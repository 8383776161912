import React, { useState, useEffect } from 'react';
import data from "./mockdata_salesreports.json";
import { Box, Button, Paper, Grid, Typography } from "@material-ui/core";
import { useStyles } from "../BodyStyles";
import { PageHeader } from "../../common/CommonComponent";
import theme from "../../../theme";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import SearchIcon from "@material-ui/icons/Search";
import honey from "../../../Components/assets/honey.png";
import excelimg from "../../../Components/assets/excelimg.png";
import calicon from "../../../Components/assets/calicon.png";
import { makeStyles } from '@material-ui/core/styles';
import DateRange from "./Daterange";
import Dat from "./Dat";
import { LocalizationProvider, DateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import axios from 'axios';
import api from "../../api.json"

import { IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";


import { DemoContainer } from '@mui/x-date-pickers/internals/demo';


const Salesreports = () => {
  const [orders, setOrders] = useState(data);
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(data.length);
  const [activeButton, setActiveButton] = useState("a");
  let background = ''
  let colo = ''


  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleDateRangeChange = (date) => {
    setSelectedDateRange(date);
  };

  const handleClick = () => {
    setShowOverlay(true);
  };

  const Overlay = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        <DateRangePicker
          startText="Start Date"
          endText="End Date"
          value={selectedDateRange}
          onChange={handleDateRangeChange}

        />
      </LocalizationProvider>
    );
  };
  const tabled = (val) => {
    setActiveButton(val);


  };
  const exportToExcel = () => {
    console.log("on export")



    // Define the header row
    const header = ["#", "DATE", "PRODUCT ID", "PRODUCT NAME", "UNIT PRICE", "QUANTITY", "REVENUE"];


    // Create the data array for the spreadsheet
    const data1 = [header, ...orders.map(({ sno, date, productID, productName, unitprice, quantity, revenue }) =>
      [sno, date, productID, productName, `Rs. ${unitprice}`, quantity, `Rs. ${revenue}`])];

    // Create a new worksheet
    const ws = XLSX.utils.aoa_to_sheet(data1);

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Table Data');

    // Create a binary string representation of the workbook
    const bin = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob from the binary string
    const blob = new Blob([bin], { type: 'application/octet-stream' });

    // Save the file
    saveAs(blob, 'salessummary.xlsx');
  };
  const [customers, setCustomers] = useState([]);
  const [cate, setCate] = useState([])
  const [user, setUser] = useState([]);

  const handleAdminsales = (e, searchedVal) => {
    console.log("getproducts", e)
    setActiveButton(e)
    axios
      .get(api.baseUrl + "/order/getadminsales", {
        params: {
          from: '',
          to: '',
          search: searchedVal,
          page: 1,
          limit: 10,

        }

      })
      .then((response) => {
        setCustomers(response.data);

        console.log(response);

        const responseData = response.data.data;
        setUser(responseData)
        setCate(responseData.order.cart)

      })
      .catch((err) => {
        console.log("error", err);
      });

  }
  useEffect(() => {

    handleAdminsales("");
  }, []);
  return (
    <Box className={classes.section}>



      <Grid container>
        <Grid item xs={12} sm={12}>
          <Typography variant='h6' className={classes.breadcrum}>
            <a className={classes.breadcrum} href="/dashboard" > Go Back </a> </Typography>


          <div style={{ display: "flex", justifyContent: "end", alignItems: "center", marginTop: "-25px" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <TextField name="search"
                placeholder="  Search"
                className={classes.rese}
                id="standard-bare"
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </div> < div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
              <Button onClick={exportToExcel} style={{ width: "200px", height: "30px", fontFamily: 'Poppins', color: " #FFFFFF", borderRadius: "5px", background: "#0EBA70", textTransform: "none", marginLeft: theme.spacing(3), borderRadius: "3", fontweight: 500, fontSize: "14px" }}>
                <td>
                  <Box
                    component="img"
                    style={{
                      alignItems: "left",
                      marginLeft: 2,
                      marginTop: 5,
                      marginLeft: theme.spacing(1),
                      maxHeight: { xs: 2, md: 2 },
                      maxWidth: { xs: 350, md: 250 },
                    }}
                    src={excelimg}
                  />
                </td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </td>
                Export to Excel</Button>
            </div>

            <Button
              onClick={handleClick}
              style={{ width: "200px", fontFamily: 'Poppins', height: "30px", color: " #FFFFFF", borderRadius: "5px", background: "#0EBA70", textTransform: "none", marginLeft: theme.spacing(3), borderRadius: "3", fontweight: 500, fontSize: "14px" }}
            > Start Date-End Date
              <td>
                <Box
                  component="img"
                  style={{
                    alignItems: "left",
                    marginLeft: 2,
                    marginTop: 5,
                    marginLeft: theme.spacing(1),
                    maxHeight: { xs: 2, md: 2 },
                    maxWidth: { xs: 350, md: 250 },
                  }}
                  src={calicon}
                />
              </td>

            </Button>
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
            {showOverlay && <Overlay />}
          </div>

          <Typography variant='h5' className={classes.pageTitlesales}>
            SALES SUMMARY
          </Typography>


        </Grid>
      </Grid>
      <Box>
      </Box>





      <Paper sx={{ width: '100%', overflow: 'hidden' }}>


        <Button onClick={(e) => tabled('a')} style={{ width: "180px", height: "30px", borderRadius: "5px", color: " #FFFFFF", background: '#0EBA70', textTransform: "none", marginBottom: theme.spacing(0), marginLeft: theme.spacing(3), fontweight: 400, fontSize: "20px", fontFamily: 'Poppins' }}>All Sales</Button>


        <TableContainer >
          <Table stickyHeader aria-label="sticky table">
            <TableHead >
              <TableRow sx={{
                "& th": {

                  fontStyle: "normal",
                  fontFamily: 'Poppins',
                  fontWeight: "400",
                  textTransform: "uppercase",
                  backgroundColor: "white",
                  color: "black",
                  fontSize: "18px",

                }
              }}>

                <TableCell align="center" >#</TableCell>
                <TableCell align="center" >DATE</TableCell>
                <TableCell align="center" >PRODUCT ID</TableCell>
                <TableCell align="left" > PRODUCT NAME </TableCell>
                <TableCell align="center" >UNIT PRICE</TableCell>
                <TableCell align="center" > QUANTITY</TableCell>
                <TableCell align="center" >REVENUE</TableCell>

              </TableRow>
            </TableHead>
            {/*   <TableBody>
         
      {orders.filter((contact) => {
         // define background and set its value to ''
         
        return search.toLowerCase() === '' ? contact :
          contact.status.toLowerCase().includes(search);
         
      }).map((contact) =>
    
     
    
        <TableRow key={contact.id} sx={{
          "& td": {
          
            fontSize: "16px",
            fontWeight: "300",
            fontStyle: "normal",
            fontFamily: 'Poppins',
            alignItems: "center",
            height: "2px !important",
            padding: "2px !important"
          }
        }}>
          <TableCell align="center">{contact.sno}</TableCell>
          <TableCell align="center">{contact.date}</TableCell>          
          <TableCell align="center">{contact.productID}</TableCell>

     <TableCell align="left">
            <td>
              <Box
                component="img"
                style={{
                  marginLeft: 1,
                  marginTop: 1,
                  maxHeight: { xs: 2, md: 2 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                src={honey}
              />
            </td>
            <td>{contact.productName}</td>
          </TableCell>
          <TableCell align="center">&#8360;. {contact.unitprice}</TableCell>
          <TableCell align="center">{contact.quantity}</TableCell>  
        <TableCell align="center">&#8360;. {contact.revenue}</TableCell>
         </TableRow>
      )}
    </TableBody>*/}
            <TableBody>
              {user.map((dataItem, index) => (
                <TableRow key={dataItem._id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{dataItem.order_date}</TableCell>
                  {dataItem.order.cart.map((cartItem) => (
                    <React.Fragment key={cartItem.auto_product_id}>
                      <TableCell align="center">{cartItem.auto_product_id}</TableCell>
                      <TableCell>
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <img src={cartItem.product_image} alt={cartItem.product_name} style={{ width: '50px', height: '50px', borderRadius: '40px', marginRight: '20px' }} />
    <span>{cartItem.product_name}</span>
  </div>
</TableCell>


                      <TableCell align="center">{cartItem.price}</TableCell>
                      <TableCell align="center">{cartItem.quantity}</TableCell>

                    </React.Fragment>
                  ))}
                  <TableCell align="center">{dataItem.add_ons}</TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

    </Box>
  )
}


export default Salesreports