import React, { useState, useEffect } from 'react';
import { Box, Button, Paper, Grid, Typography, Card } from "@material-ui/core";
import theme from "../../../theme";
import axios from 'axios';
import { useStyles } from "../BodyStyles";
import { useHistory } from 'react-router-dom';
import api from "../../api.json";
import Divider from '@material-ui/core/Divider';
import saveupload from "../../../Components/assets/saveupload.png";
import decline from "../../../Components/assets/cancel.png";
import approve from "../../../Components/assets/approved.png";
import { styled } from '@mui/material/styles';
import {  NativeSelect } from '@material-ui/core';
import InputBase from '@mui/material/InputBase';
import img from "../../assets/img1.png"
import ClearIcon from '@mui/icons-material/Clear';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { IconButton, MenuItem, Select, inputLabelClasses, Table, TableBody, TableCell, TableContainer, TableHead, 
  TablePagination, TableRow, TextField , FormControl, InputLabel, AlertTitle, Alert, } from "@mui/material";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(0),

  },
  '& .MuiInputBase-input': {
    borderRadius: 20,
    position: 'relative',
    minHeight: 18,
    background:'rgba(14, 186, 112, 0.2)',
    border: 'none',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 20,
      borderColor: 'none',

    },
  },
}));
var selectedSellerId;
var selectedEmployeeName;
const Addproducts = () => {

  const classes = useStyles();


  const [activeButton, setActiveButton] = useState("a");
  const [search, setSearch] = useState('');
  const history = useHistory();
  const [product_name, setProduct_name] = useState('');
  const [seller_id, setSeller_id] = useState('');
  const [price, setPrice] = useState('');
  const [discount, setDiscount] = useState('');
  const [product_brand, setProduct_brand] = useState('');
  const [category, setCategory] = useState('');
  const [categoryID, setCategoryID] = useState('');
  const [sub_category, setSub_category] = useState('');
  const [sub_categoryID, setSub_categoryID] = useState('');
  const [stock, setStock] = useState('');
  const [prostock, setProstock] = useState('');
  const [product_description, setProduct_description] = useState('');
  const [product_image, setProduct_image] = useState('');
  const [status, setStatus] = useState('');
  const [listing_price, setListing_price] = useState('');
  const [fulfilment, setFulfilment] = useState('');
  const [in_store, setIn_store] = useState('');
  const [settlement, setSettlement] = useState('');
  const [price_mrp, setPrice_mrp] = useState('');
  const [selling_price, setSelling_price] = useState('');
  const [usual_price, setUsual_price] = useState('');
  const [final_price, setFinal_price] = useState('');
  const [delivery_local, setDelivery_local] = useState('');
  const [delivery_zonal, setDelivery_zonal] = useState('');
  const [delivery_national, setDelivery_national] = useState('');
  const [tax_hsn, setTax_hsn] = useState('');
  const [tax_code, setTax_code] = useState('');
  const [luxury_cess, setLuxury_cess] = useState('');
  const [inventory, setInventory] = useState('');
  const [inventory_fullfilment, setInventory_fullfilment] = useState('');
  const [inventory_procurement_type, setInventory_procurement_type] = useState('');
  const [inventory_sla, setInventory_sla] = useState('');
  const [listing_status, setListing_status] = useState('');
  const [country, setCountry] = useState('');
  const [packer_details, setPacker_details] = useState('');
  const [manufacturer_details, setManufacturer_details] = useState('');
  const [importer_details, setImporter_details] = useState('');
  const [package_weight, setPackage_weight] = useState('');
  const [package_length, setPackage_length] = useState('');
  const [package_breadth, setPackage_breadth] = useState('');
  const [package_height, setPackage_height] = useState('');
  const [brand_name, setBrand_name] = useState('');
  const [product_size, setProduct_size] = useState('');
  const [product_design, setProduct_design] = useState('');
  const [product_model_name, setProduct_model_name] = useState('');
  const [product_length, setProduct_length] = useState('');
  const [product_brand_color, setProduct_brand_color] = useState('');
  const [product_width, setProduct_width] = useState('');
  const [product_model_number, setProduct_model_number] = useState('');
  const [product_pack, setProduct_pack] = useState('');
  const [agribreeders_approved, setAgribreeders_approved] = useState('');
  const [shipping_type, setShipping_type] = useState('');
  const [purchase_type, setPurchase_type] = useState('');
  const [shipping_charges_buyer, setShipping_charges_buyer] = useState('');
  const [total_deductions, setTotal_deductions] = useState('');
  const [settlement_value, setSettlement_value] = useState('');
  const [platform_fee, setPlatform_fee] = useState('');
  const [shipping_fee, setShipping_fee] = useState('');
  const [payment_gateway_fee, setPayment_gateway_fee] = useState('');
  const [collection_fee, setCollection_fee] = useState('');
  const [cate, setCate] = useState([])
  const [cates, setCates] = useState([])
const[add_ons,setAdd_ons]=useState('')
const successIcon = <CheckCircleIcon className="success-icon" />;
const iconMapping = {
  success: successIcon,
};
const errorIcon = <ErrorIcon className="error-icon" />;
const iconMapping3 = {
  error: errorIcon,
};
const [updateStatus, setUpdateStatus] = useState(null);
  useEffect(() => {

    categoryList();
    getproducts1();

  }, []);
  const[user,setUser]=useState([])
  const getproducts1 = (e) => {
 
   
    axios
    .get(api.baseUrl + "/admin/getcustomers?user_type=2")
      .then((response) => {
    //setCustomers(response.data);
    
    console.log(response);

    const responseData = response.data.data;
    setUser(responseData.users)
   
  })
  
  .catch((err) => {
    console.log("error", err);
  });

  }
 
  const [dropdownName, setDropdownName] = useState("");
  const [dropdownId, setDropdownId] = useState("");

  let getpriceGroupValue = async (pos) => {
    setDropdownId(user[(pos - 1)]._id);
    setDropdownName(user[(pos - 1)].user_id);
    selectedEmployeeName = user[(pos - 1)].user_id;
    selectedSellerId = user[(pos - 1)]._id;
    console.log("mounikaedd",selectedSellerId,selectedEmployeeName)

    setSeller_id(selectedEmployeeName);
   
  }

  let categoryselection = async (pos) => {
    console.log("pos", pos)
    setCategory(pos);
    //setCategory(cate[(pos - 1)].name);
    setCategoryID(pos._id)
    console.log("pos._id1", pos._id)

    subcategoryList();
  }

  let subcategoryselection = async (pos) => {
    console.log(pos)
    setSub_category(pos)
    setSub_categoryID(pos._id)

  }


  const categoryList = () => {
    axios
      .get(api.baseUrl + "/admin/getcategory")
      .then((response) => {

        setCate(response.data.data);
        console.log("response.data----->", cate)

      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const subcategoryList = () => {

    console.log("link")
    axios
      .get(api.baseUrl + "/admin/getsubcategory")
      .then((response) => {

        setCates(response.data.data);
        console.log("response.data----->", cates)

      })
      .catch((err) => {
        console.log("error", err);
      });
  };
 //upload function
 const [selectedImage, setSelectedImage] = useState(null);
 const [data,setData]=useState("")
 const [fileUrl, setFileUrl] = useState('');
 const [selectedImages, setSelectedImages] = useState([]);
 const [imagesList, setImagesList] = useState([]);

 const handleImageClick = () => {
   document.getElementById('fileInput').click();
 };

 const [errorMessage, setErrorMessage] = useState('');
 
 const handleFileChange = (event) => {
  const file = event.target.files[0];
  const imageUrl = URL.createObjectURL(file);

  if (selectedImages.length < 4) {
    setSelectedImages(prevImages => [...prevImages, imageUrl]);
    setImagesList(prevList => [...prevList, file]);
    uploadImage(file);
    setErrorMessage(''); // Clear any previous error message
  } else {
    setErrorMessage('Cannot upload more than 4 images');
  }
};

 
 
const uploadImage = (file) => {
  const formData = new FormData();
  formData.append('file', file);

  axios.post(api.baseUrl + '/seller/uploadFile', formData)
    .then(response => {
      console.log(response.data);
      const fileUrl = response.data.file_url;
      console.log("dvg", fileUrl);
      setFileUrl(fileUrl);

      // Update the imagesList with the file URL
      setImagesList(prevList => {
        const updatedList = [...prevList];
        updatedList[updatedList.length - 1] = fileUrl;
        return updatedList;
      });
    })
    .catch(error => {
      console.error(error);
    });
};

 
 const handleRemoveImage = (index) => {
  setSelectedImages(prevImages => prevImages.filter((_, i) => i !== index));
};
  



  let saveupload = async () => {
 
      //e.preventDefault();
  
   try {
      let res = await fetch(api.baseUrl + "/admin/createproducts", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          product_name: product_name,
          seller_id: selectedSellerId,
          price: price,
          discount: discount,
          product_brand: product_brand,
          category: categoryID,
          sub_category: sub_categoryID,
          stock: stock,
          product_description: product_description,
          product_image:imagesList,
          status: status,
          listing_price: listing_price,
          fulfilment: fulfilment,
          in_store: in_store,
          settlement: settlement,
          price_mrp: price_mrp,
         // discount_price: discount_price,
          selling_price: selling_price,
          usaual_price:usual_price,
          final_price:final_price,
          delivery_local: delivery_local,
          delivery_zonal: delivery_zonal,
          delivery_national: delivery_national,
          tax_hsn: tax_hsn,
          tax_code: tax_code,
          luxury_cess: luxury_cess,
          inventory:"Seller only",

          inventory_fullfilment: inventory_fullfilment,
          inventory_procurement_type: inventory_procurement_type,
          inventory_sla: inventory_sla,
          procrument_stock:prostock,
          listing_status: listing_status,

          country: country,
          packer_details: packer_details,
          manufacturer_details: manufacturer_details,
          importer_details: importer_details,

          package_weight: package_weight,
          package_length: package_length,
          package_breadth: package_breadth,
          package_height: package_height,

          brand_name: brand_name,
          product_size: product_size,
          product_design: product_design,
          product_model_name: product_model_name,
          product_length: product_length,
          product_brand_color: product_brand_color,
          product_width: product_width,
          product_model_number: product_model_number,
          product_pack: product_pack,

          agribreeders_approved: agribreeders_approved,
          //shipping_type: shipping_type,
          purchase_type: purchase_type,
          //shipping_charges_buyer: shipping_charges_buyer,
          //total_deductions: total_deductions,
          //settlement_value: settlement_value,
          platform_fee:"2%",
          add_ons:add_ons,
          //shipping_fee: shipping_fee,
          //payment_gateway_fee: payment_gateway_fee,
          //collection_fee: collection_fee
        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        console.log("Product successfully added")
        //alert("Product successfully added")
        setUpdateStatus("success");
        setTimeout(() => {
          setUpdateStatus(false);
          history.push("/individualproductslist")
        }, 4000);
       
      } else {
        console.log("error occured")
        setUpdateStatus("failed")
        setTimeout(() => {
          setUpdateStatus(false);
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };

 
  return (
    <Box className={classes.section}>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'}}> {updateStatus === "success" && (
          <Alert iconMapping={iconMapping} severity="success"
            onClose={() => setUpdateStatus(null)}
            style={{
              background: "green", color: "white", marginBottom: theme.spacing(2), position: "fixed", zIndex: 9999, [theme.breakpoints.down("xs")]: {
                textAlign: 'center',
                width: "95%", marginBottom: theme.spacing(2)
              },
            }}>
            <AlertTitle> Product updated successfully </AlertTitle>
          </Alert>
        )}
        {updateStatus === "failed" && ( 
          <Alert iconMapping={iconMapping} severity="error" onClose={() => setUpdateStatus(null)}
            style={{
              background: "red", color: "white", marginBottom: theme.spacing(2), position: "fixed", zIndex: 9999, [theme.breakpoints.down("xs")]: {
                textAlign: 'center',
                width: "90%",
              },
            }}>
            <AlertTitle> Failed to update product</AlertTitle>
          </Alert>
        )}
      </div>
      <Grid container>
        <Grid item xs={12} sm={12}>
         {/* <Typography variant='h6' className={classes.breadcrum}>
            <a className={classes.breadcrum} href="/individualproductslist" > Go Back </a>

  </Typography>*/}
          <Typography variant='h5' className={classes.pageTitle} >
            ENTER PRODUCT DETAILS
          </Typography>

        </Grid>

        <Paper sx={{ width: '100%', overflow: 'hidden' }}> </Paper>
      </Grid>

      <Box style={{ background: "#e4f8ef",marginLeft:"-20px",marginRight:"-20px",padding:"20px" }}>

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Name</Typography>
        <TextField value={product_name} onChange={(e) => setProduct_name(e.target.value)} className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Price</Typography>
        <TextField value={price} onChange={(e) => setPrice((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined"
          inputProps={{
            pattern: "[0-9]*",
            inputMode: "numeric",
            style: { textAlign: "left" }
          }}
        />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Discount</Typography>
        <TextField value={discount} onChange={(e) => setDiscount((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined"
          inputProps={{
            pattern: "[0-9]*",
            inputMode: "numeric",
            style: { textAlign: "left" }
          }}
        />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Brand</Typography>
        <TextField value={product_brand} onChange={(e) => setProduct_brand(e.target.value)} className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Category</Typography>


        <Select

          value={category}
          onChange={(e) => categoryselection(e.target.value)}
          className={classes.selectd} style={{ height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined"
          displayEmpty
          >
            <MenuItem value="" disabled>
              Select Category
            </MenuItem>

          {cate.map((cat) => (
            <MenuItem key={cat._id} value={cat}>
              {cat.name}
            </MenuItem>
          ))}
        </Select>
        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Subcategory</Typography>

        <Select

          value={sub_category}
          onChange={(e) => subcategoryselection(e.target.value)}
          className={classes.selectd} style={{ height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined"
          displayEmpty
          >
            <MenuItem value="" disabled>
              Select  Subcategory
            </MenuItem>

          {cates.map((cats) => (
            <MenuItem key={cats._id} value={cats}>
              {cats.sub_category_name}
            </MenuItem>
          ))}
        </Select>

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Stock</Typography>
        <TextField value={stock} onChange={(e) => setStock((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined"
          inputProps={{
            pattern: "[0-9]*",
            inputMode: "numeric",
            style: { textAlign: "left" }
          }}
        />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Description</Typography>
        <TextField value={product_description} onChange={(e) => setProduct_description(e.target.value)} className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />
        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Upload Product Image</Typography>
        <div>
        
        <div style={{ display: "flex" }}>
        <img src={img} alt="Default" onClick={handleImageClick} style={{cursor:"pointer"}} />
  {selectedImages.map((image, index) => (
    <div key={index} style={{ position: 'relative', marginRight: "10px" }}>
      <img
        src={image}
        alt={`Selected ${index}`}
        style={{ width: "100px", height: "100px",borderRadius:"12px" }}
      />
      <button
        onClick={() => handleRemoveImage(index)}
        style={{
          position: 'absolute',
          top: '0px',
          right: '-1px',
          backgroundColor: '#0EBA70',
          border: 'none',
          cursor: 'pointer',
          color: 'white',
          borderRadius:"40px"
        }}
      >
        <ClearIcon />
      </button>
    </div>
  ))}
</div>

  {errorMessage && <p style={{color:"red"}}>{errorMessage}</p>}
</div>
<input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} />








    
        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{
                    color: "black", fontFamily: 'Poppins', fontStyle: "normal",
                    fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "124px"
                  }}>
                    Listing Price
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={listing_price} onChange={(e) => setListing_price((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={8}>

                  <Select

                    value={status || "Active"}
                    onChange={(e) => setStatus(e.target.value)}
                    className={classes.select} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", paddingTop: "8px", paddingBottom: "8px" }}
                  >

                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="In Active">Deactive</MenuItem>

                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>







        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />




        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>Price Details</Typography>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    MRP
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={price_mrp} onChange={(e) => setPrice_mrp((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Your Selling Price
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={selling_price} onChange={(e) => setSelling_price((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Usual Price
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={usual_price} onChange={(e) => setUsual_price((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Final Price
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={final_price} onChange={(e) => setFinal_price(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>




        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />
        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>DELIVERY CHARGES TO CUSTOMER</Typography>
        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2} wrap="nowrap">
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Delivery Charges-Local
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={delivery_local} onChange={(e) => setDelivery_local(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Delivery Charges-Zonal
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={delivery_zonal} onChange={(e) => setDelivery_zonal(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Delivery Charges-National
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={delivery_national} onChange={(e) => setDelivery_national(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Box>


        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />


        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>TAX DETAILS</Typography>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2} wrap="nowrap">
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    HSN CODE
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={tax_hsn} onChange={(e) => setTax_hsn((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    TAX CODE
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={tax_code} onChange={(e) => setTax_code((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    LUXURY PRODUCT CESS
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={luxury_cess} onChange={(e) => setLuxury_cess((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Box>



        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />
        <Grid container direction="row" spacing={2}>
          <Grid item xs={6}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={4}>
                <Typography style={{
                  color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px",
                  textDecorationLine: "underline", marginBottom: "16px"
                }}>SELLER</Typography></Grid>
                  <Grid item xs={8}>
                  <FormControl className={classes.error} style={{
                  
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1), minWidth: "250px"
                  }}>
                    <InputLabel id="demo-simple-select-label"  InputLabelProps={{
                      sx: {
                        color: "#590F72",
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#590F72",
                          fontWeight: 500,
                          fontSize: 16
                        }
                      }
                    }}></InputLabel>
                    <NativeSelect id="select" label="Role"
                      InputLabelProps={{
                        sx: {
                          color: "#590F72",
                          margin: theme.spacing(2),
                        

                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#590F72",
                            fontWeight: 600,
                            fontSize: 16, 
                          }
                        }
                      }}
                      value={seller_id} onChange={(e) => getpriceGroupValue(e.target.options.selectedIndex)}

                      className={classes.select}
                      input={<BootstrapInput />}
                    >
                      <option value="">Seller Id:</option>
                      {user.map((cat) => (
                        <option key={cat._id} value={cat.user_id}>{cat.name} - {cat.user_id}</option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </Grid>
            </Grid>

          </Grid>
        </Grid>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Inventory
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography> Seller only </Typography>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Box>
        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />


        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>INVENTORY DETAILS</Typography>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Fulfilment by
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={inventory_fullfilment} onChange={(e) => setInventory_fullfilment(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Procurement type
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={inventory_procurement_type} onChange={(e) => setInventory_procurement_type(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100px" }}>
                    Procurement SLA
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={inventory_sla} onChange={(e) => setInventory_sla(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100px" }}>
                    Stock
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={prostock} onChange={(e) => setProstock(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />




        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>MANUFACTURING DETAILS</Typography>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Country Of Origin
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={country} onChange={(e) => setCountry(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Packers Details
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={packer_details} onChange={(e) => setPacker_details(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Manufacturing Details
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={manufacturer_details} onChange={(e) => setManufacturer_details(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Importer Details
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={importer_details} onChange={(e) => setImporter_details(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />

        <Typography style={{
          color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline",
          marginBottom: "16px"
        }}>PRODUCT DETAILS</Typography>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Package Weight
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={package_weight} onChange={(e) => setPackage_weight(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Package Length
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={package_length} onChange={(e) => setPackage_length(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Package Breadth
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={package_breadth} onChange={(e) => setPackage_breadth (e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Package Height
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={package_height} onChange={(e) => setPackage_height(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />




        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>PACKAGING DETAILS</Typography>
        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Brand
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={brand_name} onChange={(e) => setBrand_name(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Size
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={product_size} onChange={(e) => setProduct_size (e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Design
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={product_design} onChange={(e) => setProduct_design(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Model Name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={product_model_name} onChange={(e) => setProduct_model_name(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Length
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={product_length} onChange={(e) => setProduct_length (e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Brand Color
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={product_brand_color} onChange={(e) => setProduct_brand_color(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Width
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={product_width} onChange={(e) => setProduct_width (e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Model Number
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={product_model_number} onChange={(e) => setProduct_model_number((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>



        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Pack Of
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={product_pack} onChange={(e) => setProduct_pack((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Box>

        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>SETTLEMENT DETAILS</Typography>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
        

        <Grid container direction="row" spacing={0}>
  <Grid item xs={2}>
    <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
      Shipment charges
    </Typography>
  </Grid>
<div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}} spacing={3}>
  <Grid item xs={4} style={{marginRight:"10px"}}>
    <Typography style={{ color: "#FFFFFF", background: "#0EBA70", borderRadius: "10px", 
    fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", 
    marginBottom: "16px", width: "100%",marginRight:"10px",textAlign:"center" }}>
      Local - Rs.50+18% taxes
    </Typography>
  </Grid>

  <Grid item xs={4}  style={{marginRight:"10px"}}>
    <Typography style={{ color: "#FFFFFF",textAlign:"center", background: "#0EBA70", borderRadius: "10px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px",
     fontSize: "14px", marginBottom: "16px", width: "100%",marginRight:"10px" }}>
      Zonal - Rs.60+18% taxes
    </Typography>
  </Grid>

  <Grid item xs={4}  style={{marginRight:"10px"}}>
    <Typography style={{ color: "#FFFFFF", background: "#0EBA70",textAlign:"center",borderRadius: "10px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px",
     fontSize: "14px", marginBottom: "16px", width: "120%" }}>
      National - Rs.70+18% taxes
    </Typography>
  </Grid>
  </div>
</Grid>
  <Grid container direction="row" spacing={2}>

            <Grid item xs={6}>
              <Grid container direction="row" spacing={3}>
              
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Agribreeders Approved
                  </Typography>
                </Grid>
                <Grid item xs={8}>

                  <Select
                    value={agribreeders_approved || "Yes"}
                    onChange={(e) => setAgribreeders_approved(e.target.value)}
                    className={classes.select} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                  >  <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>

                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Selling Price
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField value={selling_price} 
                  onChange={(e) => setSelling_price(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF",
                   border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Purchase Type
                  </Typography>
                </Grid>
                <Grid item xs={8}>

                  <Select
                    value={purchase_type}
                    onChange={(e) => setPurchase_type(e.target.value)}
                    className={classes.select} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Purchase Type
                </MenuItem> <MenuItem value="Prepaid">Prepaid</MenuItem>
                    <MenuItem value="COD">COD</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Add Ons (Commission, Shipping, Fixed & Collection)
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={add_ons} 
                  onChange={(e) => setAdd_ons(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF",
                   border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Final Price
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    value={final_price} onChange={(e) => setFinal_price((v) => (e.target.validity.valid ? e.target.value : v))}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Platform Fee
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>2%(Fixed Charges)</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>

        </Box>

 < div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <Button onClick={saveupload} style={{ width: "200px", height: "35px", fontFamily: 'Poppins', color: " #FFFFFF", borderRadius: "5px", background: "#0EBA70", textTransform: "none", marginLeft: theme.spacing(3), borderRadius: "3", fontweight: 500, fontSize: "14px" }}>
            <td>
              <Box
                component="img"
                style={{
                  marginRight: 20,
                  marginTop: 10,
                  marginLeft: theme.spacing(1),
                  maxHeight: { xs: 2, md: 2 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                src={saveupload}
              />
            </td>
            SAVE & UPLOAD</Button>

          <Button style={{ width: "200px", height: "35px", fontFamily: 'Poppins', color: " #FFFFFF", borderRadius: "5px", background: "#D9D9D9", textTransform: "none", marginLeft: theme.spacing(3), borderRadius: "3", fontweight: 500, fontSize: "14px" }}>
            <td>
              <Box
                component="img"
                style={{
                  marginRight: 20,
                  marginTop: 10,
                  marginLeft: theme.spacing(1),
                  maxHeight: { xs: 2, md: 2 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                src={decline}
              />
            </td>
            DECLINE</Button>

          <Button style={{ width: "200px", height: "35px", fontFamily: 'Poppins', color: " #FFFFFF", borderRadius: "5px",
           background: "#D9D9D9", textTransform: "none", marginLeft: theme.spacing(3), borderRadius: "3", fontweight: 500, fontSize: "14px" }}>
            <td>
              <Box
                component="img"
                style={{
                  marginRight: 20,
                  marginTop: 10,
                  marginLeft: theme.spacing(1),
                  maxHeight: { xs: 2, md: 2 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                src={approve}
              />
            </td>
            Approve</Button>


        </div>

      </Box>
    </Box>



  )
}

export default Addproducts
