import React, { useState, useEffect } from 'react';
import { Box, Button, Paper,Grid, Typography,FormControlLabel, Checkbox } from "@material-ui/core";

import theme from "../../../theme";
import { useStyles } from "../BodyStyles";
import ClearIcon from '@mui/icons-material/Clear';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useHistory } from 'react-router-dom';
import {
    IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions, Alert,
    AlertTitle,
  } from "@mui/material";
  import api from "../../api.json"

const Createnotifications = () => {
  const successIcon = <CheckCircleIcon className="success-icon" />;
const iconMapping = {
  success: successIcon,
};
const errorIcon = <ErrorIcon className="error-icon" />;
const iconMapping3 = {
  error: errorIcon,
};
const [updateStatus, setUpdateStatus] = useState(null);
    const classes = useStyles();
    const history = useHistory()
    const [activeButton, setActiveButton] = useState("a");   
    const [category, setCategory] = useState('');
    const [checkedseller, setCheckedseller] = useState(false);
    const [checkedall, setCheckedall] = useState(false);
    const [checkedbuyer, setCheckedbuyer] = useState(false);
    const [checkedadmin, setCheckedadmin] = useState(false);
    const [messagenoti, setMessagenoti] = useState("");
    const [message, setMessage] = useState("");
    const [disable, setDisable] = useState(0);
    const [isActive, setIsActive] = useState(false);
    

    const handleChanges = (event) => {
      setCheckedseller(event.target.checked);
      console.log("seller12",checkedseller)
    };
    const handleChangeb = (event) => {
        setCheckedbuyer(event.target.checked);
        console.log("buyer12",checkedbuyer)
      };
      const handleChangea = (event) => {
        setCheckedall(event.target.checked);
        console.log("all",checkedall)
      };
      const handleChangead = (event) => {
        setCheckedadmin(event.target.checked);
        console.log("admin",checkedadmin)
      };

      let handleCreatecustomer = async (e) => {
        e.preventDefault();
        setDisable(true);
        setIsActive(true);
      
    
        try {
          let categoryValue = '';

          if (checkedseller) {
            categoryValue = '2'; // Seller
            console.log("seller",checkedseller)
          } else if (checkedall) {
            categoryValue = '4'; // All
            console.log("all",checkedall)
          } else if (checkedbuyer) {
            categoryValue = '3'; // Buyer
            console.log("buyer",checkedbuyer)
          } else if (checkedadmin) {
            categoryValue = '1'; // Admin
            console.log("admin",checkedadmin)
          }
          let res = await fetch(api.baseUrl + "/admin/createnotification", {
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
             
              category: category,
              message: messagenoti,
              target:categoryValue
            }),
          });
          let resJson = await res.json();
          if (res.status === 200) {
            setDisable(false);
            setIsActive(false);
            setUpdateStatus("success");
            setTimeout(() => {
              setUpdateStatus(false);
              history.push("/viewnotifications")
            }, 5000);
          
            setMessage("User created successfully");
           
          } else {
            setUpdateStatus("failed")
        setTimeout(() => {
          setUpdateStatus(false);
        }, 3000);
          }
        } catch (err) {
          console.log(err);
          setDisable(false);
          setIsActive(false);
        }
    
    
      };

  return (
    <>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'}}> {updateStatus === "success" && (
          <Alert iconMapping={iconMapping} severity="success"
            onClose={() => setUpdateStatus(null)}
            style={{
              background: "green", color: "white", marginBottom: theme.spacing(2), marginTop:"40px",position: "fixed", zIndex: 9999, [theme.breakpoints.down("xs")]: {
                textAlign: 'center',
                width: "95%", marginBottom: theme.spacing(2)
              },
            }}>
            <AlertTitle> Notification Added successfully </AlertTitle>
          </Alert>
        )}
        {updateStatus === "failed" && ( 
          <Alert iconMapping={iconMapping} severity="error" onClose={() => setUpdateStatus(null)}
            style={{
              background: "red", color: "white", marginBottom: theme.spacing(2), marginTop:"40px",position: "fixed", zIndex: 9999, [theme.breakpoints.down("xs")]: {
                textAlign: 'center',
                width: "90%",
              },
            }}>
            <AlertTitle> Failed to Post Notification</AlertTitle>
          </Alert>
        )}
      </div>
    <Box className={classes.section}>
    <Grid container>   
   
    <Grid item xs={12} sm={12}>
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop:"10px" }}>

    <Typography variant='h6' className={classes.breadcrum}>

      <a  className={classes.breadcrum} href="/dashboard" > Go Back </a>
      <Typography variant='h5' className={classes.pageTitleaddcust} >
         NOTIFICATIONS
      </Typography>      
        </Typography>  
      </div>
      <Grid style={{ backgroundColor: 'rgba(14, 186, 112, 0.1)'}}>
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center",height: "120px" }}>


    <Typography variant='h5' className={classes.pageTitleaddcust} >
    CREATE NOTIFICATION
      </Typography>   

  </div>
  <div style={{ display: "flex", flexDirection: "row", justifyContent: "left",fontfamily:"Poppins",fontstyle: "normal",
fontweight: "500",fontsize: "20px" }}>


<Typography variant='h5'>
Create Event
  </Typography>  
  </div>

<Box className={classes.squareBox}>
      <Grid container spacing={2}>
      <Grid item xs={12}>
  <Select
    value={category} 
  
    onChange={(e) => setCategory(e.target.value)}
    style={{ width: '100%' }}
    variant="outlined"
    displayEmpty
    inputProps={{ placeholder: 'Select a category' }}
    MenuProps={{
      PaperProps: {
        style: {
          backgroundColor: 'rgba(14, 186, 112, 0.5)', // Set the desired background color here
        },
      },
    }}
  >
    <MenuItem  value=''>
<Typography>---- Choose Category ---- </Typography></MenuItem>
    <MenuItem style={{ backgroundColor: 'rgba(14, 186, 112, 0.5)', color: "white", height: "50px" }} value="1">ADVERTISEMENT POSTS</MenuItem>
    <MenuItem style={{ background: "rgba(14, 186, 112, 0.5)", color: "white", height: "50px" }} value="2">PROMOTION POSTS</MenuItem>
    <MenuItem style={{ background: "rgba(14, 186, 112, 0.5)", color: "white", height: "50px"  }} value="3">OFFERS</MenuItem>
    <MenuItem style={{ background: "rgba(14, 186, 112, 0.5)", color: "white", height: "50px" }} value="4">DISCOUNTS</MenuItem>
  </Select>
</Grid>

        <Grid item xs={12}>
          <TextField
          placeholder='- - - - -Type Your Message- - - -'
          value={messagenoti} onChange={(e)=>setMessagenoti(e.target.value)}
            multiline
            rows={4}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={checkedseller} onChange={handleChanges} style={{color:"#0EBA70"}}/>}
            label="Send Notification to Sellers Only"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={checkedall} onChange={handleChangea} style={{color:"#0EBA70"}}/>}
            label="Send Notification to All"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel 
            control={<Checkbox checked={checkedbuyer} onChange={handleChangeb} style={{color:"#0EBA70"}}/>}
            label="Send Notification to Buyers Only"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel 
            control={<Checkbox checked={checkedadmin} onChange={handleChangead} style={{color:"#0EBA70"}}/>}
            label="Send Notification to Admins Only"
          />
        </Grid>
    
        <Grid item xs={12}>
          
        <Button type="submit" onClick={handleCreatecustomer}    style={{ width: "100%", height: "35px", fontFamily: 'Poppins', color: " #FFFFFF", 
          background: "#0EBA70 ", textTransform: "none",  borderRadius: "3", fontweight: 500, fontSize: "14px" }}>
          POST NOTIFICATION</Button>
        </Grid>




      </Grid>
    </Box>
    


  </Grid>
      </Grid>
      </Grid>
      </Box>
      </>
  )
}

export default Createnotifications

