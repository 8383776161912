import React from "react";
import Profile from "./Profile";
import logoagri from "./logo.png";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  BottomNavigation,
  Icon,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useStyles } from "./HeaderStyles";
import MenuIcon from "@material-ui/icons/Menu";
import { Border } from "react-bootstrap-icons";
import { makeStyles } from '@material-ui/core/styles';

export default function Navbar({ handleDrawerOpen }) {
 
  const classes = useStyles();

  return (
    <AppBar position='fixed' sx={{ backgroundImage: "linear-gradient(to right, #330537, #6e0845, #a62144, #d24c35, #eb8112);" }}>
      <Toolbar className={classes.toolbar}>
      <Box flexGrow={1} />
        <Profile />
    
    

<Hidden mdUp>
  <IconButton color='inherit' onClick={handleDrawerOpen}>

    <MenuIcon />
  </IconButton>
</Hidden>
  </Toolbar>
</AppBar>

  );
}