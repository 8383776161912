import React, { useState, useEffect } from 'react';
import { Box, Button, Paper,Grid, Typography } from "@material-ui/core";
import theme from "../../../theme";
import { useStyles } from "../BodyStyles";
import { useHistory } from 'react-router-dom';
import { Alert, AlertTitle, IconButton, MenuItem, Select, Table, TableBody, TableCell, 
    TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import reviewimg from "../../../Components/assets/reviewimg.png";
import createnoti from "../../../Components/assets/createnoti.png";
import axios from 'axios';
import api from "../../api.json";
import SearchIcon from "@material-ui/icons/Search";
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
const Notificationreview = () => {
    const classes = useStyles();
    //const [orders, setOrders] = useState(data);
   // const [count, setCount] = useState(data.length);  
    const [activeButton, setActiveButton] = useState("a");
    const [search, setSearch] = useState('');
    const [showOverlay, setShowOverlay] = useState(false);
    const[cate,setCate]=useState([])
    const history = useHistory()
 
    const [updateStatus, setUpdateStatus] = useState(null);
    const successIcon = <CheckCircleIcon className="success-icon" />;
    const iconMapping = {
      success: successIcon,
    };
    const errorIcon = <ErrorIcon className="error-icon" />;
    const iconMapping3 = {
      error: errorIcon,
    };
    const requestSearch = (searchedVal) => {
        console.log("searchedVal", searchedVal)
        axios
          .get(api.baseUrl + "/admin/searchviewnotification/" + searchedVal)
          .then((response) => {
            setCate(response.data.data);
            //setContacts(response.data)
            console.log("topup", response.data)
          })
          .catch((err) => {
            console.log("error", err);
          });
      };
      const [pageNumber, setPageNumber] = useState(1);

      //const [hasNextPage, setHasNextPage] = useState(false);
      const handleNextClick = () => {
        const nextPageNumber = pageNumber + 1;
        setPageNumber(nextPageNumber);
        getviewNotification(nextPageNumber);
        console.log("paghgj",pageNumber)
      };
      const limit = 10;
      const handlePrevClick = () => {
        if (pageNumber > 1) {
          const prevPageNumber = pageNumber - 1;
          setPageNumber(prevPageNumber);
          getviewNotification(prevPageNumber);
          console.log("previous",pageNumber)
        }
      };
      const hasNextPage = cate.length === limit;
      const getviewNotification =(page)=>{
        const startRow = page;
        const endRow = page * limit;
        console.log("latest",limit,startRow,endRow)
        axios
        .get(api.baseUrl + "/admin/getviewnotification",{
          params: {
            page: startRow,
            limit: 10,
          
          }
        })
        .then((response) => {
  
          setCate(response.data.notifications);
        
          console.log("category", cate,response.data)
  
  
        })
        .catch((err) => {
          console.log("error", err);
        });
      }
  
      useEffect(() => {
    
        getviewNotification(pageNumber);
          }, [pageNumber]);
  return (
    <div>
    <Box className={classes.section}>
    <Grid container justifyContent= "flex-end">
  
    <Grid item xs={12} sm={12}>
      <Typography variant='h6' className={classes.breadcrum}>
      <a  className={classes.breadcrum} href="/Notifications" > Go Back </a>
     
      </Typography>
   
      <div  style={{ display: "flex", justifyContent: "end", alignItems: "center", marginTop:"30px" }}>
      <div style={{ display: "flex", justifyContent: "center",marginRight:"10px" }}>
      <TextField name="search"
        placeholder="  Search"
        className={classes.rese}
        onChange={(e) => requestSearch(e.target.value)}
        id="standard-bare"
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
      />
    </div>

      </div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography variant='h5' className={classes.noti} >
        VIEW NOTIFICATIONS
        </Typography>     
                
     </Box>
      </Grid>
    


      </Grid>
      
      </Box>
      <Paper>
  <TableContainer style={{ border: "0.25px solid gray", borderTopRightRadius: "10px",borderTopLeftRadius: "10px" }}>
    <Table>
      <TableHead style={{color:"#000000"}}>
        <TableRow>
          <TableCell align="center" style={{ borderBottom: "1px solid gray",color:"#000000",fontWeight:500,fontFamily:"Poppins",fontStyle:"normal",fontSize:"16px" }}>
            Date
          </TableCell>

          <TableCell align="center" style={{ borderBottom: "1px solid gray",color:"#000000",fontWeight:500,fontFamily:"Poppins",fontStyle:"normal",fontSize:"16px" }}>
            Category
          </TableCell>
          <TableCell align="center" style={{ borderBottom: "1px solid gray",color:"#000000",fontWeight:500,fontFamily:"Poppins",fontStyle:"normal",fontSize:"16px" }}>
            Message
          </TableCell>

          <TableCell align="center" style={{ borderBottom: "1px solid gray",color:"#000000",fontWeight:500,fontFamily:"Poppins",fontStyle:"normal",fontSize:"16px" }}>
            Addressed to
          </TableCell>

        {/*  <TableCell align="center" style={{ borderBottom: "1px solid gray",color:"#000000",fontWeight:500,fontFamily:"Poppins",fontStyle:"normal",fontSize:"16px" }}>
            Action
      </TableCell>*/}
        </TableRow>
      </TableHead>

      <TableBody >
      {cate.map((contact) =>

 

<TableRow  key={contact._id} sx={{
  "& td": {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    alignItems: "center",
    height: "50px !important",
    width:"1213px !important",
    padding: "2px !important"

  }
}}>
          <TableCell align="center" style={{borderBottom: "1px solid gray",color:"#000000",fontWeight:500,fontFamily:"Poppins",fontStyle:"normal",
          fontSize:"16px" }}>{contact.created_at.substring(0, 10)}</TableCell>

          <TableCell align="center" style={{borderBottom: "1px solid gray",color:"#000000",fontWeight:500,fontFamily:"Poppins",fontStyle:"normal",fontSize:"16px" }}>
           {contact.category}</TableCell>
          <TableCell align="center" style={{borderBottom: "1px solid gray",color:"#000000",fontWeight:500,fontFamily:"Poppins",fontStyle:"normal",fontSize:"16px" }}>
            {contact.message}</TableCell>

          <TableCell align="center" style={{borderBottom: "1px solid gray",color:"#000000",fontWeight:500,fontFamily:"Poppins",fontStyle:"normal",fontSize:"16px" }}>{contact.target}</TableCell>

       {/*   <TableCell align="center" >
            <Button
              type="submit"
              style={{
                textDecoration: "underline",
                color: "#84240B",
                fontFamily: "Poppins",
                letterSpacing: "0.001em",fontStyle:"normal",fontSize:"16px",
              }} 
            >
              DELETE
            </Button>
            </TableCell>*/}
        </TableRow>
         )}
      </TableBody>
   
    </Table>
  </TableContainer>
</Paper>
<Box style={{ textAlign: "end" }}>
      <Button onClick={handlePrevClick} style={{ color: "#590F72" }}>
        Prev
      </Button>
      <strong style={{ color: "blue", justifyContent: "space-between" }}>
        {pageNumber}
      </strong>
      <Button onClick={handleNextClick} style={{ color: "#590F72",opacity: !hasNextPage ? '60%' : '',
          color: !hasNextPage ? '' : '',}} disabled={!hasNextPage}>
        Next
      </Button>
    </Box>
      </div>
  )
}

export default Notificationreview