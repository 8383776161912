import React from 'react'
import  profilepic from '../../../Components/assets/profilepic.png';
import {Box,Typography} from "@material-ui/core";
import { ResponsiveContainer, LineChart, Label,Line, PieChart, Pie, Cell , CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
const data = [
  { name: 'Groceries Rs.1500', value: 1500},
  { name: 'Fish and Meat      Rs.8800', value: 300 },
  { name: 'Dairy products     Rs.2300', value: 1500},
  { name: 'Fruits and veg     Rs.4800', value: 2500},
  { name: 'Others             Rs.10500', value: 500 }
];

const ldata = [
  { label: 'June', orders: 21, customers: 41 },
  { label: 'July', orders: 35, customers: 79 },
  { label: 'Aug', orders: 75, customers: 57 },
  { label: 'Sep', orders: 51, customers: 47 },
  { label: 'Oct', orders: 41, customers: 63 },
  { label: 'Nov', orders: 47, customers: 71 },
  { label: 'Dec', orders: 67, customers: 81 },
  { label: 'Jan', orders: 37, customers: 51 }
];

const COLORS = ['#36E968', ' #EB4335', '#4285F4','#FBBC05',' #0EBA70'];

const CustomizedXAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <text
      x={x}
      y={y}
      dy={16}
      textAnchor="middle"
      fill="#666"
      fontSize={18} // Adjust the font size here
    >
      {payload.value}
    </text>
  );
};

const Dashboard1 = () => {
    
  return (
    <div>
    <div style={{marginTop:"70px",color:"black",marginLeft:"50px", fontSize: "34px"}} >
 <div style={{float:"right"}}>
 <img src={profilepic} alt="img1" style={{ maxWidth: '100%', marginLeft:"20px",maxHeight: '100%',marginTop:"0px" }} />
 <Typography style={{ color: " #0EBA70",paddingLeft:"50px",paddingBottom:"0px",marginTop:"0px",fontFamily: 'Poppins', fontStyle: "normal", fontSize: "20px" }}>Kiran</Typography>
 <Typography style={{ fontFamily: 'Poppins', paddingTop:'0px',fontStyle: "normal",marginTop:"0px", fontSize: "12px" }}>Kiran_admin@gmail.com</Typography>
 <Typography style={{ marginLeft:"20px",marginTop:"5px",color: "white",marginTop:"0px",height:"20px",backgroundColor:" #0EBA70",width:"70%",borderRadius:"10px",textAlign:"center",fontFamily: 'Poppins', fontStyle: "normal", fontSize: "12px" }}>Admin User 2</Typography>
 <Typography style={{ fontFamily: 'Poppins', marginLeft:"50px",marginTop:"55px",fontStyle: "normal", fontSize: "16px" }}>Overview</Typography>
<Box
      sx={{
        width: 200,
        height: 200,
        backgroundColor: " #0EBA70",
        border: "0.25px solid #000000"
      }}
    >
    <Typography style={{ fontFamily: 'Poppins', color:"white",marginLeft:"30px",marginTop:"25px",fontStyle: "normal", fontSize: "16px" }}>8957           Products</Typography>
    <Typography style={{ fontFamily: 'Poppins', color:"white",marginLeft:"30px",marginTop:"35px",fontStyle: "normal", fontSize: "16px" }}>875            Orders</Typography>
    <Typography style={{ fontFamily: 'Poppins', color:"white",marginLeft:"30px",marginTop:"35px",fontStyle: "normal", fontSize: "16px" }}>68,957         Total Sales</Typography>

</Box>
<Typography style={{ fontFamily: 'Poppins',marginTop:"15px",fontStyle: "normal", fontSize: "16px" }}>Average Sales</Typography>
<Typography style={{ fontFamily: 'Poppins', marginLeft:"20px",marginTop:"20px",fontStyle: "normal", fontSize: "16px" }}>+12%</Typography>
<Typography style={{ fontFamily: 'Poppins', marginTop:"15px",fontStyle: "normal", fontSize: "16px" }}>Average Orders</Typography>
<Typography style={{ fontFamily: 'Poppins', marginLeft:"20px",marginTop:"15px",fontStyle: "normal", fontSize: "16px" }}>+10%</Typography>
<Typography style={{ fontFamily: 'Poppins', marginTop:"20px",fontStyle: "normal", fontSize: "16px" }}>New Clients</Typography>
<Typography style={{ fontFamily: 'Poppins', marginLeft:"20px",marginTop:"15px",fontStyle: "normal", fontSize: "16px" }}>+3%</Typography>

 </div>
 <div >
 <Typography style={{ fontFamily: 'Poppins', marginLeft:"0px",marginTop:"35px",fontStyle: "normal", fontSize: "16px" }}>HELLO KIRAN </Typography>
 <Typography style={{ fontFamily: 'Poppins', marginLeft:"0px",fontStyle: "normal", fontSize: "10px" }}>Welcome Back! </Typography>
 <Typography style={{ fontFamily: 'Poppins', marginLeft:"20px",marginTop:"35px",fontStyle: "normal", fontSize: "20px" }}>TOTAL SALES REPORT</Typography>

 </div>
 <div style={{marginLeft:'70px'}}>
 
 <ResponsiveContainer width="70%" height={300}>
            <LineChart data={ldata} margin={{ top: 15, right: 0, bottom: 15, left: 0 }}>
            <Legend layout="horizontal" verticalAlign="top" align="left" wrapperStyle={{
          fontSize: '15px', 
           }}/>
              <Tooltip />
              <XAxis dataKey="label" tick={<CustomizedXAxisTick />}/>
              <YAxis tick={<CustomizedXAxisTick />}/>
              <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
              <Line type="monotone" dataKey="orders" stroke="#FB8833" />
              <Line type="monotone" dataKey="customers" stroke="#17A8F5" />
            </LineChart>
          </ResponsiveContainer>
          </div>
          </div>
          <div className="chart-container" style={{marginLeft:'10px'}}>
    <PieChart width={600} height={300}>
    <Legend wrapperStyle={{ marginLeft:'30%',marginTop:'10%' ,fontSize:'16px'}}
        iconSize={10}
        iconType="circle" layout="vertical" verticalAlign="middle" align="right" />
      <Pie
        data={data}
        cx={200}
        cy={200}
        innerRadius={60}
        outerRadius={80}
        cornerRadius={100}
        fill="#8884d8"
        paddingAngle={-5}
        //dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
        <Label width={50} color="#000000" fontSize="15px" fontWeight="bold" position="center">
                SalesBy Category Rs.29500
              </Label>
      </Pie>
    </PieChart>
    </div>
    </div>
  )
}

export default Dashboard1
