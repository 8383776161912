import React, { useState, useEffect } from 'react';
import { Box, Button, Paper, Grid, Typography, Card } from "@material-ui/core";
import theme from "../../../theme";
import { useStyles } from "../BodyStyles";
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import api from "../../api.json"
import { format } from 'date-fns';
import ErrorIcon from '@material-ui/icons/Error';
import dropcust from "../../../Components/assets/dropcust.png";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateRangeIcon from '@material-ui/icons/DateRange';
import {
  IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions, Alert,
  AlertTitle,
} from "@mui/material";

import Spinner from '../Spinner';
const Addcustomers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const today = new Date()

  const [dateof, setDateof] = useState(new Date());

const history = useHistory()
const successIcon = <CheckCircleIcon className="success-icon" />;
const iconMapping = {
  success: successIcon,
};
const errorIcon = <ErrorIcon className="error-icon" />;
const iconMapping3 = {
  error: errorIcon,
};
const [updateStatus, setUpdateStatus] = useState(null);
  const [activeButton, setActiveButton] = useState("a");
  const [search, setSearch] = useState('');
  const [pname, setPname] = useState('');
  const [cname, setCname] = useState("");
  const [category1, setCategory1] = useState("");
  const [brandname, setBrandname] = useState("")
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [price, setPrice] = useState("");
  const [email, setEmail] = useState("");
  const [businesstype, setBusinesstype] = useState("");
  const [district, setDistrict] = useState("");
  const [occupation, setOccupation] = useState("");
  const [address, setAddress] = useState({
    street: '',
  });
  const [id, setID] = useState("")
  const [usertype, setUsertype] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [dateofbirth, setDateofbirth] = useState(new Date());
  const [username, setUsername] = useState("");
  const [isActive2, setIsActive2] = useState(false);
  const [message, setMessage] = useState("");
  const [disable, setDisable] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState({
  
    mobile: "",
    email: "",
    

    msg: "",
    type: ""
  })

  useEffect(() => {
    setID(localStorage.getItem('ID'))
        
    //setListingstatus(localStorage.getItem("lstatus"))

  }, []);
  const [remarks, setRemarks] = useState("");

  const handleInvitationClick = () => {
    setOpenDialog(true);
  };
  const handleSaveClick = () => {
    setOpenDialog(true);
  };
  const isEmpty = (str) => {
    return (!str || str.length === 0);
  }
  const handleCancel_customers = (id) => {
    axios
      .delete(api.baseUrl + "/admin/deletecustomers" + id)
      .then(() => {
        setOpenDialog(false);
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          alert(error.response.data.message);
        } else {
          alert("An error occurred while deleting the product.");
        }
        console.log("Error:", error);
      });
  };

  const handleCancelClick = () => {
   
    setOpenDialog(true);
  };

  const handleSendInvitation = () => {
   
    setOpenDialog1(true);
  };

  const handleSave= () => {
   
    setOpenDialog2(true);
  };
  const handleRemarksChange = (event) => {
    setRemarks(event.target.value);
  };
  //post api
  let handleCreatecustomer = async (e) => {
    e.preventDefault();
    let formIsValid = true;
    let newErrors = {};
    console.log(
    "name:", pname,
      "user_name:", username,
      "mobile:", mobile,
      "email:", email,
      "business_type:",businesstype,
      "password:", password,
      "user_type:", usertype,
      "gender:", gender,
      "date_of_birth:", format(new Date(dateof), 'yyyy-MM-dd'),
      "address:", address,
      "occupation:", occupation,
      "district:" ,district)
    // if (!usertype) {
    //   newErrors.usertype = "*Please Select User";
    //   formIsValid = false;
    // }
    // if (!pname) {
    //   newErrors.pname = "*Please enter a Name";
    //   formIsValid = false;
    // }
    // if (!username) {
    //   newErrors.username = "*Please enter Username";
    //   formIsValid = false;
    // }
    // if (!mobile) {
    //   newErrors.mobile = "*Please enter Mobilenumber";
    //   formIsValid = false;
    // }
    // if (!gender) {
    //   newErrors.gender = "*Please enter gender";
    //   formIsValid = false;
    // }
    // if (!dateof) {
    //   newErrors.dateof = "*Please select Date";
    //   formIsValid = false;
    // }
    // if (!email) {
    //   newErrors.email = "*Please enter an Email";
    //   formIsValid = false;
    // }
    // if (!address) {
    //   newErrors.address = "*Please enter Address";
    //   formIsValid = false;
    // }
    // if (!district) {
    //   newErrors.district = "*Please enter District";
    //   formIsValid = false;
    // }
    // if (!password) {
    //   newErrors.password = "*Please enter a Password";
    //   formIsValid = false;
    // }
    // else if (password.length < 6 || password.length > 8) {
    //   newErrors.password = "*Password must be between 6 and 8 characters";
    //   formIsValid = false;
    // } 
    // else if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,8}$/)) {
    //   newErrors.password = "*Password must include at least one uppercase letter, one lowercase letter, one digit, and one special character";
    //   formIsValid = false;
    // }
    
    // if (!formIsValid) {
    //   setError(newErrors);
    //   return;
    // }
    setDisable(true);
    setIsActive(true);
  
    try {
      let res = await fetch(api.baseUrl + "/admin/createcustomers", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: pname,
          user_name: username,
          mobile: mobile,
          email: email,
          business_type: businesstype,
          password: password,
          user_type: usertype,
          gender: gender,
          date_of_birth: format(new Date(dateof), 'yyyy-MM-dd'),
          address: address,
          occupation: occupation,
          district: district
        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setDisable(false);
        setIsActive(false);
        setUpdateStatus("success");
        setTimeout(() => {
          setUpdateStatus(false);
          history.push("/admincustomers")
        }, 4000);
    
        setMessage("User created successfully");
       
      } else {
        setError({ status: true, message: resJson.message, type: 'error' });
        setUpdateStatus("failed")

        setTimeout(() => {
          setUpdateStatus(false);
        }, 3000);
      }
    } catch (err) {
      console.log(err);
      setDisable(false);
      setIsActive(false);
    }
  };
  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    setDateof(formattedDate);
  }, []);
  return (
    <Box className={classes.section}>
        <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',



      }}>
        {updateStatus === "success" && (
          <Alert iconMapping={iconMapping} severity="success"
            onClose={() => setUpdateStatus(null)}
            style={{
              background: "green", color: "white", marginBottom: theme.spacing(2), marginLeft:theme.spacing(20),position: "fixed", zIndex: 9999,
               [theme.breakpoints.down("xs")]: {
                textAlign: 'center',

                width: "95%", marginBottom: theme.spacing(2)
              },
            }}>
            <AlertTitle>Successfully added customer.</AlertTitle>
          </Alert>
        )}
        {updateStatus === "failed" && (
          <Alert iconMapping={iconMapping} severity="error" onClose={() => setUpdateStatus(null)}
            style={{
              background: "red", color: "white", marginBottom: theme.spacing(2), position: "fixed", zIndex: 9999, [theme.breakpoints.down("xs")]: {
                textAlign: 'center',
                width: "90%",
              },
            }}>
            <AlertTitle> Failed to Add Customer.</AlertTitle>
          </Alert>
        )}
      </div>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Typography variant='h6' className={classes.breadcrum}>
            <a className={classes.breadcrum} href="/dashboard" > Go Back </a>

          </Typography>
          <Typography variant='h5' className={classes.pageTitle} >
            ADD CUSTOMERS
          </Typography>

        </Grid>

        <Paper sx={{ width: '100%', overflow: 'hidden' }}> </Paper>
      </Grid>

      <Box style={{ background: "#e4f8ef" }}>


      <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "400px", fontSize: "20px" }}>USER TYPE</Typography>

      <Select value={usertype} onChange={(e) => setUsertype(e.target.value)}
        className={classes.select} style={{ height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" 
        >

        <MenuItem style={{ background: "#0EBA70", color: "white",height: "50px"}} value={1}>User</MenuItem>
        <MenuItem style={{ background: "#0EBA70", color: "white",height: "50px"}} value={2}>Seller</MenuItem>
        <MenuItem style={{ background: "#0EBA70", color: "white",height: "50px"}} 
        value={3}>Admin</MenuItem>
        <MenuItem style={{ background: "#0EBA70", color: "white",height: "50px"}} value={4}>Super Admin </MenuItem>
<MenuItem style={{ background: "#0EBA70", color: "white",height: "50px"}} value={5}>Seller/Buyer

</MenuItem>
    </Select>
    {!usertype && error.usertype && <div className="error" style={{ color: "red", textAlign: "start" ,marginTop:"-12px" ,
        marginLeft:"12px"}}>{error.usertype}</div>}
    <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "400px", fontSize: "20px" }}>USER NAME</Typography>
        <TextField value={username} onChange={(e) => setUsername(e.target.value)}className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />

        {!username && error.username && <div className="error" style={{ color: "red", textAlign: "start" ,marginTop:"-12px" ,
        marginLeft:"12px"}}>{error.username}</div>}
        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "400px", fontSize: "20px" }}>PASSWORD</Typography>
        <TextField value={password} onChange={(e) => setPassword(e.target.value)}className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />
        {!password && error.password && <div className="error" style={{ color: "red", textAlign: "start" ,marginTop:"-12px" ,
        marginLeft:"12px"}}>{error.password}</div>}
      
      <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "400px", fontSize: "20px",
        letterspacing: "0.001em" }}>NAME</Typography>
        <TextField className={classes.textField} value={pname } onChange={(e) => setPname(e.target.value)} style={{background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px",
            boxSizing: "border-box", marginBottom: "16px"}} fullWidth variant="outlined" />
  {!pname && error.pname && <div className="error" style={{ color: "red", textAlign: "start" ,marginTop:"-12px" ,
        marginLeft:"12px"}}>{error.pname}</div>}

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "400px", fontSize: "20px" }}>EMAIL</Typography>
        <TextField value={email} onChange={(e) => setEmail(e.target.value)} className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />
        
        {!email && error.email && <div className="error" style={{ color: "red", textAlign: "start" ,marginTop:"-12px" ,marginLeft:"12px"}}>{error.email}</div>}
      

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "400px", fontSize: "20px" }}>MOBILE</Typography>
        <TextField value={mobile}    onKeyPress={(e) => {
                const allowedKeys = /[0-9]/;
                const key = e.key;
                if (!allowedKeys.test(key)) {
                  e.preventDefault();
                } else if (mobile.length >= 10) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => setMobile(e.target.value)}pattern="[0-9].*"
         
       
        className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px",
         boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />
            
            {!mobile && error.mobile && <div className="error" style={{ color: "red", textAlign: "start" ,marginTop:"-12px" ,
        marginLeft:"12px"}}>{error.mobile}</div>}
        
       


            <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "400px", fontSize: "20px" }}>GENDER</Typography>
                
        <Select value={gender } onChange={(e) => setGender(e.target.value)}
        className={classes.select} style={{ height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" 
        >

        <MenuItem style={{ background: "#0EBA70", color: "white",height: "50px"}}value="male">Male</MenuItem>
        <MenuItem  style={{ background: "#0EBA70", color: "white",height: "50px"}} value="female">Female</MenuItem>
        <MenuItem  style={{ background: "#0EBA70", color: "white",height: "50px"}} value="others">Others</MenuItem>
     

    </Select>
    {!gender && error.gender && <div className="error" style={{ color: "red", textAlign: "start" ,marginTop:"-12px" ,
        marginLeft:"12px"}}>{error.gender}</div>}

        <Typography style={{ color: "black",fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "400px", fontSize: "20px" }}>DATE OF BIRTH</Typography>
        <TextField type="date" placeholder="Select Date" value={dateof}  onChange={(e) => setDateof(e.target.value)}
        onKeyDown={(e) => {e.preventDefault();}}  
        requiredInputProps={{
        readOnly: true, // disable  manual entry 
         }}  
         className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000",
          borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />
           {!dateof && error.dateof && <div className="error" style={{ color: "red", textAlign: "start" ,marginTop:"-12px" ,
        marginLeft:"12px"}}>{error.dateof}</div>}
           
        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "400px", fontSize: "20px" }}>ADDRESS</Typography>
        <TextField value={address.street}
         //onChange={(e) => setAddress({ ...address, street: e.target.value })} 
         onChange={(e) => setAddress( e.target.value )} 
         name='address'
        className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />
        {!address.street && error.address && <div className="error" style={{ color: "red", textAlign: "start" ,marginTop:"-12px" ,
        marginLeft:"12px"}}>{error.address}</div>}
       
        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "400px", fontSize: "20px" }}>OCCUPATION</Typography>
        <TextField value={occupation} onChange={(e) => setOccupation(e.target.value)}className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />
       
       

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "400px", fontSize: "20px" }}>BUSINESS TYPE</Typography>
        <TextField value={businesstype} onChange={(e) => setBusinesstype(e.target.value)}className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />


        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "400px", fontSize: "20px" }}>DISTRICT</Typography>
        <TextField value={district} onChange={(e) => setDistrict(e.target.value)}className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />
        {!district && error.district && <div className="error" style={{ color: "red", textAlign: "start" ,marginTop:"-12px" ,
        marginLeft:"12px"}}>{error.district}</div>}
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
       
          <Button onClick={() => handleSendInvitation()} style={{width: "185px", height: "45px", fontFamily: 'Poppins', color: " #FFFFFF", borderRadius: "5px",
            background: "#84240B", textTransform: "none", marginLeft: theme.spacing(3), borderRadius: "3", fontweight: 500, fontSize: "14px"
          }}>
              SEND INVITATION</Button>
          
              
              <Button  onClick={() => handleCancelClick()} style={{width: "185px", height: "45px", fontFamily: 'Poppins', color: " #FFFFFF", borderRadius: "5px",
            background: "#D9D9D9", textTransform: "none", marginLeft: theme.spacing(3), borderRadius: "3", fontweight: 500, fontSize: "14px"
          }}>
              CANCEL</Button>

              
              <Button  onClick={handleCreatecustomer}style={{width: "185px", height: "45px", fontFamily: 'Poppins', color: " #FFFFFF", borderRadius: "5px",
            background: "#0EBA70", textTransform: "none", marginLeft: theme.spacing(3), borderRadius: "3", fontweight: 500, fontSize: "14px"
          }}>
              SAVE</Button>
</div>

              <Dialog  PaperProps={{
    style: {
      width: '500px', // Adjust the width as per your requirements
      backgroundColor: '#F0F0F0', // Change the background color
    },
  }} open={openDialog} onClose={() => setOpenDialog(false)}>
  <DialogContent>
    <DialogContentText style={{color:"#000000",width: "350px",height: "60px",left: "591px",
top: "407px",fontFamily: 'Poppins', fontStyle: 'normal',fontWeight: "400",
fontSize: "20px",lineHeight: "30px",letterspacing: "0.001em"}}>
      Do you want to cancel adding customer information?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenDialog(false)} style={{color:"black",fontSize:"16px",fontWeight:400,
    boxSizing: "border-box",width: "100px",height: "45px",background: "#FFFFFF",border: "1px solid #0EBA70",
borderRadius: "10px"}}>Yes</Button>
    <Button style={{color:"black",fontSize:"16px",fontWeight:400,
    boxSizing: "border-box",width: "100px",height: "45px",background: "#0EBA70",border: "1px solid #0EBA70",
borderRadius: "10px"}} onClick={() => {
      handleCancel_customers(selectedContact._id);
      setSelectedContact(null);
    }}>No</Button>
  </DialogActions>
</Dialog>

<Dialog PaperProps={{
    style: {
      width: '400px', // Adjust the width as per your requirements
      backgroundColor: '#F0F0F0', // Change the background color
    },
  }}
 open={openDialog1} onClose={() => setOpenDialog1(false)}>
  <DialogContent>
    <DialogContentText style={{color:"#000000",width: "350px",height: "60px",left: "591px",
top: "407px",fontFamily: 'Poppins', fontStyle: 'normal',fontWeight: "400",
fontSize: "20px",lineHeight: "30px",letterspacing: "0.001em"}}>
      Do you want to send Invitation?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenDialog1(false)} style={{color:"black",fontSize:"16px",fontWeight:400,
    boxSizing: "border-box",width: "100px",height: "45px",background: "#FFFFFF",border: "1px solid #0EBA70",
borderRadius: "10px"}}>Yes</Button>
    <Button 
    style={{color:"black",fontSize:"16px",fontWeight:400,
    boxSizing: "border-box",width: "100px",height: "45px",background: "#0EBA70",border: "1px solid #0EBA70",
borderRadius: "10px"}}
onClick={() => setOpenDialog1(false)}>No</Button>
  </DialogActions>
</Dialog>

<Dialog PaperProps={{
    style: {
      width: '400px', // Adjust the width as per your requirements
      backgroundColor: '#F0F0F0', // Change the background color
    },
  }} open={openDialog2} onClose={() => setOpenDialog2(false)}>

  <DialogContent>
    <DialogContentText style={{color:"#000000",width: "350px",height: "60px",left: "591px",
    top: "407px",fontFamily: 'Poppins', fontStyle: 'normal',fontWeight: "400",
    fontSize: "20px",lineHeight: "30px",letterspacing: "0.001em"}}>
      Successfully addedcustomer and customer ID is generated.
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenDialog2(false)} style={{color:"black",fontSize:"16px",fontWeight:400,
    boxSizing: "border-box",width: "100px",height: "45px",background: "#FFFFFF",border: "1px solid #0EBA70",
borderRadius: "10px"}}>Yes</Button>
    <Button style={{color:"black",fontSize:"16px",fontWeight:400,
    boxSizing: "border-box",width: "100px",height: "45px",background: "#0EBA70",border: "1px solid #0EBA70",
borderRadius: "10px"}}
     onClick={() => {
      handleSave(selectedContact._id);
      setSelectedContact(null);
    }}>No</Button>
  </DialogActions>
</Dialog>

             


      
      </Box>
    </Box>



  )
}

export default Addcustomers





