import {React,useState} from 'react'
import { useStyles } from "../BodyStyles";
import { Box,Grid,Button, Typography,Table,TableCell,TableContainer,TableRow,TableHead,Paper, TableBody } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import calicon from "../../../Components/assets/calicon.png";
import { LocalizationProvider, DateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { IconButton, TextField } from "@mui/material";
import theme from "../../../theme";
import  palmjaggery from '../../../Components/assets/palmjaggery.png';
import chillies from '../../../Components/assets/chillies.png';
import fruits from '../../../Components/assets/fruits.png';
const product = [
{
     no: '1',
     id: '#25465555',
     name: 'Palm Jaggery',
     category: 'Staples',
     date: '12/09/2022',
     price: 'Rs.350'
   },
   {
     no: '2',
     id: '#25465595',
     name: 'Cucumbers',
     category: 'Fresh vegetables',
     date: '12/09/2022',
     price: 'Rs.60'
  },

 {
     no: '3',
     id: '#25465559',
     name: 'Thai Chillies',
     category: 'Staples',
     date: '12/09/2022',
     price: 'Rs.650'
   },
   {
     no: '4',
     id: '#25465554',
     name: 'Apples',
     category: 'Fruits',
     date: '12/09/2022',
     price: 'Rs.760'
   },
   {
     no: '5',
     id: '#25465557',
     name: 'Tomatoes',
     category: 'Vegetables',
     date: '12/09/2022',
     price: 'Rs.280'
       },
 ];

const Offers = () => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
   
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
    const [showOverlay, setShowOverlay] = useState(false);

  const handleDateRangeChange = (date) => {
    setSelectedDateRange(date);
  };

  const handleClick = () => {
    setShowOverlay(true);
  };
  

  const Overlay = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        <DateRangePicker
          startText="Start Date"
          endText="End Date"
          value={selectedDateRange}
          onChange={handleDateRangeChange}
          
        />
      </LocalizationProvider>
    );
  };
  return (
    <div>
    <Box className={classes.section}>
    <Grid container justifyContent= "flex-end">
    <Grid item xs={12} sm={12}>
      <Typography variant='h6' className={classes.breadcrum}>
      <a  className={classes.breadcrum} href="/dashboard" > Go Back </a>
     
      </Typography>
    
      <div style={{ display: "flex", justifyContent: "end", alignItems: "center", marginTop:"-25px" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
          <TextField name="search"
            placeholder="  Search"
            className={classes.rese}
            id="standard-bare"
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </div>
        <Button
              onClick={handleClick}
              style={{ width: "200px", fontFamily: 'Poppins', height: "30px", color: " #FFFFFF", borderRadius: "5px", background: "#0EBA70", textTransform: "none", marginLeft: theme.spacing(3), borderRadius: "3", fontweight: 500, fontSize: "14px" }}
            > Start Date-End Date
            <td>
            <Box
              component="img"
              style={{alignItems: "left",
                marginLeft: 2,
                marginTop: 5,
                marginLeft: theme.spacing(1),
                maxHeight: { xs: 2, md: 2 },
                maxWidth: { xs: 350, md: 250 },
              }}
              src={calicon}
            />
          </td>
             
            </Button>      
             </div> 
             <div style={{ display: "flex", flexDirection: "row", justifyContent: "end"}}>
             {showOverlay && <Overlay />}
             </div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography variant='h5' className={classes.noti} >
        APPROVED PRODUCT LIST
        </Typography>
        <Button>
        <div style={{ width: "120px", height: "30px", paddingLeft:"50px",fontweight: 500, fontSize: "16px",Align:"center",color: " #FFFFFF", background:'#0EBA70',display: "flex", flexDirection: "row"}}>SAVE</div>
        </Button>
        </Box>
        </Grid>
        <TableContainer >
    <Table stickyHeader aria-label="sticky table">
      <TableHead >
        <TableRow sx={{
          
            fontStyle: "bold",
            fontWeight: "400",
            backgroundColor: "white",
            color: "black",
            fontSize: "18px",
            width: "1213px",

          
        }}>

          <TableCell align="center" >#</TableCell>
          <TableCell align="center" >Product ID</TableCell>
          <TableCell align="center" >Name </TableCell>
          <TableCell align="center" >Category</TableCell>
          <TableCell align="center" >Date of Listing</TableCell>
          <TableCell align="center" > Product Price</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
        
         {product.length > 0 && product.map((product) =>
           <TableRow key={product.id} sx={{
          
              fontSize: "16px",
              fontWeight: "300",
              fontStyle: "normal",
              alignItems: "center",
              height: "50px !important",
              width: "1213px !important",
              padding: "2px !important",
            
          }}>
         
          <TableCell align="center">{product.no} </TableCell>
          <TableCell align="center" >{product.id} </TableCell>
          <TableCell align="center">{product.name}</TableCell>
          <TableCell align="center" >{product.category}</TableCell>
          <TableCell align="center" >{product.date}</TableCell>
          <TableCell align="center" >{product.price}</TableCell>
          </TableRow>
        )}
      </TableBody>
      </Table>
        </TableContainer>
      
             </Grid>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography variant='h5' className={classes.noti} >
        PRODUCTS ON DEALS/DISCOUNT
        </Typography>
</Box>
<TableContainer >
    <Table stickyHeader aria-label="sticky table"/>
      </TableContainer>
      <Grid container spacing={10} >
          <Grid item xs={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={200}
              width={200}
              borderRadius={20}        
            
            >
              <img src={palmjaggery} alt="img1" style={{ maxWidth: '100%', maxHeight: '100%' }} />
              <h1 style={{color:'white',width:'10%',fontFamily:'poppins',marginTop:'60px',backgroundColor:'black',fontSize:'20px',textAlign:'center',fontWeight:'normal',position:'absolute',height:'fit-content'}}>Palm Jaggery</h1>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
             
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={200}
              width={200}
             
              borderRadius={20}        
            
            >
              <img src={chillies} alt="img1" style={{ maxWidth: '100%', maxHeight: '100%' }} />
              <h3 style={{color:'white',width:'10%',fontFamily:'poppins',marginTop:'60px',backgroundColor:'black',fontSize:'20px',textAlign:'center',fontWeight:'normal',position:'absolute',height:'fit-content'}}>Thai Chillies</h3>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
             
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={200}
              width={200}
              
              borderRadius={20}        
            
            >
              <img src={fruits} alt="img1" style={{ maxWidth: '100%', maxHeight: '100%' }} />
              
              <h3 style={{color:'white',width:'10%',fontFamily:'poppins',marginTop:'60px',backgroundColor:'black',fontSize:'20px',textAlign:'center',fontWeight:'normal',position:'absolute',height:'fit-content'}}>Fresh Fruits</h3>
            </Box>
          </Grid>
          </Grid>
        </div>
        
  )
}

export default Offers