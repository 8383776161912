import React, { useState,useEffect } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Typography, Grid, IconButton, Box, TextField } from '@mui/material';
import { useStyles } from "../BodyStyles";
import axios from 'axios';
import api from "../../api.json";
import SearchIcon from "@material-ui/icons/Search";
import "./Category.css"
const majorData = [
  {
    id: 1,
    label: 'Major 1',
    subData: [
      {
        id: 1,
        label: 'Sub 1',
        nestedData: [
          { id: 1, label: 'Nested 1' },
          { id: 2, label: 'Nested 2' },
          
        ],
      },
      {
        id: 2,
        label: 'Sub 2',
        nestedData: [
          { id: 3, label: 'Nested 3' },
          { id: 4, label: 'Nested 4' },
          // Add more nested items as needed
        ],
      },
      // Add more sub items as needed
    ],
  },
  {
    id: 2,
    label: 'Major 2',
    subData: [
      {
        id: 1,
        label: 'Sub 1',
        nestedData: [
          { id: 1, label: 'Nested 1' },
          { id: 2, label: 'Nested 2' },
          
        ],
      },
      {
        id: 2,
        label: 'Sub 2',
        nestedData: [
          { id: 3, label: 'Nested 3' },
          { id: 4, label: 'Nested 4' },
          // Add more nested items as needed
        ],
      },
    ],
  },
  // Add more major items as needed
];

const Categorylist = () => {
  const [selectedMajor, setSelectedMajor] = useState(null);
  const [selectedSub, setSelectedSub] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [subclassifications, setSubclassifications] = useState([]);

  const getNotification = () => {
    axios
      .get(api.baseUrl + "/admin/getall_v2")
      .then((response) => {
        const { data } = response.data;
        const categories = data.map((category) => ({
          _id: category._id,
          name: category.name,
          subcategories: category.subcategories.map((subcategory) => ({
            _id: subcategory._id,
            sub_category_name: subcategory.sub_category_name,
            subclassifications: subcategory.subclassifications.map((subclass) => ({
              _id: subclass._id,
              sub_classification_name: subclass.sub_classification_name
            }))
          }))
        }));
  
        setCategories(categories);
        setSubcategories([]);
        setSubclassifications([]);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  

  useEffect(() => {
    getNotification();
  }, []);
  const requestSearch = (searchedVal) => {
    console.log("searchedVal", searchedVal);
    axios
      .get(api.baseUrl + "/admin/searchcategory/" + searchedVal)
      .then((response) => {
        setCategories(response.data.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const [searchQuery, setSearchQuery] = useState('');
  const filteredCategories = categories.filter((contact) => {
    return (
      searchQuery.trim() === '' || // If the search query is empty, show all items
      Object.values(contact).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  });
  

  const handleMajorClick = (major) => {
    if (selectedMajor === major) {
      setSelectedMajor(null);
    } else {
      setSelectedMajor(major);
      setSelectedSub(null);
    }
  };

  const handleSubClick = (sub) => {
    if (selectedSub === sub) {
      setSelectedSub(null);
    } else {
      setSelectedSub(sub);
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.section} style={{padding:"20px",margin:"-20px",background:" rgba(14, 186, 112, 0.1)"}}>
    <Grid item xs={12} sm={12}>
      <Typography variant='h6' className={classes.breadcrum}>
        <a className={classes.breadcrum} href="/dashboard">
          Go Back
        </a>
      </Typography>
  
      <div style={{ display: "flex", justifyContent: "end", alignItems: "center", marginTop: "30px" }}>
        <div style={{ display: "flex", justifyContent: "center", marginRight: "10px" }}>
          <TextField
            name="search"
            placeholder="  Search"
            className={classes.rese}
           // onChange={(e) => requestSearch(e.target.value)}
            id="standard-bare"
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h5' className={classes.noti}>
          CATEGORIES LIST
        </Typography>
      </Box>
    </Grid>
    <div style={{ display: 'flex',background:"rgba(14, 186, 112, 0.2)" }}>
  {/* Major Categories */}
  <div style={{ flex: 1, marginRight: '10px' }}>
    <Table style={{ border: '0.2px solid gray', borderBottom: '0.2px solid gray' }}>
      <TableHead>
        <TableRow>
          <TableCell style={{ fontSize: '20px', borderBottom: '0.2px solid gray',color: '#000000' }}>MAJOR CATEGORIES</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredCategories.map((majorItem) => (
          <TableRow key={majorItem._id}>
            <TableCell
              onClick={() => handleMajorClick(majorItem)}
              style={{
                cursor: 'pointer',
                fontWeight: selectedMajor === majorItem ? 'bold' : 'normal',
                background: selectedMajor === majorItem ? ' #FBBC05' : '#FFFFFF',
              }}
            >
              {majorItem.name}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>

  {/* Sub-Categories */}
  <div style={{ flex: 1, marginRight: '10px' }}>
    <Table style={{ border: '0.2px solid gray', borderBottom: '0.2px solid gray' }}>
      <TableHead>
        <TableRow>
          <TableCell style={{ fontSize: '20px', borderBottom: '0.2px solid gray',color: '#000000' }}>SUB - CATEGORIES</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {selectedMajor &&
          selectedMajor.subcategories.map((subItem) => (
            <TableRow key={subItem._id}>
              <TableCell
                onClick={() => handleSubClick(subItem)}
                style={{
                  cursor: 'pointer',
                  fontWeight: selectedSub === subItem ? 'bold' : 'normal',
                  background: selectedSub === subItem ? ' #FBBC05' : '#FFFFFF',
                }}
              >
                {subItem.sub_category_name}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </div>

  {/* Sub-Classification */}
  <div style={{ flex: 1 }}>
    <Table style={{ border: '0.2px solid gray', borderBottom: '0.2px solid gray' }}>
      <TableHead>
        <TableRow>
          <TableCell style={{ fontSize: '20px', color: '#000000', borderBottom: '0.2px solid gray'}}>SUB - CLASSIFICATION</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {selectedSub &&
          selectedSub.subclassifications.map((nestedItem) => (
            <TableRow key={nestedItem._id}>
              <TableCell style={{background:" #FBBC05"}}>{nestedItem.sub_classification_name}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </div>
</div>



  </div>
  
  
  );
};


export default Categorylist