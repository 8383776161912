import React from 'react'

const Paymentreports = () => {
  return (
    <div style={{marginTop:"250px",color:"black",marginLeft:"500px", fontSize: "34px"}} >
    Coming Soon
       </div>
  )
}

export default Paymentreports