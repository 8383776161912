import React, { useState, useEffect } from 'react';
import { Box, Button, Paper, Grid, Typography, Tooltip } from "@material-ui/core";
import theme from "../../../theme";
import { useStyles } from "../BodyStyles";
//import data from "./mockdata_individualproductslist.json";
import { IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, Alert,
  AlertTitle,TablePagination, TableRow, TextField } from "@mui/material";
import axios from 'axios';
import api from "../../api.json"
import approved1 from "../../../Components/assets/approved.png";
import pending1 from "../../../Components/assets/pending.png";
import declined1 from "../../../Components/assets/declined.png";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import image132 from "./image132.png";
import approveimg from "./approveimg.png";
import declinedimg from "./declinedimg.png";
import Modal from '@material-ui/core/Modal';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

//var pageNumber=1;
const Adslist = () => {
  const classes = useStyles();
  const successIcon = <CheckCircleIcon className="success-icon" />;
const iconMapping = {
  success: successIcon,
};
const errorIcon = <ErrorIcon className="error-icon" />;
const iconMapping3 = {
  error: errorIcon,
};
const [updateStatus, setUpdateStatus] = useState(null);
  const history=useHistory();
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageModalOpen(true);
  };
  const closeImageModal = () => {
    setImageModalOpen(false);
  };

  //const [orders, setOrders] = useState(data);
  //const [count, setCount] = useState(data.length);
  const [activeButton, setActiveButton] = useState("a");
  const [search, setSearch] = useState('');
  const [offset, setOffset] = useState(0);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [cate, setCate] = useState([])
  const [adminProducts, setAdminProducts] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);

  //const [hasNextPage, setHasNextPage] = useState(false);
  const handleNextClick = () => {
    const nextPageNumber = pageNumber + 1;
    setPageNumber(nextPageNumber);
    getproducts1(nextPageNumber);
    console.log("paghgj",pageNumber)
  };
  const limit = 10;
  const handlePrevClick = () => {
    if (pageNumber > 1) {
      const prevPageNumber = pageNumber - 1;
      setPageNumber(prevPageNumber);
      getproducts1(prevPageNumber);
      console.log("previous",pageNumber)
    }
  };
  const [disable2, setDisable2] = useState(true);
  const [isActive2, setIsActive2] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

 
  const getproducts1 = (page) => {
    
    const startRow = page;
    const endRow = page * limit;
    console.log("latest",limit,startRow,endRow)
    axios
      .get(api.baseUrl + "/admin/getallads")
      .then((response) => {
        setAdminProducts(response.data);
      
        console.log("setAdminProducts ----->", response.data);
      })
      .catch((err) => {
        console.log("error", err);
        // Handle the error condition and set the state accordingly
        setAdminProducts([]);
       
      });

  }
  const filteredAdminProducts = adminProducts.filter((contact) => {
    return (
      searchQuery.trim() === '' || // If the search query is empty, show all items
      Object.values(contact).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  });
 
  const tabled = (val) => {
    setActiveButton(val);
if (val == 'd') {
  console.log("Approved")
  setSearch('approved')
}
if (val == 'p') {
  console.log("Pending")
  setSearch('pending')

}
if (val == 'c') {
  console.log("declined")
  setSearch('declined')
}
if (val == 'a') {
  console.log("all")
  setSearch('')
}

//getproducts1()
  };

  useEffect(() => {

    getproducts1(pageNumber)

  }, [search,pageNumber]);
  
  const [status, setStatus] = useState('Status');
const [comments, setComments] = useState('Comments');

const handleStatusChange = (newStatus) => {
  if (newStatus === 'active') {
    setStatus('ACTIVE');
    setComments('');
  } else if (newStatus === 'declined') {
    setStatus('Comments');
    setComments('');
  }
};
let handleapprove = async (id) => {
  
  try {
    let res = await fetch(api.baseUrl + "/admin/updateadstatus", {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        _id: id,
       status: "approved"

      }),
    });
    let resJson = await res.json();
    if (res.status === 200) {
      console.log("approve api response:",resJson)
      getproducts1()
      setUpdateStatus("success");
        setTimeout(() => {
          setUpdateStatus(false);
        }, 3000);
    }

    else {
      console.log("some error in approve api")
    }
  } catch (err) {
    console.log(err);
  }
};
let handledecline = async (id) => {
  
  try {
    let res = await fetch(api.baseUrl + "/admin/updateadstatus", {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        _id: id,
       status: "declined"

      }),
    });
    let resJson = await res.json();
    if (res.status === 200) {
      console.log("decline api response:",resJson)
      getproducts1()
      setUpdateStatus("failed")
      setTimeout(() => {
        setUpdateStatus(false);
      }, 3000);
    }

    else {
      console.log("some error in decline api")
    }
  } catch (err) {
    console.log(err);
  }
};
const hasNextPage = adminProducts.length === limit;
  return (

    <Box className={classes.section}>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      {updateStatus === "success" && (
          <Alert iconMapping={iconMapping} severity="success"
            onClose={() => setUpdateStatus(null)}
            style={{
              background: "green", color: "white", marginBottom: theme.spacing(2), marginLeft:theme.spacing(20),position: "fixed", zIndex: 9999,
               [theme.breakpoints.down("xs")]: {
                textAlign: 'center',

                width: "95%", marginBottom: theme.spacing(2)
              },
            }}>
            <AlertTitle> Ad Image Approved successfully</AlertTitle>
          </Alert>
        )}
        {updateStatus === "failed" && (
          <Alert iconMapping={iconMapping3} severity="error" onClose={() => setUpdateStatus(null)}
            style={{
              background: "red", color: "white", marginBottom: theme.spacing(2), position: "fixed", zIndex: 9999, [theme.breakpoints.down("xs")]: {
                textAlign: 'center',
                width: "90%",
              },
            }}>
            <AlertTitle> Ad Image Declined successfully</AlertTitle>
          </Alert>
        )}
        </div>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Typography variant='h6' className={classes.breadcrum}>
            <a className={classes.breadcrum} href="/dashboard" > Go Back </a>
      </Typography>
      <div style={{ display: "flex", justifyContent: "end", alignItems: "center", marginTop:"-20px" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
      <TextField name="search"
        placeholder="  Search"
        className={classes.rese}
        id="standard-bare"
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>
    </div>




      <Typography variant='h5' className={classes.pageTitle} >
        ADS LISTING
      </Typography>
      
</Grid>

<Paper sx={{ width: '100%', overflow: 'hidden' }}>


  <Button onClick={(e) => tabled('a')} style={{
    width: "200px", height: "30px", borderRadius: "5px", color: " #FFFFFF",
    background: activeButton === 'a' ? '#0EBA70' : '#84240B', textTransform: "none", marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3), fontweight: 500, fontSize: "14px"
  }}>All Ads</Button>
  <Button className='btn' onClick={(e) => tabled('d')} style={{
    width: "200px", height: "30px", color: " #FFFFFF", borderRadius: "5px", background: activeButton === 'd' ? '#0EBA70' : '#84240B',

    textTransform: "none", marginBottom: theme.spacing(3), marginLeft: theme.spacing(3), fontweight: 500, fontSize: "14px"
  }}>Approved</Button>
  <Button className='btn' onClick={(e) => tabled('p')} style={{
    width: "200px", height: "30px", color: " #FFFFFF", borderRadius: "5px",
    background: activeButton === 'p' ? '#0EBA70' : '#84240B', textTransform: "none", marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3), fontweight: 500, fontSize: "14px"
  }}>Pending</Button>
  <Button className='btn' onClick={(e) => tabled('c')} style={{
    width: "200px", height: "30px",
    color: " #FFFFFF", borderRadius: "5px", background: activeButton === 'c' ? '#0EBA70' : '#84240B',
     textTransform: "none",
    marginBottom: theme.spacing(3), marginLeft: theme.spacing(3), fontweight: 500, fontSize: "14px"
  }}>Declined</Button>

  <TableContainer >
    <Table stickyHeader aria-label="sticky table">
      <TableHead >
        <TableRow sx={{
          "& th": {

            fontStyle: "normal",
            fontWeight: "400",
            backgroundColor: "white",
            color: "black",
            fontSize: "18px",
            fontWeight: "400",
            width: "1213px",

          }
        }}>

          <TableCell align="center" >#</TableCell>
          <TableCell align="center" >Seller ID</TableCell>
          <TableCell align="center" >Ad ID </TableCell>
          <TableCell align="center" >Image</TableCell>
          <TableCell align="center" >Date </TableCell>
          <TableCell align="center" > Description</TableCell>
          <TableCell align="center" >
  {search === 'pending' 
    ? (
      <>
        <span onClick={() => handleStatusChange('active')}>Action</span>
        
      </>
    ) 
    : (
      <>
        <span>{status}</span>
        <span >{status === 'declined' ? comments : ''}</span>

      </>
    )
  }
  
</TableCell>




        </TableRow>
      </TableHead>
      <TableBody>

      {filteredAdminProducts.filter((contact) => {
          // define background and set its value to ''

      return  (
        search.toLowerCase() === "" ||
        (contact.status &&
          contact.status.toLowerCase().includes(search))
      );
    }).map((contact,index) =>



          <TableRow key={contact.id} sx={{
            "& td": {
              fontSize: "16px",
              fontWeight: "300",
              fontStyle: "normal",
              alignItems: "center",
              height: "50px !important",
              width: "1213px !important",
              padding: "2px !important"
            }
          }}>
            <TableCell align="center">{index + 1}</TableCell>
            <TableCell align="center">{contact.seller_id}</TableCell>
            <TableCell align="center">{contact.product_name}</TableCell>
              <TableCell align="left">
              <div style={{ display: 'flex', alignItems: 'center' }}>
    <img src={api.baseUrl+'/'+contact.image_url} alt="img"  style={{
    marginLeft: 1,
    maxHeight: '150px',
    maxWidth: '50px',
    marginRight: '10px' 
  }} />
    <a href='#'  onClick={() => openImageModal(api.baseUrl+'/'+contact.image_url)} > ViewImage</a>
    </div>
  </TableCell>

            <TableCell align="center">{contact.created_at.substring(0, 10)}</TableCell>
            <TableCell align="center">{contact.description}</TableCell>
            {/*} <TableCell align='center'>{contact.product_status}</TableCell>*/}

            <TableCell align="center" style={{marginLeft:"20px"}}>
                {contact.status === 'approved' || contact.status==='2'? (
                  <div style={{ backgroundColor: '#0EBA70' ,width:"200px",height:"30px",borderRadius:"5px",display:"flex",flexDirection:"row",margin:"20px",
                   justifyContent:"space-evenly"}}>
                    <img src={approved1} alt="Approved"  style={{color:"#FFFFFF"}}/>
                    <div style={{color:"#FFFFFF",fontSize:"18px"}}>Approved</div>
                  </div>
                ) : activeButton === 'p' ? (
                  <div style={{  width: "250px", height: "30px",textAlign:"center", borderRadius: "5px", display: "flex", flexDirection: "row", margin: "20px", justifyContent: "space-evenly" }}>
                   
                   <div style={{ color: "#FFFFFF", fontSize: "18px" }}>
                  
  <Tooltip title="Approved" >
    <img src={approveimg} alt="Approved" onClick={() => handleapprove(contact._id)}/>
  </Tooltip>&nbsp;&nbsp;
  <Tooltip title="Declined">
    <img src={declinedimg} alt="Declined"onClick={() => handledecline(contact._id)} />
  </Tooltip>
</div>

                  </div>

                ): contact.status === '1'|| contact.status==='pending' ? (
                  <div style={{ backgroundColor: '#F03E00' ,width:"200px",height:"30px",borderRadius:"5px",display:"flex",flexDirection:"row",margin:"20px",
                   justifyContent:"space-evenly"}}>
                    <img src={pending1} alt="Pending" style={{color:"#FFFFFF"}}/>
                    <div  style={{color:"#FFFFFF",fontSize:"18px"}}>Pending</div>
                  </div>
                ) : (
                  <div style={{ backgroundColor: '#FBBC05' ,width:"200px",height:"30px",borderRadius:"5px",display:"flex",flexDirection:"row",margin:"20px",
                  justifyContent:"space-evenly"}}> 
                    <img src={declined1} alt="Declined"  style={{color:"#FFFFFF"}}/>
                    <div style={{color:"#FFFFFF",fontSize:"18px"}}>Declined</div>
                  </div>
                )}
              </TableCell>
          </TableRow>
        )}
      </TableBody>
      <Modal open={isImageModalOpen} onClose={closeImageModal}   aria-labelledby="modal-title"
  aria-describedby="modal-description"
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', borderRadius: '20px',
  }}>
        <div  >
          <img src={selectedImage} alt="Selected Image"  style={{
      width: '365px',
      height: '255px',
      
      borderRadius: '20px',
      background: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
    }}/>
        </div>
      </Modal>
    </Table>
  </TableContainer>
 
</Paper>

  </Grid>
  <Box style={{ textAlign: "end" }}>
      <Button onClick={handlePrevClick} style={{ color: "#590F72" }}>
        Prev
      </Button>
      <strong style={{ color: "blue", justifyContent: "space-between" }}>
        {pageNumber}
      </strong>
      <Button onClick={handleNextClick} style={{ color: "#590F72",opacity: !hasNextPage ? '60%' : '',
          color: !hasNextPage ? '' : '',}} disabled={!hasNextPage}>
        Next
      </Button>
    </Box>
</Box>

  )
}

export default Adslist