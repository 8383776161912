import React,{ useState, useEffect }  from 'react'
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    Button,
    Typography,
    BottomNavigation ,
  
} from "@material-ui/core";
import api from "../Components/api.json";

import { useStyles } from "./HeaderStyles";
var pageNumber = 1;
export default function FooterComp() {
    const classes = useStyles();
    const [disable, setDisable] = useState(0);
    const [contacts, setContacts] = useState([]);
    const [offset, setOffset] = useState(0);
    const [message, setMessage] = useState("");
    const [categorySortOrder, setCategorySortOrder] = useState("asc")
    const [data, setData] = useState([]);
    const [isActive1, setIsActive1] = useState(false);
    const handleCategorySort = () => {
        setCategorySortOrder(categorySortOrder === "asc" ? "desc" : "asc");
        setData(data.sort((a, b) => {
          if (categorySortOrder === "asc") {
            return a.category.localeCompare(b.category);
          } else {
            return b.category.localeCompare(a.category);
          }
        }));
      };
  const handleNextClick = (e) => {
    var pageInt = parseInt(pageNumber)
    pageInt = pageInt + 1;
    pageNumber = pageInt.toString();

    console.log("console", pageNumber)
    getordersummary(e)
    const selectedPage = e.selected;
    setOffset(pageNumber + 1)
  };
  const handlePrevClick = (e) => {
    var pageInt = parseInt(pageNumber)
    if (pageInt > 1) {
      pageInt = pageInt - 1;
    }

    pageNumber = pageInt.toString();

    console.log("console", pageNumber)
    getordersummary(e)
    const selectedPage = e.selected;
    setOffset(pageNumber - 1)

  };
  async function getordersummary(e) {
    if (e) {
      e.preventDefault();
    }

    setDisable(true);
    setIsActive1(true);
    setIsNextButtonDisabled(true)

    let isNextDisabled = false;

   
  
    try {
      let res = await fetch(api.baseUrl + "/getordersummary/" + pageNumber, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({

        
        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        console.log("SUCCESS", resJson);
        const result = res.data;
        setDisable(false);
        setIsNextButtonDisabled(false)

        setMessage("User created successfully");
        
        if (resJson.data.length === 0 && resJson.data.length === 0) {
          alert("Data Not Found");
          setIsNextButtonDisabled(true)
          isNextDisabled = true;
        } else {

          setData(resJson.data);
          setContacts(resJson.data);


        }
      }
      else {

        setMessage("Some error occured");
        setIsNextButtonDisabled(false)
      }
    } catch (err) {
      console.log(err);
      setDisable(false);

    }
    //}
    setIsNextButtonDisabled(isNextDisabled);


  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [count, setCount] = useState()
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {

    //console.log('handleChangeRowsPerPage triggered');
    const event = {
      preventDefault: () => { },
      target: { value: e.target.value }
    };
  
    setRowsPerPage(parseInt(e.target.value));
    //setPage(2);
    getordersummary(event);
  };

  return (
    
      <Toolbar  className={classes.ftoolbar}>
       {/* <Box style={{ textAlign: "end"}}>
        <Button onClick={handlePrevClick} style={{ color: "black" }}>Prev</Button>
        <Button onClick={handlePrevClick} style={{ color: "black" }}>{pageNumber}</Button>
        <Button onClick={handlePrevClick} style={{ color: "black" }}>{pageNumber+1}</Button>
        <Button onClick={handlePrevClick} style={{ color: "black" }}>{pageNumber+2}</Button>
        
        <Button onClick={handleNextClick} style={{
          color: "black", opacity: isNextButtonDisabled ? '60%' : '',
          color: isNextButtonDisabled ? '' : '', color: "black"
        }} disabled={isNextButtonDisabled}>Next</Button></Box>*/}
        
      </Toolbar>
    
 
  );
}
