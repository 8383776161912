import { makeStyles } from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { red } from "@mui/material/colors";
export const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(3, 0),

  },
  sectionad: {
    margin: theme.spacing(3, 0),
    background:"rgba(14, 186, 112, 0.1)",

  },
  sectionad1: {
    margin: theme.spacing(3, 0),
    background:"white",

  },

  btn: {
    width: "200px",
    height: "30px",
    color: "#FFFFFF",
    borderRadius: "5px",
    textTransform: "none",
    marginBottom: theme.spacing(3),
    fontweight: 500,
    fontSize: "14px",
    background: "red",
    "&.active": {
      background: "black",
    },
  },

  typography:{

    fontWeight: 500,
    fontSize: 18,
    color: '#590F72',
    padding:"10px",
   alignContent:"center",
   justifyContent:"center"

  },
  error: {
    main: "#CAADD5",
    light: '#CAADD5',

    dark: '#CAADD5',
    contrastText: '#590F72',
  },
  select: {
    width: '250px',
    fontWeight: 600,
    fontSize: 16,
    color: '#590F72',
  },
  selectdrop: {
    width: '250px',
    fontWeight: 600,
    fontSize: 16,
    color: 'red',
    background:'white',
  },
  dropdownIcon: {
    color: 'red', // Set the color of the dropdown icon to white
  },
  root: {
    "& .MuiTableCell-head": {
        color: "white",
        backgroundColor: "#CAADD5"

    },
    "& .MuiFormControl-root":{
      width:"80%",
      margin:theme.spacing(1)
    }
},

palette:{
  primary:{
      main:"#CAADD5",
      light: '#CAADD5',
      backgroundColor: "#CAADD5",
      dark: '#CAADD5',
      contrastText: '#590F72',
  },


},
  responsiveImg: {
    width: " 100%",
    height: "auto",
  },
  cardImage: {
    maxHeight: "150px",
    overflowY: "hidden",
  },

  //page
  pageTitle: {
    color: "black",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    margin: theme.spacing(4, 0),
    textTransform: "capitalize",
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
     lineHeight: "30px",
  },
  pageTitleads: {
    color: "black",
    width:"236px",
    textAlign:"center",
    height:"60px",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    margin: theme.spacing(4, 0),
    textTransform: "capitalize",
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
     lineHeight: "20px",
     letterSpacing: "0.001em",
  },
  pageTitlesales: {
    color: "black",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(6),
    margin: theme.spacing(4, 0),
    textTransform: "capitalize",
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
     lineHeight: "30px",
  },
  
 
  pageSubTitleorder: {
    color: "black",

    marginTop: theme.spacing(2),
    margin: theme.spacing(1, 0),
    marginBottom: theme.spacing(6),

  },
  pageTitleorder: {
    color: "black",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    margin: theme.spacing(4, 0),
    textTransform: "capitalize",
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
     lineHeight: "30px",
  },
  pageTitle: {
    color: "black",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    margin: theme.spacing(4, 0),
    textTransform: "capitalize",
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
     lineHeight: "30px",
  },
  pageTitleaddcust: {
    color: "black",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    margin: theme.spacing(4, 0),
    textTransform: "capitalize",
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
     lineHeight: "30px",
   
  },
  pageSubTitle: {
    color: "black",

    marginTop: theme.spacing(10),
    margin: theme.spacing(1, 0),
    marginBottom: theme.spacing(1),

  },
  breadcrum: {
    color: "black",
    marginTop: theme.spacing(4),
    margin: theme.spacing(1, 0),
    marginBottom: theme.spacing(1),
    fontFamily: 'Poppins',
   fontStyle: "normal",
   fontWeight: "500",
   fontSize: "17px",
    lineHeight: "21px",
    letterSpacing: "0.001em",
    textdecorationline: "underline",
  },

  //dashboard
  cardLabel: {
    textTransform: "uppercase",
    color: blueGrey[500],
    textAlign: "center",
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    },
  },
  cardTitle: {
    textTransform: "capitalize",
    color: blueGrey[800],
    textAlign: "center",
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.8rem",
    },
  },
 
  ratioBtn: { fontSize: "1rem", fontWeight: "bold" },
  cardContent: {
    position: "relative",
  },
  //cardGraph
  displayCardGraph: {
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100% !important",
    height: "15% !important",
  },

  //user trafic graph
  displayUserGraph: {
    width: "100%",
    minHeight: "300px",
    height: "auto",
  },
  footer: {
    background: "#feffee",
    padding: theme.spacing(0, 0, 0, 33),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 1),
      marginTop: "45px",
    },
  },
  root1: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor:'lightgrey',
  },
  selectEmpty: {

  }
  ,
  box: {

  }
  ,
  textField: {
    background: "#FFFFFF",
    borderRadius: "5px",
    width: "440px",
    marginLeft: "340px",

    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0px",
      marginBottom: "16px",
    },
    '& .MuiInputBase-input': {
      height: '1px',
    },
  },

  select: {
    width: '250px',
    fontWeight: 600,
    fontSize: 16,
    color: '#590F72',
  },

  selectd: {
    width: '250px',
    fontWeight: 600,
    fontSize: 16,
    color: '#590F72',
  },


  noti:{
    color: "black",
marginBottom:"10px",
    textTransform: "capitalize",
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
     lineHeight: "30px",
  },

  searchbar: {
    display: "flex", flexGrow: 1, textAlign: "center",
    justifyContent: "center", borderRadius: "30px",
    background: "#FBFBFD", border: "0.25px solid #000000",
    boxsizing: "border-box",
    width: "100px",
    height: "30px",
    marginLeft: "250px",
    marginRight: "700px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "50px",
    height: "30px",
    marginLeft: "50px",
    marginRight: "70px",
    borderRadius: "5px",

  },
    rese: {
    background: "rgba(14, 186, 112, 0.1)",
    borderRadius: "20px", // Update this property to make the border rounded
    width: "451px",
    marginLeft: "340px",
    
    border: "0.25px solid #000000",boxSizing: "border-box",
    
    textDecoration: "none",
     outline: "none",
    border: "none", // Add this property to remove the border
    '& .MuiInputBase-input': {
      height: '0px',
    },
    [theme.breakpoints.down("sm")]: {
      background: "#FFFFFF",
      borderRadius: "20px", // Update this property to make the border rounded
      width: "200px",
      marginLeft: "0px",
      zIndex: 9999,
      textDecoration: "none",
      boxShadow: "none",
      border: "none", // Add this property to remove the border
    },
  },
  squareBox: {
    width: '100%', // Adjust the width as needed
    height: '100%', // Adjust the height as needed
    display: 'flex',
    background:"white",
    justifyContent: 'center',
    alignItems: 'center',
    border: '0.25px solid #000000',
    boxSizing: 'border-box',
    padding: theme.spacing(1),
    borderRadius: '4px', // Optional: Add border radius for rounded corners
  }

}));