import React, { useState, useEffect } from 'react';
import Loadingspinner from '../Ads/Loadingspinner';
import { ColorRing } from  'react-loader-spinner'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  NativeSelect,
  TextField,
  Typography,
  inputLabelClasses,
  MenuItem,
  Select,Button, Alert, AlertTitle
} from '@mui/material';
import { useStyles } from '../BodyStyles';
import close1 from '../../../Components/assets/close1.png';
import close2 from '../../../Components/assets/close2.png';
import img1 from '../../../Components/assets/img1.png';
import remove from '../../../Components/assets/remove.png';
import edit from '../../../Components/assets/Vectornew.png'
import img2 from '../../../Components/assets/img2.png';
import img3 from '../../../Components/assets/img3.png';
import img4 from '../../../Components/assets/img4.png';
import img5 from '../../../Components/assets/img5.png';
import img11 from '../../../Components/assets/img11.png';
import img12 from '../../../Components/assets/img12.png';
import img13 from '../../../Components/assets/img13.png';
import img14 from '../../../Components/assets/img14.png';
import theme from '../../../theme';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import axios from 'axios';
import api from '../../api.json';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(0),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    minHeight: 5,
    border: '1px solid gray',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: 'gray',
    },
  },
}));

const Ads = () => {
  const classes = useStyles();
  const [cate, setCate] = useState([]);
  const [subcate, setSubcate] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selcat, setSelcat] = useState(null);
  const [selsubcat, setSelsubcat] = useState(null);
  const [selindex, setSelindex] = useState(null);
  const [selindex1, setSelindex1] = useState(null);
  const [selindex2, setSelindex2] = useState(null);
  const [selindex3, setSelindex3] = useState(null);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [openDialog3, setOpenDialog3] = useState(false);
  const [fileup, setFileup] = useState(null);
  const [fileup1, setFileup1] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]); 
  const [selectedImages1, setSelectedImages1] = useState([]);
  const [selectedImages2, setSelectedImages2] = useState([]);
  const [selectedImages3, setSelectedImages3] = useState([]);
  const [fileUrl1, setFileUrl1] = useState('');
  const [imagesList1, setImagesList1] = useState([]);
  const [imagesList2, setImagesList2] = useState([]);
  const [imagesList3, setImagesList3] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedsubCategory, setSelectedsubCategory] = useState('');
  const [adType, setAdType] = useState('');
  const [imagearray1,setimagearray1] = useState([])
const [imagearray3,setimagearray3] = useState([])
const [isLoading1, setIsLoading1] = useState(false);
const [isLoading2, setIsLoading2] = useState(false);
const [isLoading3, setIsLoading3] = useState(false);

  useEffect(() => {
    getcategory();
    getsubcategory();
    getads();
  },[]);

  

  const [selectedcarousel,setselectedcarousel] = useState("")
  const [selectedstatic,setselectedstatic] = useState("")
  
  const handlecarouselChange = (event) => {
    setUpdateStatus(null)
    setselectedcarousel(event.target.value);
    //console.log("selectedcarousel",event.target.value)
    const newvar = parseInt(event.target.value);
    const filteredImages = carouselimages.filter(image => image.type === newvar);
   // console.log("filteredimages;",filteredImages)
    let allImagesEmpty = true; // Initialize to true

    for (var i = 1; i <= 5; i++) {
      const propname = 'image_' + i;
    
      if (filteredImages.some(image => image[propname] !== "")) {
        allImagesEmpty = false;
        break; 
      }
    }
    if (allImagesEmpty) {
      setiscarouselEdit(true);
    } else {
      setiscarouselEdit(false);
    }
  };
  const handlestaticChange = (event) => {
    setUpdateStatus("")
    
    setselectedstatic(event.target.value);
    const newvar = parseInt(event.target.value);
    const filteredImages = staticimages.filter(image => image.type === newvar);
   // console.log("filteredimages;",filteredImages)
      if (filteredImages[0].image_1==""){
      setisstaticEdit(true);
    } else {
      setisstaticEdit(false);
    }
  };
  const [subcategoryid,setsubCategoryId] = useState("");
  const handleSubCategoryChange = (event) => {
    setUpdateStatus("")
    const selectedSubCategoryName = event.target.value;
    setSelectedsubCategory(selectedSubCategoryName);
    console.log("selected subcategory:", selectedSubCategoryName);
    const selectedSubCategory = subcate.find((cat) => cat.sub_category_name === selectedSubCategoryName);
    if (selectedSubCategory) {
      setsubCategoryId(selectedSubCategory._id);
      console.log("selected subcategory ID:", selectedSubCategory._id);
      setSelectedCategory(selectedSubCategory.category_id);
      console.log('Selected category_id:', selectedSubCategory.category_id);
    } 
    const filteredImages = subcategoryimages.filter(image => image.sub_category_id === selectedSubCategory._id);
    console.log("filteredimages;",filteredImages)
    let allImagesEmpty = true; 
    for (var i = 1; i <= 5; i++) {
      const propname = 'image_' + i;
      if (filteredImages.some(image => image[propname] !== "")) {
      //  console.log("inside if")
        allImagesEmpty = false;
        break; 
      }
    }
    if (allImagesEmpty) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  };
  const successIcon = <CheckCircleIcon className="success-icon" />;
  const iconMapping = {
    success: successIcon,
  };
  const errorIcon = <ErrorIcon className="error-icon" />;
  const iconMapping3 = {
    error: errorIcon,
  };
  const [updateStatus, setUpdateStatus] = useState(null);
  const [updateStatus1, setUpdateStatus1] = useState(null);
  const [updateStatus2, setUpdateStatus2] = useState(null);
  const [isEdit,setIsEdit] = useState(false)
  const [iscarouselEdit,setiscarouselEdit] = useState(false)
  const [isstaticEdit,setisstaticEdit] = useState(false)
  
  const handlecarouselEdit =() => {
   // console.log("inside handlecarouseledit")
    setiscarouselEdit(true)
  }
  const handlestaticEdit =() => {
   // console.log("inside handlestaticedit")
    setisstaticEdit(true)
  }
  const handleEdit =() => {
    console.log("inside handleedit")
    setIsEdit(true)
  }
  const [carouselimages,setcarouselimages] = useState([])
  const [staticimages,setstaticimages] = useState([])
  const [subcategoryimages,setsubcategoryimages] = useState([])

  const getads = () => {
    axios
      .get(api.baseUrl + '/admin/syncAd')
      .then((response) => {
       // console.log("response:",response)
        setcarouselimages(response.data.data[0].imageList)
        setstaticimages(response.data.data[1].imageList)
        setsubcategoryimages(response.data.data[2].imageList)
      //  console.log("section 1:",response.data.data[0].imageList)
       // console.log("section 2:",response.data.data[1].imageList)
        console.log("section 3:",response.data.data[2].imageList)
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const deleteads1 = (imageval) => {
    //console.log("deleting image:",imageval)
    axios
      .post(api.baseUrl + '/admin/deleteAd',
      {
section : 1,
type : selectedcarousel,
image: imageval
},
      )
      .then((response) => {
        getads();
       
      })
      .catch((err) => {
        console.log('error', err);
      });
  };
  const deleteads2 = (imageval) => {
   // console.log("deleting image:",imageval)
    axios
      .post(api.baseUrl + '/admin/deleteAd',
      {
section : 2,
type : selectedstatic,
image: "image_1"
},
      )
      .then((response) => {
        getads();
       setsamplearr2([])
       setuploadfile([])
      })
      .catch((err) => {
        console.log('error', err);
      });
  };
  const deleteads3 = (imageval) => {
   // console.log("deleting image:",imageval)
    axios
      .post(api.baseUrl + '/admin/deleteAd',
      {
section : 3,
sub_category_id: subcategoryid,
category_id: selectedCategory,
image: imageval
},
      )
      .then((response) => {
        getads();
      })
      .catch((err) => {
        console.log('error', err);
      });
  };
  const getcategory = () => {
    axios
      .get(api.baseUrl + '/admin/getcategory')
      .then((response) => {
        setCate(response.data.data);
        //console.log('ghghhf67----->', response.data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const getsubcategory = () => {
    axios
      .get(api.baseUrl + '/admin/getsubcategory')
      .then((response) => {
        setSubcate(response.data.data);
       // console.log('ghghhf67----->', response.data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };
  //upload function

const [fileUrl, setFileUrl] = useState('');
 
const [imagesList, setImagesList] = useState([]);




const [errorMessage, setErrorMessage] = useState('');
const [errorMessage1, setErrorMessage1] = useState('');
const [errorMessage2, setErrorMessage2] = useState('');
const [errorMessage3, setErrorMessage3] = useState('');
const [samplearr1,setsamplearr1] = useState([])
const [samplearr2,setsamplearr2] = useState([])
const [samplearr3,setsamplearr3] = useState([])

const handleImageClick1= () => {
  if (selectedcarousel === '') {
    setUpdateStatus("failed")
    setTimeout(() => {
      setUpdateStatus(false);
    }, 5000);
    return; 
  }
    const imagearray1 = carouselimages.find((imageObj) => {
   if (imageObj.type === parseInt(selectedcarousel)){
      console.log("imageObj:",imageObj);   
      return imageObj;
   }
  else
  {
    return null;
  }
  })
  let imageCount = 0;
for (var i = 1; i <= 6; i++) {
  const propname = 'image_' + i;
  if (uploadarray1 && uploadarray1.hasOwnProperty(propname) && uploadarray1[propname] !== "") {
  //  console.log("inside if")
    imageCount++;
  }
}

if (imageCount === 6) {
 // console.log("inside if")
      setErrorMessage('Cannot upload more than 6 images');
      setFileup("failed");
      setTimeout(() => {
        setFileup("")
      }, 2000);
      return;
}
  // console.log("imagearray1:",imagearray1)
   if(imagearray1!== null && imagearray1!== undefined){
  //  console.log("if part")
        for(var i=1;i<=6;i++)
        {
        const propname = 'image_'+i;  
        if(!imagearray1.hasOwnProperty(propname) || imagearray1[propname]==="")
        {
          console.log("empty image slot:",propname)
          document.getElementById('fileInput1').click();
          return;
        }
      }
      
setErrorMessage('Cannot upload more than 6 images');
      setFileup("failed");
      setTimeout(() => {
        setFileup("");
        setErrorMessage('');
      }, 5000);
    }
        console.log("else part")
        document.getElementById('fileInput1').click();
        return;
    }

    const [uploadarray1,setuploadarray1] =useState([])
    const [uploadarray3,setuploadarray3] =useState([])

const [samplefile1,setsamplefile1]= useState([])
const [samplefile3,setsamplefile3]= useState([])
const handleFileChange1 = (event) => {
  console.log("inside filechange")
  var i;
  const file = event.target.files[0];
  const imageUrl = URL.createObjectURL(file);
  console.log("file,imageurl:",file,imageUrl)
  var imagearray1 = carouselimages.find((imageObj) => {
    if (imageObj.type === parseInt(selectedcarousel)){
       console.log("imageObj:",imageObj);   
       return imageObj;
    }
   else
   {
     return null;
   }
   })
    var newimagearray = {...imagearray1,...uploadarray1}
    console.log("imagearray:",imagearray1)
    console.log("newimagearray:",newimagearray)
    if(imagearray1!==null && imagearray1!==undefined){
    for(var i=1;i<=6;i++)
    {
    var propname = 'image_'+i;  
    if(!newimagearray.hasOwnProperty(propname) || newimagearray[propname]==="")
    {
      console.log("empty image slot:",propname)
      newimagearray[propname] = file;
      console.log("added image:",propname,":",newimagearray[propname])
      console.log("newimagearray:",newimagearray)
      setsamplearr1((samplearr1) => [...samplearr1, imageUrl]);
      setsamplefile1((prevSampleFile) => [
        ...prevSampleFile,
        { imageUrl, file },
      ]);
      console.log("samplearr1:",samplearr1)
      setuploadarray1(newimagearray);
      return;
    }
  }
}
else{
    imagearray1=
    {image_1 :file,
    section :1,
    type : selectedcarousel}
    newimagearray = imagearray1;
    console.log("addedimage:",imagearray1)
    setuploadarray1(newimagearray);
    setsamplearr1((samplearr1) => [...samplearr1, imageUrl]);
    setsamplefile1((prevSampleFile) => [
      ...prevSampleFile,
      { imageUrl, file },
    ]);
  } 
    setuploadarray1(newimagearray);
    setsamplearr1((samplearr1) => [...samplearr1, imageUrl]);
    setsamplefile1((prevSampleFile) => [
      ...prevSampleFile,
      { imageUrl, file },
    ]);
    setErrorMessage('');
};

const [fileup2,setfileup2] = useState(false)

const uploadImage1 = (newimagearr) => {
  console.log("newimage array:",newimagearr)
  if(newimagearr.length===0){
    console.log("no changes made")
    setfileup2(true)
    setTimeout(() => {
      setfileup2(false)
    }, 1000);
    return;
  }
  setIsLoading1(true);
  const imageCount = Object.keys(newimagearr)
  .filter(propname => propname.startsWith('image_') && newimagearr[propname] !== '')
  .length;
  const formData = new FormData();
  console.log("newimagearr:",newimagearr)
if (imageCount >= 3) {
  formData.append('section',1);
 formData.append('type', selectedcarousel);
 var i;
 for(i=1;i<=6;i++){
  const propname = 'image_'+i;
  if(newimagearr.hasOwnProperty(propname))
  {
    formData.append(propname,newimagearr[propname])
  }
} 
}
else {
  console.log('Error: There are less than 3 images in newimagearr');
  setIsLoading1(false)
  setfileup2(true)
  setTimeout(() => {
    setfileup2(false);
  }, 5000);
  return;
}
  axios.post(api.baseUrl + '/admin/postCarouselAd', formData)
    .then(response => {    
      console.log("response:",response)
      setIsLoading1(false);
      setsamplearr1([])
      setuploadarray1([])
      setiscarouselEdit(false)
        getads();
      })  
    .catch(error => {
      console.error(error);
      setIsLoading1(false);
    });
};
const handleImageClick2 = () => {
   console.log("selectedstatic:",selectedstatic)
  if (selectedstatic === '') {
    setUpdateStatus("failed")
    setTimeout(() => {
      setUpdateStatus(false);
    }, 5000);
    return; // Prevent further execution
  }
  console.log("uploadfile:",uploadfile)
  if(samplearr2.length!==0){
    setFileup1("failed")
    setTimeout(() => {
      setFileup1("")
    }, 1000);
    return;
  }
  const imagearray1 = staticimages.find((imageObj) => {
    if (imageObj.type === parseInt(selectedstatic)){
       console.log("imageObj:",imageObj);   
       return imageObj;
    }
   else
   {
     return null;
   }
   })
  
    console.log(" imagearray1:",imagearray1)
    if(uploadfile.length===0 && imagearray1== null || imagearray1== undefined|| imagearray1.length===0)  
        {
          console.log("inside if")
     document.getElementById('fileInput2').click();
     console.log("static images:",staticimages)
     return;
    }
    else  if(imagearray1.image_1==="")
    {
      console.log("inside if")
 document.getElementById('fileInput2').click();
 console.log("static images:",staticimages)
 return;
}
    else{
      setErrorMessage('Cannot upload more than 1 image');
        setFileup1("failed");
        setTimeout(() => {
          setFileup1("");
          setErrorMessage('');
        }, 5000);
    }
  };

const [uploadfile,setuploadfile]=useState([])
const handleFileChange2 = (event) => {
  var i;
   const file = event.target.files[0];
  const imageUrl = URL.createObjectURL(file);
  console.log("file,imageurl:",file,imageUrl)
  var imagearray1 = staticimages.find((imageObj) => {
    if (imageObj.type === parseInt(selectedstatic)){
       console.log("imageObj:",imageObj);   
       return imageObj;
    }
   else
   {
     return null;
   }
   })
   if(imagearray1!== null && imagearray1!== undefined)
   {
     if(imagearray1.image_1===""){
        imagearray1.image_1=file;
        console.log("check")
        setstaticimages(null)
        setsamplearr2((prevSampleArr) => [...prevSampleArr, imageUrl]);
        console.log("check1")
        setuploadfile(file)
        return;
}
else{
 setErrorMessage('Cannot upload more than 1 image');
   setFileup1("failed");
   setTimeout(() => {
     setFileup1("");
     setErrorMessage('');
   }, 5000);
}
}
else{
  imagearray1 = {
    type: selectedstatic,
    section : 2,
    image_1 : file
  }
  setsamplearr2((prevSampleArr) => [...prevSampleArr, imageUrl]);
  setuploadfile(file)
}
}
   
const [fileup3,setfileup3]= useState(false)
const uploadImage2 = (file) => {
  console.log("uploadfile:",uploadfile)
  if(uploadfile.length===0){
    console.log("no changes made")
    setfileup3(true)
    setTimeout(() => {
      setfileup3(false)
    }, 1000);
    return;
  }
  console.log("file:",file)
  if(!file || file.length===0){
    setfileup3(true);
  setTimeout(() => {
    setfileup3(false);
  }, 5000);
  return;
}
setIsLoading2(true);
  console.log("file:",file)
  const formData = new FormData();
  formData.append('section',2);
  formData.append('type', selectedstatic);
  formData.append('image_1',file)
  console.log("formData",)
  axios.post(api.baseUrl + '/admin/postStaticAd', formData)
    .then(response => {    
      console.log("response:",response)
      setIsLoading2(false)
      setsamplearr2([])
      setisstaticEdit(false)
        getads();
      })  
    .catch(error => {
      console.error(error);
      setIsLoading2(false)
    });
};


const handleImageClick3 = () => {
 if (selectedsubCategory === '') {
    setUpdateStatus("failed")
    setTimeout(() => {
      setUpdateStatus(false);
    }, 5000);
    return; 
  }
   const imagearray1 = subcategoryimages.find((imageObj) => {
   if (imageObj.sub_category_id=== subcategoryid){
      console.log("imageObj:",imageObj);   
      return imageObj;
   }
  else
  {
    return null;
  }
  })
  let imageCount = 0;
for (var i = 1; i <= 6; i++) {
  const propname = 'image_' + i;
  if (uploadarray3 && uploadarray3.hasOwnProperty(propname) && uploadarray3[propname] !== "") {
    console.log("inside if")
    imageCount++;
  }
}

if (imageCount === 6) {
  console.log("inside if")
      setErrorMessage('Cannot upload more than 6 images');
      setFileup("failed");
      setTimeout(() => {
        setFileup("")
      }, 2000);
      return;
}
   console.log("imagearray1:",imagearray1)
   if(imagearray1!== null && imagearray1!== undefined){
    console.log("if part")
        for(var i=1;i<=6;i++)
        {
        const propname = 'image_'+i;  
        if(!imagearray1.hasOwnProperty(propname) || imagearray1[propname]==="")
        {
          console.log("empty image slot:",propname)
          document.getElementById('fileInput3').click();
          return;
        }
      }
      setErrorMessage('Cannot upload more than 6 images');
      setFileup("failed");
      setTimeout(() => {
        setFileup("");
        setErrorMessage('');
      }, 5000);
    }
        console.log("else part")
        document.getElementById('fileInput3').click();
        return;
    }

    const handleFileChange3 = (event) => {
      console.log("inside filechange");
      const file = event.target.files[0];
      const imageUrl = URL.createObjectURL(file);
      console.log("file, imageUrl:", file, imageUrl);
    
      const imagearray1 = subcategoryimages.find((imageObj) => imageObj.sub_category_id === subcategoryid);
      let newimagearray = { ...imagearray1, ...uploadarray3 };
      console.log("imagearray:", imagearray1);
      console.log("newimagearray:", newimagearray);
    
      let updatedUploadArray = { ...newimagearray }; // Create a copy to update
    
      let updated = false;
      for (let i = 1; i <= 6; i++) {
        const propname = 'image_' + i;
        if (!updatedUploadArray.hasOwnProperty(propname) || updatedUploadArray[propname] === "") {
          console.log("empty image slot:", propname);
          updatedUploadArray[propname] = file;
          updated = true;
          console.log("in if added image:", propname, ":", updatedUploadArray[propname]);
    
          // Update state with the new image URL and file
          setsamplearr3((samplearr3) => [...samplearr3, imageUrl]);
          setsamplefile3((prevSampleFile) => [
            ...prevSampleFile,
            { imageUrl, file },
          ]);
          break; // Exit the loop after updating one slot
        }
      }
    
      if (updated) {
        // Update setuploadarray3 with the updated array
        setuploadarray3((prevUploadArray) => ({ ...updatedUploadArray }));
      }
    
      console.log(uploadarray3);
      setErrorMessage('');
    };
    
    


const uploadImage3 = (newimagearr) => {
  if(newimagearr.length===0){
  console.log("no changes made")
  setfileup2(true)
  setTimeout(() => {
    setfileup2(false)
  }, 1000);
  return;
}
  setIsLoading3(true)
  const imageCount = Object.keys(newimagearr)
  .filter(propname => propname.startsWith('image_') && newimagearr[propname] !== '')
  .length;
  console.log(imageCount,"Count");
  const formData = new FormData();
if (imageCount >= 3) {
 formData.append('section',3);
 formData.append('sub_category_id', subcategoryid);
 formData.append('category_id',selectedCategory);
 console.log("formData1",formData.get('type'))
 var i;
 console.log("newimagearr:",newimagearr)
 for(i=1;i<=6;i++){
  const propname = 'image_'+i;
  if(newimagearr.hasOwnProperty(propname))
  {
    formData.append(propname,newimagearr[propname])
  }
} 
}
else {
  console.log('Error: There are less than 3 images in newimagearr',);
  setIsLoading3(false)
  setfileup2(true)
  setTimeout(() => {
    setfileup2(false);
  }, 5000);
  return;
}
  axios.post(api.baseUrl + '/admin/postSubCategoryAd', formData)
    .then(response => {    
      setIsLoading3(false)
      console.log("response:",response)
      setsamplearr3(null)
      setIsEdit(false)
        getads();
      })  
    .catch(error => {
      setIsLoading3(false)
      console.error(error);
    });
};
const handleRemovesample1=(index,imageUrl) =>{
  console.log("imageurl:",imageUrl)
  const updatedSamplearr1 = [...samplearr1];
  updatedSamplearr1.splice(index, 1);
  setsamplearr1(updatedSamplearr1);
  const updatedfilearray1 = [ ...samplefile1 ];
 console.log("updatedfilearray:",updatedfilearray1)
 var deletedfile;
    for (const entry of updatedfilearray1) {
      if (entry.imageUrl === imageUrl) {
        deletedfile = entry.file
        console.log("deleted file:",entry.file)
      }
    } 
  const updatedUploadarr1 = {...uploadarray1};
  for(var i=1;i<=6;i++)
    {
    var propname = 'image_'+i;  
    if(updatedUploadarr1.hasOwnProperty(propname) && updatedUploadarr1[propname] instanceof File && updatedUploadarr1[propname] === deletedfile)
    {
      console.log("inside if:",updatedUploadarr1)
      console.log("matchfound:",updatedUploadarr1[propname],deletedfile)
      delete updatedUploadarr1[propname];
      console.log("updatedUploadarray:",updatedUploadarr1)
      setuploadarray1(updatedUploadarr1)
      break;
    }
  } 
}
const handleRemovesample2=(index) =>{
  const updatedSamplearr2 = [...samplearr2];
  updatedSamplearr2.splice(index, 1);
  setsamplearr2(updatedSamplearr2);
}
const handleRemovesample3=(index,imageUrl) =>{
  console.log("imageurl:",imageUrl)
  const updatedSamplearr3 = [...samplearr3];
  updatedSamplearr3.splice(index, 1);
  setsamplearr3(updatedSamplearr3);
  const updatedfilearray3 = [ ...samplefile3 ];
 console.log("updatedfilearray:",updatedfilearray3)
 var deletedfile;
    for (const entry of updatedfilearray3) {
      if (entry.imageUrl === imageUrl) {
        deletedfile = entry.file
        console.log("deleted file:",entry.file)
      }
    } 
  const updatedUploadarr3 = {...uploadarray3};
  for(var i=1;i<=6;i++)
    {
    var propname = 'image_'+i;  
    if(updatedUploadarr3.hasOwnProperty(propname) && updatedUploadarr3[propname] instanceof File && updatedUploadarr3[propname] === deletedfile)
    {
      console.log("inside if:",updatedUploadarr3)
      console.log("matchfound:",updatedUploadarr3[propname],deletedfile)
      delete updatedUploadarr3[propname];
      console.log("updatedUploadarray:",updatedUploadarr3)
      setuploadarray3(updatedUploadarr3)
      break;
    }
  } 
}

const handleRemoveImage1 = (index) => {
  console.log("remove",index)
  setSelindex(index)
  setOpenDialog1(true);
};
const handleRemovefImage1 = (index) => {
  console.log("handleRemovefImage",index)
  deleteads1(index);
  getads();
  setOpenDialog1(false);
};
const handleRemoveImage2 = (index) => {
  console.log("remove",index)
  setSelindex2(index)
  setOpenDialog2(true);
};
const handleRemovefImage2 = (index) => {
  console.log("handleRemovefImage",index)
  deleteads2(index);
  getads();
  setOpenDialog2(false);
};
const handleRemoveImage3 = (index) => {
  console.log("remove",index)
  setSelindex3(index)
  setOpenDialog3(true);
};
const handleRemovefImage3 = (index) => {
  console.log("handleRemovefImage",index)
  deleteads3(index);
  getads();
  setOpenDialog3(false);
};



  return (
    <>
      <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'}}>
   
      {updateStatus === "failed" && (
        <Alert iconMapping={iconMapping3} severity="error" onClose={() => setUpdateStatus(null)}
          style={{
            background: "red", color: "white", marginBottom: theme.spacing(-20), position: "fixed", zIndex: 9999,
             [theme.breakpoints.down("xs")]: {
              textAlign: 'center',
              width: "90%",
            },
          }}>
          <AlertTitle>Please select a category before selecting/uploading an image.</AlertTitle>
        </Alert>
      )}
    </div>
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'}}>
   
      {updateStatus1 === "failed" && (
        <Alert iconMapping={iconMapping3} severity="error" onClose={() => setUpdateStatus1(null)}
          style={{
            background: "red", color: "white", marginBottom: theme.spacing(-20), position: "fixed", zIndex: 9999,
             [theme.breakpoints.down("xs")]: {
              textAlign: 'center',
              width: "90%",
            },
          }}>
          <AlertTitle>Please select edit mode to make changes</AlertTitle>
        </Alert>
      )}
       {updateStatus2 === "failed" && (
        <Alert iconMapping={iconMapping3} severity="error" onClose={() => setUpdateStatus2(null)}
          style={{
            background: "red", color: "white", marginBottom: theme.spacing(-20), position: "fixed", zIndex: 9999,
             [theme.breakpoints.down("xs")]: {
              textAlign: 'center',
              width: "90%",
            },
          }}>
          <AlertTitle>Please select a Category before selecting/uploading an image.</AlertTitle>
        </Alert>
      )}
    </div>


    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'}}>
   
      {fileup === "failed" && (
        <Alert iconMapping={iconMapping3} severity="error" onClose={() => setFileup(null)}
          style={{
            background: "red", color: "white", marginBottom: theme.spacing(-20), position: "fixed", zIndex: 9999,
             [theme.breakpoints.down("xs")]: {
              textAlign: 'center',
              width: "90%",
            },
          }}>
          <AlertTitle>Cannot upload more than 6 images</AlertTitle>
        </Alert>
      )}
       {fileup1 === "failed" && (
        <Alert iconMapping={iconMapping3} severity="error" onClose={() => setFileup1(null)}
          style={{
            background: "red", color: "white", marginBottom: theme.spacing(-20), position: "fixed", zIndex: 9999,
             [theme.breakpoints.down("xs")]: {
              textAlign: 'center',
              width: "90%",
            },
          }}>
          <AlertTitle>Cannot upload more than 1 image</AlertTitle>
        </Alert>
      )}
      {fileup2 && (
        <Alert iconMapping={iconMapping3} severity="error" onClose={() => setfileup2(false)}
          style={{
            background: "red", color: "white", marginBottom: theme.spacing(-20), position: "fixed", zIndex: 9999,
             [theme.breakpoints.down("xs")]: {
              textAlign: 'center',
              width: "90%",
            },
          }}>
          <AlertTitle>Upload minimum 3 images</AlertTitle>
        </Alert>
      )}
       {fileup3 && (
        <Alert iconMapping={iconMapping3} severity="error" onClose={() => setfileup3(false)}
          style={{
            background: "red", color: "white", marginBottom: theme.spacing(-20), position: "fixed", zIndex: 9999,
             [theme.breakpoints.down("xs")]: {
              textAlign: 'center',
              width: "90%",
            },
          }}>
          <AlertTitle>Please upload an image</AlertTitle>
        </Alert>
      )}
    </div>


    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '40px',marginBottom: '20px' }}>
            <Typography variant="h6" className={classes.breadcrum}>
              <a className={classes.breadcrum} href="/dashboard">
                Go Back
              </a>
              </Typography>
              </div>
              <Box className={classes.section} style={{marginRight:"-40px"}}>
      <Grid container style={{background:"rgba(14, 186, 112, 0.2)",marginLeft:"-20px",padding:"20px"}}>
      <Grid item xs={12}>
          
              </Grid>
              <Grid item xs={12}>
        <Card className={classes.card} style={{ marginTop: '5px',marginBottom: '5px',padding:"10px" }}>
        <Grid item container alignItems="center" justify="space-between">
<Grid item xs={6} style={{ marginTop: '20px', marginBottom: '5px' }}>     
          <Typography variant="h5" className={classes.pageTitle}>
          LEFT TO RIGHT CAROUSEL - IMAGES
          </Typography>
    </Grid>
    
    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
    {isLoading1 && <ColorRing
  visible={true}
  height="40"
  width="40"
  ariaLabel="blocks-loading"
  wrapperStyle={{}}
  wrapperClass="blocks-wrapper"
  colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
/>}
  <NativeSelect
    id="select"
    label="Role"
    value={selectedcarousel}
    onChange={handlecarouselChange}
    fullWidth
    InputLabelProps={{
      sx: {
        color: "#590F72",
        margin: theme.spacing(2),
        border: "black",
        [`&.${inputLabelClasses.shrink}`]: {
          color: "#590F72",
          fontWeight: 600,
          fontSize: 16,
          border: "black",
        },
      },
    }}
    className={classes.select}
    input={<BootstrapInput />}
    sx={{ width: '350px', border: "0.5px #000000" }}
  >
    <option value="">----- Categories-----</option>
    <option value="1">Home</option>
    <option value="2">Sub-categories</option>
    <option value="5">Products</option>
    {/* {cate.map((cat) => (
      <option key={cat._id} value={cat.name}>{cat.name}</option>
    ))} */}
  </NativeSelect>
 {selectedcarousel && (iscarouselEdit ? ( <Button onClick={()=>uploadImage1(uploadarray1)}style={{ width: "150px", height: "45px", textAlign:'center',marginLeft:'10px',fontFamily: 'Poppins', color: " #e4f8ef", borderRadius: "5px", background: "#0EBA70", textTransform: "none", borderRadius: "3", fontweight: 500, fontSize: "14px" }}>
   SAVE </Button>):( <Button onClick={handlecarouselEdit} style={{ width: "150px", height: "45px", textAlign:'center',marginLeft:'10px',fontFamily: 'Poppins', color: " #e4f8ef", borderRadius: "5px", background: "#84240B", textTransform: "none", borderRadius: "3", fontweight: 500, fontSize: "14px" }}>
   EDIT </Button>))}
  
   {/* {!isEdit &&   (<img src={edit} alt="Default" onClick={handleEdit}  style={{ width: "30px",marginLeft:'10px',cursor:"pointer"}}/>)} */}

</Grid>
</Grid>
        <div >

        
        <div style={{ display: "flex" ,marginBottom:"5px"}}>
        
        <Box
                 display="flex"
                 justifyContent="center"
                 alignItems="center"
                 height={200}
                 width={200}
                 bgcolor="#0EBA70"
                 borderRadius="20px"
                 marginRight="20px"
              >
              
    <img src={img1} alt="Default" onClick={iscarouselEdit && selectedcarousel ? handleImageClick1: !iscarouselEdit && selectedcarousel ? () => {
  setUpdateStatus1("failed");
  setTimeout(() => {
    setUpdateStatus1(null);
  }, 2000); 
}:() => {setUpdateStatus("failed");
    setTimeout(() => {
      setUpdateStatus(null);
    }, 2000); 
  }}  style={{cursor:"pointer"}}/>
     </Box>
     <div style={{ overflowX: 'scroll', whiteSpace: 'nowrap', display: 'flex', overflowY: 'hidden' }}>
      {carouselimages &&
 carouselimages.map((imageObj, _id) => {
    if (imageObj.type === parseInt(selectedcarousel)) {
      return (
        <div key={_id} style={{ display: 'flex', flexDirection: 'row', marginRight: '10px' }}>
          {[imageObj.image_1, imageObj.image_2, imageObj.image_3,imageObj.image_4,imageObj.image_5,imageObj.image_6].map((image, index) => (
            <div key={index} style={{ marginRight: '10px' }}>
              {image && (
                
              <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={200}
              width={200}
              bgcolor="white"
              borderRadius="20px"
              position="relative"
              >
                
                <img
                  src={api.baseUrl + '/' + image}
                  alt={`Selected ${index + 1}`}
                  style={{ width: '200px', height: '200px', borderRadius: '12px' }}
                />
                {iscarouselEdit && (
                  <button
                    onClick={() => handleRemoveImage1(`image_${index+1}`)}
                    style={{
                      position: 'absolute',
                      top: '0px',
                      right: '0px',
                      backgroundColor: 'red',
                      border: 'none',
                      cursor: 'pointer',
                      color: 'white',
                      borderRadius: '40px',
                    }}
                  >
                    <ClearIcon/>
                  </button>
                )}
               
              </Box>
              )}
            </div>
          ))}
        </div>
      );
    }
    return null; // Skip rendering if not matching type
  })}
  {samplearr1 && samplearr1.map((imageUrl, index) => (
     <div key={index} style={{ marginRight: '10px' }}>
      {imageUrl && (
   <Box
   display="flex"
                justifyContent="center"
                alignItems="center"
                height={200}
                width={200}
                bgcolor="white"
                borderRadius="20px"
                position="relative"
               >
   
        <img key={index}
         src={imageUrl}
          alt={`Sample ${index}`}
          style={{ width: "200px", height: "200px", borderRadius: "12px" }}
         />
                 
              {iscarouselEdit && (
              <button
                onClick={()=>handleRemovesample1(index,imageUrl)}
                style={{
                  position: 'absolute',
                  top: '0px',
                  right: "0px",
                  backgroundColor: 'red',
                  border: 'none',
                  cursor: 'pointer',
                  color: 'white',
                  borderRadius:"40px"
                }}
              >
               < ClearIcon/>
              </button>
              )}
           </Box>
              )}
            </div>
          ))}
</div>
 </div>
</div>
<input type="file" id="fileInput1" style={{ display: 'none' }} onChange={(e)=>handleFileChange1(e)} />
</Card>
  </Grid>
  <Grid item xs={12}> 

<Card className={classes.card} style={{ marginTop: '5px',marginBottom: '5px',padding:"10px" }}>

<Grid item container alignItems="center" justify="space-between">
<Grid item xs={6} style={{ marginTop: '20px', marginBottom: '5px' }}>
  <Typography variant="h5" className={classes.pageTitle}>
 STATIC IMAGES
  </Typography>
</Grid>
<Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
{isLoading2 && <ColorRing
  visible={true}
  height="40"
  width="40"
  ariaLabel="blocks-loading"
  wrapperStyle={{}}
  wrapperClass="blocks-wrapper"
  colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
/>}
  <NativeSelect
    id="select"
    label="Role"
    value={selectedstatic}
    onChange={handlestaticChange}
    fullWidth
    InputLabelProps={{
      sx: {
        color: "#590F72",
        margin: theme.spacing(2),
        border: "grey",
        [`&.${inputLabelClasses.shrink}`]: {
          color: "#590F72",
          fontWeight: 600,
          fontSize: 16,
          border: "grey",
        },
      },
    }}
    className={classes.select}
    input={<BootstrapInput />}
    sx={{ width: '350px', border: "0.5px  #000000" }}
  >
    <option value="">----- Home -----</option>
    <option value="1">Home</option>
    <option value="3">Categories</option>
    <option value="5">Products</option>
    <option value="6">All</option>
  </NativeSelect>
  {selectedstatic && (isstaticEdit ? ( <Button onClick={()=>uploadImage2(uploadfile)}style={{ width: "150px", height: "45px", textAlign:'center',marginLeft:'10px',fontFamily: 'Poppins', color: " #e4f8ef", borderRadius: "5px", background: "#0EBA70", textTransform: "none", borderRadius: "3", fontweight: 500, fontSize: "14px" }}>
   SAVE </Button>):( <Button  onClick={handlestaticEdit} style={{ width: "150px", height: "45px", textAlign:'center',marginLeft:'10px',fontFamily: 'Poppins', color: " #e4f8ef", borderRadius: "5px", background: "#84240B", textTransform: "none", borderRadius: "3", fontweight: 500, fontSize: "14px" }}>
   EDIT </Button>))}
</Grid>
</Grid>

    
        <div >          
        <div style={{ display: "flex" ,marginBottom:"5px"}}>
        <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={200}
                width={200}
                bgcolor="#0EBA70"
                borderRadius="20px"
                marginRight="20px"
              >
           
        <img src={img1} alt="Default"  onClick={isstaticEdit && selectedstatic ? handleImageClick2: !isstaticEdit && selectedstatic ? () => {
  setUpdateStatus1("failed");
  setTimeout(() => {
    setUpdateStatus1(null);
  }, 2000); 
}:() => {setUpdateStatus("failed");
    setTimeout(() => {
      setUpdateStatus(null);
    }, 2000); 
  }}  style={{cursor:"pointer"}}/>
        </Box>
        
        {staticimages &&
  staticimages.map((imageObj1, _id) => {
    if (imageObj1.type === parseInt(selectedstatic)) {
      console.log("imageobj1:",imageObj1.image_1)
      return (
        <div key={_id} style={{ display: 'flex', flexDirection: 'row', marginRight: '10px' }}>  
          {imageObj1.image_1!=="" && imageObj1.image_1!==null && [imageObj1.image_1].map((image, index) => (
            <div key={index} style={{ marginRight: '10px' }}>
              {image!=="" && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={200}
                width={200}
                bgcolor="white"
                borderRadius="20px"
                position="relative"
              >
              <img
                  src={api.baseUrl + '/' + image}
                  alt={`Selected ${index + 1}`}
                  style={{ width: "200px", height: "200px", borderRadius: "12px" }}
                />
                {isstaticEdit && (
                  <button
                    onClick={() => handleRemoveImage2(index)}
                    style={{
                      position: 'absolute',
                      top: '0px',
                      right: "0px",
                      backgroundColor: 'red',
                      border: 'none',
                      cursor: 'pointer',
                      color: 'white',
                      borderRadius: "40px"
                    }}
                  >
                    <ClearIcon />
                  </button>
                )}
              </Box>
              )}
            </div>
          ))}
        </div>
      );
    }
    return null; // Skip rendering if not matching type
  })}
 {samplearr2 && samplearr2.map((imageUrl, index) => (
     <div key={index} style={{ marginRight: '10px' }}>
      {imageUrl && (
   <Box
                 display="flex"
                 justifyContent="center"
                 alignItems="center"
                 height={200}
                 width={200}
                 bgcolor="white"
                 borderRadius="20px"
                 position="relative"
               >
   
        <img key={index}
         src={imageUrl}
          alt={`Sample ${index}`}
          style={{ width: "200px", height: "200px", borderRadius: "12px" }}
         />
              {isstaticEdit && (
              <button
                onClick={()=>handleRemovesample2(index)}
                style={{
                  position: 'absolute',
                  top: '0px',
                  right: "0px",
                  backgroundColor: 'red',
                  border: 'none',
                  cursor: 'pointer',
                  color: 'white',
                  borderRadius:"40px"
                }}
              >
               < ClearIcon/>
              </button>
              )}
           </Box>
              )}
            </div>
          ))}
</div>
 
</div>
<input type="file" id="fileInput2" style={{ display: 'none' }} onChange={(e)=>handleFileChange2(e)} />
</Card>

</Grid>  
<Grid item xs={12}>    
<Card className={classes.card} style={{ marginTop: '5px',marginBottom: '5px',padding:"10px" }}>
<Grid item container alignItems="center" justify="space-between">
<Grid item xs={6} style={{ marginTop: '20px', marginBottom: '5px' }}>
  <Typography variant="h5" className={classes.pageTitle}>
  SUB CATEGORY FRAME IMAGES
  </Typography>
</Grid>
<Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
{isLoading3 && <ColorRing
  visible={true}
  height="40"
  width="40"
  ariaLabel="blocks-loading"
  wrapperStyle={{}}
  wrapperClass="blocks-wrapper"
  colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
/>}
<NativeSelect
    id="select"
    label="Role"
    fullWidth
    value={selectedsubCategory}
    onChange={handleSubCategoryChange}
    InputLabelProps={{
      sx: {
        color: "#590F72",
        margin: theme.spacing(2),
        border: "black",
        [`&.${inputLabelClasses.shrink}`]: {
          color: "#590F72",
          fontWeight: 600,
          fontSize: 16,
          border: "black",
        },
      },
    }}
    className={classes.select}
    input={<BootstrapInput />}
    sx={{ width: '350px' , border: "0.5px #000000"}} 
  >
    <option value={selsubcat}>----- Choose Sub Category -----</option>
                                      {subcate.map((cat) => (
                                          <option key={cat._id} value={cat.sub_category_name}>{cat.sub_category_name}</option>
                                      ))}
  </NativeSelect>
  {selectedsubCategory&& (isEdit ? ( <Button  onClick={()=>uploadImage3(uploadarray3)}style={{ width: "150px", height: "45px", textAlign:'center',marginLeft:'10px',fontFamily: 'Poppins', color: " #e4f8ef", borderRadius: "5px", background: "#0EBA70", textTransform: "none", borderRadius: "3", fontweight: 500, fontSize: "14px" }}>
   SAVE </Button>):( <Button  onClick={handleEdit}style={{ width: "150px", height: "45px", textAlign:'center',marginLeft:'10px',fontFamily: 'Poppins', color: " #e4f8ef", borderRadius: "5px", background: "#84240B", textTransform: "none", borderRadius: "3", fontweight: 500, fontSize: "14px" }}>
   EDIT </Button>))}
</Grid>
</Grid>

        <div >
        <div style={{ display: "flex" ,marginBottom:"5px"}}>
        <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={200}
                width={200}
                bgcolor="#0EBA70"
                borderRadius="20px"
                marginRight="20px"
              >
        <img src={img1} alt="Default" onClick={isEdit && selectedsubCategory ? handleImageClick3: !isEdit && selectedsubCategory ? () => {
  setUpdateStatus1("failed");
  setTimeout(() => {
    setUpdateStatus1(null);
  }, 2000); 
}:() => {setUpdateStatus("failed");
    setTimeout(() => {
      setUpdateStatus(null);
    }, 2000); 
  }}  style={{cursor:"pointer"}}/></Box>
        <div style={{ overflowX: 'scroll', whiteSpace: 'nowrap', display: 'flex', overflowY: 'hidden' }}>     
  {subcategoryimages &&
  subcategoryimages.map((imageObj, _id) => {
    if (imageObj.sub_category_id === subcategoryid){
      console.log("subcategoryimages:",imageObj)
      return (
        <div key={_id} style={{ display: 'flex', flexDirection: 'row', marginRight: '10px' }}>
          {[imageObj.image_1, imageObj.image_2, imageObj.image_3,imageObj.image_4,imageObj.image_5,imageObj.image_6].map((image, index) => (
            <div key={index} style={{ marginRight: '10px' }}>
              {image && (
      <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={200}
      width={200}
      bgcolor="white"
      borderRadius="20px"
      position="relative"           >
      <img
        src={api.baseUrl+'/'+image}
        alt={`Selected ${imageObj._id}`}
        style={{ width: "200px", height: "200px",borderRadius:"12px" }}
      />
      {isEdit && (
      <button
        onClick={() => handleRemoveImage3(`image_${index+1}`)}
        style={{
          position: 'absolute',
          top: '0px',
          right: "0px",
          backgroundColor: 'red',
          border: 'none',
          cursor: 'pointer',
          color: 'white',
          borderRadius:"40px"
        }}
      >
        < ClearIcon/>
              </button>
            )}
            </Box>
              )}
          </div>
        ))}
      </div>
    );
  }
  return null; // Skip rendering if not matching type
})}
 {samplearr3 && samplearr3.map((imageUrl, index) => (
     <div key={index} style={{ marginRight: '10px' }}>
      {imageUrl && (
   <Box
   display="flex"
   justifyContent="center"
   alignItems="center"
   height={200}
   width={200}
   bgcolor="white"
   borderRadius="20px"
   position="relative"
               >
   
        <img key={index}
         src={imageUrl}
          alt={`Sample ${index}`}
          style={{ width: "200px", height: "200px", borderRadius: "12px" }}
         />
                 
              {isEdit && (
              <button
                onClick={()=> handleRemovesample3(index,imageUrl)}
                style={{
                  position: 'absolute',
                  top: '0px',
                  right: "0px",
                  backgroundColor: 'red',
                  border: 'none',
                  cursor: 'pointer',
                  color: 'white',
                  borderRadius:"40px"
                }}
              >
               < ClearIcon/>
              </button>
              )}
           </Box>
              )}
            </div>
          ))}
</div>
 </div>
</div>
<input type="file" id="fileInput3" style={{ display: 'none' }} onChange={(e)=>handleFileChange3(e)} />
</Card>

</Grid> 
            <Dialog open={openDialog1} onClose={() => setOpenDialog1(false)}>
              <DialogTitle style={{ color: "#000000" }}>Delete Image?</DialogTitle>
              <DialogContent>
                <DialogContentText style={{ color: "#000000" }}>
                  Are you sure you want to delete this image?
                </DialogContentText>
              </DialogContent>
              
              <DialogActions>
              <Button onClick={() => setOpenDialog1(false)} style={{ background:"#0EBA70",color: "black", fontSize: "16px", fontWeight: 400 }}>Cancel</Button>
                <Button style={{ background:"#EB4335",color: "black", fontWeight: 400, fontSize: "16px" }}
                onClick={() => {
                  handleRemovefImage1(selindex);            
                }}
                >Delete</Button>
              </DialogActions>
            </Dialog>

            <Dialog open={openDialog2} onClose={() => setOpenDialog2(false)}>
              <DialogTitle style={{ color: "#000000" }}>Delete Image?</DialogTitle>
              <DialogContent>
                <DialogContentText style={{ color: "#000000" }}>
                  Are you sure you want to delete this image?
                </DialogContentText>
              </DialogContent>
              
              <DialogActions>
              <Button onClick={() => setOpenDialog2(false)} style={{ background:"#0EBA70",color: "black", fontSize: "16px", fontWeight: 400 }}>Cancel</Button>
                <Button style={{ background:"#EB4335",color: "black", fontWeight: 400, fontSize: "16px" }}
                onClick={() => {
                  handleRemovefImage2(selindex2);
                
            
                }}
                >Delete</Button>
              </DialogActions>
            </Dialog>

            <Dialog open={openDialog3} onClose={() => setOpenDialog3(false)}>
              <DialogTitle style={{ color: "#000000" }}>Delete Image?</DialogTitle>
              <DialogContent>
                <DialogContentText style={{ color: "#000000" }}>
                  Are you sure you want to delete this image?
                </DialogContentText>
              </DialogContent>
              
              <DialogActions>
                <Button onClick={() => setOpenDialog3(false)} style={{ background:"#0EBA70",color: "black", fontSize: "16px", fontWeight: 400 }}>Cancel</Button>
                <Button style={{ background:"#EB4335",color: "black", fontWeight: 400, fontSize: "16px" }} 
                onClick={() => {
                  handleRemovefImage3(selindex3);            
                }}
                >Delete</Button>
              </DialogActions>
            </Dialog>
  </Grid>
    </Box>
    </>
  );
};

export default Ads;
