import { makeStyles } from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { blue,red } from "@material-ui/core/colors";
import { display, fontSize, margin } from "@mui/system";

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    background:"#0EBA70"
  },
  ftoolbar: {
    background:"#0EBA70",
    position: 'absolute',
   
    left: 0,
    right: 0,
    margin: '0 auto',
  
  },

   ulAvater: {
    backgroundColor: blue["A200"],
    color: "white",
    display:"none"
  },
  navAvatar: {
    width: "25px",
    height: "25px",
  },
  searchbar: {
    
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    marginLeft:"230px",
    background:"blue",
    
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      display:"flex",
      alignItems: 'start',
      width:"20px",
     

    }
  },
  logo: {
    color: "white",
    textAlign: "center",
  },
  subm: {
    color: "white",
    textAlign: "center",
    
  },
  navlist: {
    minWidth: "250px",
    maxWidth: "300px",
  },
  ulAvater: {
    backgroundColor: blue["A200"],
    color: "white",
    display:"none"
  },
  navAvatar: {
    width: "35px",
    height: "35px",
  },

  //wrapper of main contianer
  wrapper: {
    minHeight: "100vh",
    height: "auto",
    background: "white",
    marginTop: "0px",
    padding: theme.spacing(2, 2, 0, 34),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 2),
      marginTop: "0px",
    },
  },

  //Side nav
  drawerPaper: {
    width: "250px",
    marginTop: "1px",
    background:"#0EBA70",
    zIndex:"9999",


    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      color:"#0EBA70"
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
    color: "#0EBA70",
   
    " & div": {
      color: "white",
    },
  },
  navlinks: {
    color: "#0EBA70",
    "& :hover , &:hover div": {
      color: blue["A200"],
    },
    " & div": {
      color: blueGrey["A400"],
    },
  },
  activeNavlinks: {
    color: blue["A700"],
    "& div": {
      color: blue["A700"],
    },
  },
  navButton: {
    width: " 100%",
    textTransform: "capitalize",
  },
}));