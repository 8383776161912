import React, { useState,useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
import { DialogActions } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import image from "../Components/assets/profileicon.png";
import notification1 from "../Components/assets/notification1.png";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import { useStyles } from "./HeaderStyles";
import { useHistory } from "react-router-dom";

export default function Profile() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlenotiClick = (event) => {
    
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setOpenDialog(false)
    history.push("/");
    window.location.reload()
  };
  const [openDialog, setOpenDialog] = useState(false);
  const handleSaveClick = (e) => {
    //setSelectedContact(contact);
    e.preventDefault(); // Add this line to prevent default form submission behavior
    setOpenDialog(true);
  };
  const handleLogoutConfirmation = () => {
    setOpenDialog(true);
  };
  const dropDownData = [
    { label: "settings", icon: <SettingsIcon /> },
    { label: "Logout", icon: <ExitToAppIcon /> ,action: handleLogoutConfirmation },
  ];


  // useEffect(() => {
  //   const storedCount = parseInt(localStorage.getItem("totalcount")) || 0;
  //   setCount(storedCount);
  // }, []);

  const [count, setCount] = useState(parseInt(localStorage.getItem("totalcount")) || 0);

  // Update the count whenever a new notification arrives
  // You can call this function whenever a new notification is received
  const updateNotificationCount = () => {
    const newCount = count + 1;
    setCount(newCount);
    localStorage.setItem("totalcount", newCount);
  };
  return (
    <Box>
       <Badge badgeContent={count} color="error" style={{ marginRight:"20px" }}>
        <NotificationsIcon style={{ fontSize: "39px" }} />
      </Badge>
        <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        startIcon={
          <Avatar src={image} className={classes.navAvatar}></Avatar>
        }></Button>

      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {dropDownData.map((item, i) => (
          <MenuItem key={i} component={ListItem}  onClick={() => {
            handleClose();
            if (item.action) item.action();
          }} >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle style={{ color: "#000000" }}>
          Are you sure you want to logout?
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            style={{ color: "#590F72", fontSize: "16px", fontWeight: 400,background: "#0EBA70"}}
          >
            Cancel
          </Button>
          <Button
            style={{ color: "#590F72", fontWeight: 400, fontSize: "16px",background: "#EB4335" }}
            onClick={handleLogout}
          >
          Log Out
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
