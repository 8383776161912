import React from 'react'
import { createTheme } from '@mui/material'

const theme = createTheme({
    palette:{
        primary:{
            main:"#CAADD5",
            light: '#CAADD5',
     
            dark: '#CAADD5',
            contrastText: '#590F72',
        },
      
        
    },
    typography:{
        h1:{
            fontSize:"20px"
        }
    },
    text:{
        h1:{
            fontSize:"20px"
        }
    },
   
    
})
export default theme;