import React, { useState, useEffect } from 'react';
import data from "./mockdata_orderreports.json";
import { Box, Button, Paper,Grid, Typography } from "@material-ui/core";
import { useStyles } from "../BodyStyles";
import { PageHeader } from "../../common/CommonComponent";
import theme from "../../../theme";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import SearchIcon from "@material-ui/icons/Search";
import honey from "../../../Components/assets/honey.png";
import excelimg from "../../../Components/assets/excelimg.png";
import DateRange from "./Daterange";
import Dat from "./Dat";
import { IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import axios from "axios";
import api from "../../api.json"

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';



const Orderreports = () => {
    const [orders, setOrders] = useState(data);
    const classes = useStyles();
    const [search, setSearch] = useState('');
    const [count, setCount] = useState(data.length);   
    const [showDateRange, setShowDateRange] = useState(true); 
    const [activeButton, setActiveButton] = useState("a");
    let background=''
    let colo=''
    const startDate = new Date('2023-04-10');
  const endDate = new Date('2023-04-15');
  const [selectedDateRange, setSelectedDateRange] = useState({ start: null, end: null });
  const [showOverlay, setShowOverlay] = useState(false);

  const handleDateRangeChange =() => {
   
    
      
  };

  const handleClick = () => {
    setShowOverlay(true);
  };

  const Overlay = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateRangePicker']}>
        <DateRangePicker localeText={{ start: 'Check-in', end: 'Check-out' }} />
      </DemoContainer>
    </LocalizationProvider>
    );
  };

    const tabled = (val) => {      
      setActiveButton(val);  
        console.log("searchedVal", val)
        console.log("orders", orders)
        if (val=='d'){
            console.log("completed")
        setSearch('completed')
        
        }
        if (val=='p'){
            console.log("pending")
            setSearch('pending')
           
            }
            if (val=='c'){
                console.log("cancelled")
                setSearch('cancelled')
                }
                if (val=='a'){
                    console.log("all")
                    setSearch('')
                    }

             };
    const exportToExcel = () => {
        console.log("on export")
       
        // Get the table data       
        const filteredOrders = orders.filter((order) => order.status.toLowerCase().includes(search.toLowerCase()));

    
        // Define the header row
        const header = ["#", "Order ID", "Product Name", "Buyer", "Seller", "Date","Price","Platform Fee", "Status"];
    
    
        // Create the data array for the spreadsheet
        const data1 = [header, ...filteredOrders.map(({ sno,orderID,productName,buyer,seller,date,price,platformFee,status }) =>
          [sno,orderID,productName,buyer,seller,date, `Rs. ${price}`,`Rs. ${platformFee}`,status])];
    
        // Create a new worksheet
        const ws = XLSX.utils.aoa_to_sheet(data1);
    
        // Create a new workbook
        const wb = XLSX.utils.book_new();
    
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Table Data');
    
        // Create a binary string representation of the workbook
        const bin = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    
        // Create a Blob from the binary string
        const blob = new Blob([bin], { type: 'application/octet-stream' });
    
        // Save the file
        saveAs(blob, 'ordersummary.xlsx');
      };
      const [customers, setCustomers] = useState([]);
      const [cate, setCate] = useState([])
      const [user, setUser] = useState([]);
    
      const handleAdminorders = (e, searchedVal) => {
        console.log("getproducts", e)
        setActiveButton(e)
        axios
          .get(api.baseUrl + "/order/getadminorders", {
            params: {
              from: '',
              to: '',
              search: searchedVal,
              page: 1,
              limit: 10,
    
            }
    
          })
          .then((response) => {
            setCustomers(response.data);
    
            console.log(response);
    
            const responseData = response.data.data;
            setUser(responseData)
            setCate(responseData.order.cart)
    
          })
          .catch((err) => {
            console.log("error", err);
          });
    
      }
      useEffect(() => {
    
        handleAdminorders("");
      }, []);
  return (
     
    <Box className={classes.section}>



<Grid container>
      <Grid item xs={12} sm={12}>
      <Typography variant='h6' className={classes.breadcrum}>
      <a  className={classes.breadcrum} href="/dashboard" > Go Back </a> </Typography>


      <div style={{ display: "flex", justifyContent: "end", alignItems: "center", marginTop:"-25px" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
      <TextField name="search"
        placeholder="  Search"
        className={classes.rese}
        id="standard-bare"
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
      />
    </div> < div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
                <Button onClick={exportToExcel} style={{ width: "200px",height: "35px", fontFamily: 'Poppins', color: " #FFFFFF", borderRadius: "5px", background: "#0EBA70", textTransform: "none",  marginLeft: theme.spacing(3), borderRadius: "3", fontweight: 500, fontSize: "14px" }}>
                <td>
          <Box
            component="img"
            style={{alignItems: "left",
              marginLeft: 2,
              marginTop: 5,
              marginLeft: theme.spacing(1),
              maxHeight: { xs: 2, md: 2 },
              maxWidth: { xs: 350, md: 250 },
            }}
            src={excelimg}
          />
        </td>
        <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </td>
        Export to Excel</Button>               
      </div> 
  <Button onClick={() => Overlay()} style={{ width: "200px", fontFamily: 'Poppins',height: "35px", color: " #FFFFFF", borderRadius: "5px", background: "#0EBA70", textTransform: "none",  marginLeft: theme.spacing(3), borderRadius: "3", fontweight: 500, fontSize: "14px" }}>Start Date -  End Date</Button>
              
     
      </div> 



      <Typography variant='h5' className={classes.pageTitleorder}>
         ORDERS SUMMARY
        </Typography>
        <Typography variant='body2' className={classes.pageSubTitleorder}>
          {count} Orders Found
        </Typography>
                
      </Grid>
    </Grid>
       <Box>          
       </Box>
       
         
        
        
      
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    
      
    <Button  onClick={(e) => tabled('a')} style={{ width: "200px",height: "30px",  borderRadius: "5px", color: " #FFFFFF", background: activeButton === 'a' ? '#0EBA70' : '#84240B', textTransform: "none", marginBottom: theme.spacing(3), marginLeft: theme.spacing(1), fontweight: 500, fontSize: "14px", fontFamily: 'Poppins' }}>All Orders</Button>
    <Button  className='btn' onClick={(e) => tabled('d')} style={{ width: "200px",height: "30px", color: " #FFFFFF", borderRadius: "5px",  background: activeButton === 'd' ? '#0EBA70' : '#84240B',
   
    textTransform: "none", marginBottom: theme.spacing(3), marginLeft: theme.spacing(1),  fontweight: 500, fontSize: "14px", fontFamily: 'Poppins' }}>Completed</Button>
    <Button  className='btn'onClick={(e) => tabled('p')} style={{ width: "200px",height: "30px", color: " #FFFFFF", borderRadius: "5px", background: activeButton === 'p' ? '#0EBA70' : '#84240B', textTransform: "none", marginBottom: theme.spacing(3), marginLeft: theme.spacing(1),  fontweight: 500, fontSize: "14px" , fontFamily: 'Poppins'}}>Pending</Button>
    <Button  className='btn' onClick={(e) => tabled('c')} style={{ width: "200px",height: "30px", color: " #FFFFFF", borderRadius: "5px", background: activeButton === 'c' ? '#0EBA70' : '#84240B', textTransform: "none", marginBottom: theme.spacing(3), marginLeft: theme.spacing(1),  fontweight: 500, fontSize: "14px", fontFamily: 'Poppins' }}>Cancelled</Button>

    <TableContainer >
    <Table stickyHeader aria-label="sticky table">
      <TableHead >
        <TableRow sx={{
          "& th": {
         
            fontStyle: "normal",
            fontFamily: 'Poppins',
            fontWeight: "400",
            textTransform: "uppercase",
            backgroundColor: "white",
            color: "black",
            fontSize: "18px",

          }
        }}>

          <TableCell align="center" >
            #</TableCell>
          <TableCell align="center" >
             ORDER ID</TableCell>
          <TableCell align="left" >
           PRODUCT NAME </TableCell>
          <TableCell align="center" >
         BUYER</TableCell>
          <TableCell align="center" >
           SELLER</TableCell>
           <TableCell align="center" >
           DATE</TableCell>
           <TableCell align="center" >
           PRICE</TableCell>
           <TableCell align="center" >
           PLATFORM FEE</TableCell>
           <TableCell align="center" >
           STATUS</TableCell>  
        </TableRow>
      </TableHead>
      <TableBody>
     
  {orders.filter((contact) => {
     // define background and set its value to ''
     
    return search.toLowerCase() === '' ? contact :
      contact.status.toLowerCase().includes(search);
     
  }).map((contact) =>

 

    <TableRow key={contact.id} sx={{
      "& td": {
      
        fontSize: "16px",
        fontWeight: "300",
        fontStyle: "normal",
        fontFamily: 'Poppins',
        alignItems: "center",
        height: "2px !important",
        padding: "2px !important"
      }
    }}>
      <TableCell align="center">{contact.sno}</TableCell>
      <TableCell align="center">{contact.orderID}</TableCell>
      <TableCell align="left">
        <td>
          <Box
            component="img"
            style={{
              marginLeft: 1,
              marginTop: 1,
              maxHeight: { xs: 2, md: 2 },
              maxWidth: { xs: 350, md: 250 },
            }}
            src={honey}
          />
        </td>
        <td>{contact.productName}</td>
      </TableCell>
      <TableCell align="center">{contact.buyer}</TableCell>
      <TableCell align="center">{contact.seller}</TableCell>
      <TableCell align="center">{contact.date}</TableCell>
      <TableCell align="center">&#8360;. {contact.price}</TableCell>
      <TableCell align="center">&#8360;. {contact.platformFee}</TableCell>
      <TableCell align="center">
      <div style={{
      width: "200px",
      height: "30px",
      background: contact.status == 'completed' ? 'rgba(14, 186, 112, 0.1)' : 
      contact.status == 'pending' ? 'rgba(251, 188, 5, 0.1)' :
      contact.status == 'cancelled' ? 'rgba(235, 67, 53, 0.1)' :
      '' ,
      borderRadius: "50px"
    }}>
      <div style={{
        width: "200px",
        height: "6px",
        color: contact.status =='completed' ? '#0EBA70' : 
        contact.status == 'pending' ? '#FBBC05' :
      contact.status == 'cancelled' ? '#EB4335' :
      '' ,
        borderRadius: "50px"
      }}>{contact.status}</div>
    </div>
        
      </TableCell>
    </TableRow>
  )}
</TableBody>

    </Table>
  </TableContainer>
  </Paper>
  </Box>
  )
}

export default Orderreports
