import React, { useState, useEffect } from 'react';
import { Box, Button, Paper, Grid, Typography } from "@material-ui/core";
import theme from "../../../theme";
import { useStyles } from "../BodyStyles";
import "./customer.css"
import data from "./mockdata_customers.json";
import api from "../../api.json";
import axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import atob from "atob"
import btoa from 'btoa';

import {
  IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import excelimg from "../../../Components/assets/excelimg.png";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ChatLeft } from 'react-bootstrap-icons';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const Admincustomers = ({ password }) => {
  const classes = useStyles();
  //const [orders, setOrders] = useState(data);
const[type,setType] = useState("1")
  const [count, setCount] = useState(data.length);
  const [activeButton, setActiveButton] = useState("1");
  const [search, setSearch] = useState('');
  const [sellers, setSellers] = useState('');
  const [admins, setAdmins] = useState('');
  const [superadmin, setSuperadmin] = useState('');
  const [member, setMember] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
const[cate,setCate]=useState('')
const togglePasswordVisibility = (contactId) => {
  setShowPassword((prevPasswords) => {
    const updatedPasswords = { ...prevPasswords };
    updatedPasswords[contactId] = !updatedPasswords[contactId];
    return updatedPasswords;
  });
};
const handleDeleteClick = (contact) => {
  setSelectedContact(contact);
  setOpenDialog(true);
};
  const handleDelete_customers = (id) => {
    axios
      .delete(api.baseUrl + "/admin/deletecustomers/" + id)
      .then(() => {
        getproducts1("")
        setOpenDialog(false);
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          alert(error.response.data.message);
        } else {
          alert("An error occurred while deleting the product.");
        }
        console.log("Error:", error);
      });
  };

  const [customers, setCustomers] = useState([]);

  const [user, setUser] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

      //const [hasNextPage, setHasNextPage] = useState(false);
      const handleNextClick = () => {
        const nextPageNumber = pageNumber + 1;
        setPageNumber(nextPageNumber);
        getproducts1("",nextPageNumber);
        console.log("paghgj",pageNumber)
      };
      const limit = 10;
      const handlePrevClick = () => {
        if (pageNumber > 1) {
          const prevPageNumber = pageNumber - 1;
          setPageNumber(prevPageNumber);
          getproducts1("",prevPageNumber);
          console.log("previous",pageNumber)
        }
      };
      const hasNextPage = user.length === limit;
  const getproducts1 = (e,page) => {
    console.log("getproducts", e);
    const startRow = page;
    const endRow = page * limit;
    console.log("latest",limit,startRow,endRow)
    setActiveButton(e);
    axios
      .get(api.baseUrl + "/admin/getcustomers?user_type=" + e,{
        params:{
          page: startRow,limit:10
        }
      })
      .then((response) => {
        setCustomers(response.data);
  
        console.log("response:",response);
  
        const responseData = response.data.data;
        const users = responseData.users; // Extract the users from the response data
        setUser(users); // Update the user state with the users data
        setCate(responseData.counts);
        console.log("userdata67", users, "mounika", responseData.counts);
  
        // Pass the email and name values to requestSearch
        // const searchedVal = {
        //   email: users.email,
        //   name: users.name,
       // };
  
       // requestSearch(searchedVal);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
 
  const handleUserTypeChange = (event, index) => {
    const updatedUser = [...user]; // Create a copy of the user array
    updatedUser[index].user_type = parseInt(event.target.value); // Update the user type for the specified index
  
    const requestBody = {
      _id: updatedUser[index]._id,
      user_type: updatedUser[index].user_type,
    };
  
    // Make the API request to update the user type using the requestBody
    fetch(api.baseUrl + '/admin/user_type_status', {
      method: 'PUT',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the API response if needed
        // For example, you can show a success message or update the user state with the response data
        setUser(updatedUser);
        getproducts1('');
      })
      .catch((error) => {
        // Handle the error if the API request fails
        console.error('Error:', error);
      });
  };
  
  useEffect(() => {
  
getproducts1("",pageNumber);
  }, [pageNumber]);

  
  
  // const requestSearch = (searchedVal) => {
  //   const { name, email } = searchedVal;
  
  //   const encodedEmail = encodeURIComponent(email);
  //   console.log("encodedEmail:",encodedEmail)
  //   const encodedName = encodeURIComponent(name);
  //   console.log("name:",encodedName)
  
  //   const apiUrl = `${api.baseUrl}/admin/searchcustomers?email=${encodedEmail}&name=${encodedName}`;
  
  //   axios.get(apiUrl)
  //     .then((response) => {
  //       setUser(response.data.data.users);
  //       console.log("topup", response.data);
  //     })
  //     .catch((err) => {
  //       console.log("error", err);
  //     });
  // };
  
  const [searchQuery, setSearchQuery] = useState('');
  const filtereduser = user.filter((contact) => {
    return (
      searchQuery.trim() === '' || // If the search query is empty, show all items
      Object.values(contact).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  });  
  
  const exportToExcel = () => {
    console.log("on export")

    // Get the table data       
    const filteredOrders = user.filter((order) => order.membership.toLowerCase().includes(search.toLowerCase()));


    // Define the header row
    const header = ["#", "User ID", "Name", "User Name", "Email", "Password", "Address", "Registered On", "Membership"];


    // Create the data array for the spreadsheet
    const data1 = [header, ...filteredOrders.map(({ sno, userID, name, username, email, password, address, registeredon, membership }) =>
      [sno, userID, name, username, email, password, address, registeredon, membership])];

    // Create a new worksheet
    const ws = XLSX.utils.aoa_to_sheet(data1);

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Table Data');

    // Create a binary string representation of the workbook
    const bin = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob from the binary string
    const blob = new Blob([bin], { type: 'application/octet-stream' });

    // Save the file
    saveAs(blob, 'customers.xlsx');

  };


  let setmem = async (updatedContact) => {
    // Perform any additional operations or API calls if needed

    // Find the index of the updated contact in the 'orders' array
    const index = user.findIndex(contact => contact._id === updatedContact.id);

    // Create a new array with the updated contact
    const updatedOrders = [...user];
    updatedOrders[index] = updatedContact;

    // Update the 'orders' state with the updated array
    setUser(updatedOrders);
  };
  const tabled = (val) => {

    setActiveButton(val);
   
    setType(val)
    getproducts1(type)
    console.log("hjgjhgj",val)

    // if (val == '3') {
    //   console.log("adminsnew")
    //  // setSearch('Admin')

    // }
    // if (val == '2') {
    //   console.log("sellernew")
    //   setSearch('Seller')

    // }
    // if (val == '4') {
    //   console.log("super adminnew")
    //   setSearch('Super Admin')
    // }
    // if (val == '1') {
    //   console.log("allnew")
    //   setSearch('')
    // }

  };
  return (
    <Box className={classes.section}>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>

            <Typography variant='h6' className={classes.breadcrum}>

              <a className={classes.breadcrum} href="/dashboard" > Go Back </a>
            </Typography>

            <div style={{ display: "flex", justifyContent: "end", alignItems: "center", marginTop: "50px" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <TextField name="search"
                  placeholder="  Search"
                //  onChange={(e) => requestSearch(e.target.value)}
                  className={classes.rese}
                  id="standard-bare"
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
      
                />
              </div>
              < div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
                <Button onClick={exportToExcel} style={{ width: "200px", height: "35px", fontFamily: 'Poppins', color: " #FFFFFF", borderRadius: "5px", background: "#0EBA70", textTransform: "none", marginLeft: theme.spacing(3), borderRadius: "3", fontweight: 500, fontSize: "14px" }}>
                  <td>
                    <Box
                      component="img"
                      style={{
                        alignItems: "left",
                        marginLeft: 2,
                        marginTop: 5,
                        marginLeft: theme.spacing(1),
                        maxHeight: { xs: 2, md: 2 },
                        maxWidth: { xs: 350, md: 250 },
                      }}
                      src={excelimg}
                    />
                  </td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </td>
                  Export to Excel</Button>
              </div>
            </div>
          </div>
          <Typography variant='h5' className={classes.pageTitleaddcust} >
            CUSTOMERS
          </Typography>

        </Grid>

        <Grid style={{ backgroundColor: 'rgba(14, 186, 112, 0.1)' }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-end" }}>
            <div style={{ marginLeft: "left" }}>
              <Button className='btn' onClick={(e) => getproducts1('')} style={{ width: "150px", height: "30px",
               color: "#FFFFFF", background: activeButton === '' ? '#0EBA70' : '#84240B', textTransform: "none", 
               marginBottom: theme.spacing(3), marginLeft: theme.spacing(1), fontWeight: 500, fontSize: "20px" }}>Users</Button>
                <Button className='btn' onClick={(e) => getproducts1('4')} style={{ width: "150px", height: "30px", color: "#FFFFFF",
               background: activeButton === '4' ? '#0EBA70' : '#84240B', textTransform: "none", marginBottom: theme.spacing(3), 
               marginLeft: theme.spacing(1), fontWeight: 500, fontSize: "20px" }}>Super Admin</Button>

              <Button className='btn' onClick={(e) => getproducts1('3')} style={{ width: "150px", height: "30px", color: "#FFFFFF",
               background: activeButton === '3' ? '#0EBA70' : '#84240B', textTransform: "none", marginBottom: theme.spacing(3), 
               marginLeft: theme.spacing(1), fontWeight: 500, fontSize: "20px" }}>Admins</Button>

              <Button className='btn' onClick={(e) => getproducts1('2')} style={{ width: "150px", height: "30px", color: "#FFFFFF",
               background: activeButton === '2' ? '#0EBA70' : '#84240B', textTransform: "none", marginBottom: theme.spacing(3),
                marginLeft: theme.spacing(1), fontWeight: 500, fontSize: "20px" }}>Sellers</Button>

                   <Button className='btn' onClick={(e) => getproducts1('1')} style={{ width: "150px", height: "30px", color: "#FFFFFF",
               background: activeButton === '1' ? '#0EBA70' : '#84240B', textTransform: "none", marginBottom: theme.spacing(3),
                marginLeft: theme.spacing(1), fontWeight: 500, fontSize: "20px" }}>Buyer</Button>
                   <Button className='btn' onClick={(e) => getproducts1('5')} style={{ width: "200px", height: "30px", color: "#FFFFFF",
               background: activeButton === '5' ? '#0EBA70' : '#84240B', textTransform: "none", marginBottom: theme.spacing(3),
                marginLeft: theme.spacing(1), fontWeight: 500, fontSize: "20px" }}>Buyer & Seller</Button>
             
            </div>
            <div style={{ marginLeft: "auto" }} >
              <Table style={{ background: "#0EBA70" }} size="small">
                <TableBody>
                  <TableRow style={{ height: "1px" }}>
                    <TableCell style={{ borderRight: "1px solid #ffffff", color: "white" }}>Users</TableCell>
                    <TableCell style={{ color: "white" }}>{cate.users}</TableCell>
                  </TableRow>
                  <TableRow style={{ height: "1px" }}>
                    <TableCell style={{ borderRight: "1px solid #ffffff", color: "white" }}>Super Admin</TableCell>
                    <TableCell style={{ color: "white" }}>{cate.super_admin}</TableCell>
                  </TableRow>
                  <TableRow style={{ height: "1px" }}>
                    <TableCell style={{ borderRight: "1px solid #ffffff", color: "white" }}>Admin</TableCell>
                    <TableCell style={{ color: "white" }}>{cate.admin}</TableCell>
                  </TableRow>
                  <TableRow style={{ height: "1px" }}>
                    <TableCell style={{ borderRight: "1px solid #ffffff", color: "white" }}>Seller</TableCell>
                    <TableCell style={{ color: "white" }}>{cate.seller}</TableCell>
                  </TableRow>
                  <TableRow style={{ height: "1px" }}>
                    <TableCell style={{ borderRight: "1px solid #ffffff", color: "white" }}>Buyer</TableCell>
                    <TableCell style={{ color: "white" }}>{cate.buyer}</TableCell>
                  </TableRow>
                  <TableRow style={{ height: "1px" }}>
                    <TableCell style={{ borderRight: "1px solid #ffffff", color: "white" }}>Buyer & Seller</TableCell>
                    <TableCell style={{ color: "white" }}>{cate.buyer_seller}</TableCell>
                  </TableRow>
                 
                  {/* Add more rows as needed */}
                </TableBody>
              </Table>


            </div>
          </div>

          <TableContainer component={Paper}>

          </TableContainer>

          <Paper sx={{ width: '100%', overflow: 'hidden' }}>


            <TableContainer >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        padding: '4px',
                        height: '20px', // Adjust the height of the table cell
                        fontStyle: 'normal',
                        fontFamily: 'Poppins',
                        fontWeight: '400',
                        backgroundColor: 'white',
                        color: 'black',
                        fontSize: '16px',
                        width: '104px',
                      }}
                    >
                      #
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: '4px',
                        fontStyle: "normal",
                        fontFamily: 'Poppins',
                        fontWeight: "400",
                        backgroundColor: "white",
                        color: "black",
                        fontSize: "16px",
                        height: '10px', // Adjust the height of the table cell
                      }}
                    >
                      User ID
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: '4px',
                        fontStyle: "normal",
                        fontFamily: 'Poppins',
                        fontWeight: "400",
                        backgroundColor: "white",
                        color: "black",
                        fontSize: "16px",
                        height: '10px', // Adjust the height of the table cell
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: '4px',
                        fontStyle: "normal",
                        fontFamily: 'Poppins',
                        fontWeight: "400",
                        backgroundColor: "white",
                        color: "black",
                        fontSize: "16px",
                        height: '10px', // Adjust the height of the table cell
                      }}
                    >
                      User Name
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: '4px',
                        fontStyle: "normal",
                        fontFamily: 'Poppins',
                        fontWeight: "400",
                        backgroundColor: "white",
                        color: "black",
                        fontSize: "16px",
                        height: '10px', // Adjust the height of the table cell
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: '4px',
                        fontStyle: "normal",
                        fontFamily: 'Poppins',
                        fontWeight: "400",
                        backgroundColor: "white",
                        color: "black",
                        fontSize: "16px",
                        height: '10px', // Adjust the height of the table cell
                      }}
                    >
                      Password
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: '4px',
                        fontStyle: "normal",
                        fontFamily: 'Poppins',
                        fontWeight: "400",
                        backgroundColor: "white",
                        color: "black",
                        fontSize: "16px",
                        height: '10px', // Adjust the height of the table cell
                      }}
                    >
                      Address
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: '4px',
                        fontStyle: "normal",
                        fontFamily: 'Poppins',
                        fontWeight: "400",
                        backgroundColor: "white",
                        color: "black",
                        fontSize: "16px",
                        height: '10px', // Adjust the height of the table cell
                      }}
                    >
                      Registered on
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: '4px',
                        fontStyle: "normal",
                        fontFamily: 'Poppins',
                        fontWeight: "400",
                        backgroundColor: "white",
                        color: "black",
                        fontSize: "16px",
                        height: '10px', // Adjust the height of the table cell
                      }}
                    >
                      Membership
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: '4px',
                        fontStyle: "normal",
                        fontFamily: 'Poppins',
                        fontWeight: "400",
                        backgroundColor: "white",
                        color: "black",
                        fontSize: "16px",
                        height: '10px', // Adjust the height of the table cell
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>



                <TableBody>

                  {filtereduser.map((contact,index) => 
                    // define background and set its value to ''
                    <TableRow key={contact._id} sx={{
                      "& td": {
                        fontSize: "16px",
                        fontWeight: "300",
                        fontStyle: "normal",
                        alignItems: "center",
                        height: "50px !important",
                        width: "1213px !important",
                        padding: "2px !important"
                      }
                    }}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{contact.user_id}</TableCell>
                      <TableCell align="center">{contact.name}</TableCell>
                      <TableCell align="center">{contact.user_name}</TableCell>
                      <TableCell align="center">{contact.email}</TableCell>


                      <TableCell align="center">
        {showPassword[contact._id] ? (
          <>
            {atob(contact.password)}{" "}
            <button
              onClick={() => togglePasswordVisibility(contact._id)}
              style={{
                border: "none",
                background: "white",
                fontSize: "2px",
                size: "2px",
              }}
            >
              <VisibilityOff />
            </button>
          </>
        ) : (
          <>
            ********{" "}
            <button
              onClick={() => togglePasswordVisibility(contact._id)}
              style={{
                marginTop: "12px",
                border: "none",
                background: "white",
                fontSize: "2px",
                size: "2px",
              }}
            >
              <Visibility />
            </button>
          </>
        )}
      </TableCell>


                      <TableCell align="center">{contact.address}</TableCell>
                      <TableCell align="center">{contact.created_at.substring(0, 10)}</TableCell>


                      <TableCell align="center">
  <Select
    value={contact.user_type}
    onChange={(event) => handleUserTypeChange(event, index)}
    className={classes.selectdrop}
    style={{
      width: '150px',
      color: 'white',
      height: '30px',
      background: '#0EBA70',
      border: '0.25px solid #000000',
      borderRadius: '10px',
      boxSizing: 'border-box',
      paddingTop: '8px',
      paddingBottom: '8px',
    }}
  >
    <MenuItem value="3">Admin</MenuItem>
    <MenuItem value="4">Super Admin</MenuItem>
    <MenuItem value="2">Seller</MenuItem>
    <MenuItem value="1">Buyer</MenuItem>
   <MenuItem value="5">Seller & Buyer</MenuItem>
  </Select>

</TableCell>



                     <TableCell align="center">



                        <div style={{
                          width: "200px",
                          height: "6px",
                          lineHeight: "21px",
                          textDecorationLine: "underline",
                          color: '#EB4335',
                          borderRadius: "50px",

                        }}>

                          <a
                            href="#"
                            style={{
                              display: "block",
                              width: "100%",
                              height: "100%",
                              color: "#EB4335"
                            }}
                            onClick={() => handleDeleteClick(contact._id)}
                          >
                           Delete
                          </a>

                        </div>


                      </TableCell>

                    </TableRow>
                  )}
                </TableBody>

              </Table>
            </TableContainer>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
              <DialogTitle style={{ color: "#000000" }}>Delete Customer?</DialogTitle>
              <DialogContent>
                <DialogContentText style={{ color: "#000000" }}>
                  Are you sure you want to delete this Customer?
                </DialogContentText>
              </DialogContent>
              
              <DialogActions>
                <Button onClick={() => setOpenDialog(false)} style={{ color: "black", fontSize: "16px", fontWeight: 400 }}>Cancel</Button>
                <Button style={{ color: "black", fontWeight: 400, fontSize: "16px" }} onClick={() => {
                  handleDelete_customers(selectedContact);
                  setSelectedContact(null);
             
                }}>Delete</Button>
              </DialogActions>
            </Dialog>

            <Box style={{ textAlign: "end" }}>
      <Button onClick={handlePrevClick} style={{ color: "#590F72" }}>
        Prev
      </Button>
      <strong style={{ color: "blue", justifyContent: "space-between" }}>
        {pageNumber}
      </strong>
      <Button onClick={handleNextClick} style={{ color: "#590F72",opacity: !hasNextPage ? '60%' : '',
          color: !hasNextPage ? '' : '',}} disabled={!hasNextPage}>
        Next
      </Button>
    </Box>
          </Paper>
        </Grid>
     
      </Grid>

    </Box>
  )
}

export default Admincustomers

