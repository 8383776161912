import React, { useState, useEffect } from 'react';
import { Box, Button, Paper, Grid, Typography } from "@material-ui/core";
import theme from "../../../theme";
import { useStyles } from "../BodyStyles";
import data from "./mockdata_individualproductslist.json";
import { IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import axios from 'axios';
import api from "../../api.json"
import approved from "../../../Components/assets/approved.png";
import pending from "../../../Components/assets/pending.png";
import declined from "../../../Components/assets/declined.png";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
//var pageNumber=1;
const Individualproductslist = () => {
  const classes = useStyles();
  const history=useHistory()
  //const [orders, setOrders] = useState(data);
  const [count, setCount] = useState(data.length);
  const [activeButton, setActiveButton] = useState("a");
  const [search, setSearch] = useState('');
  const [offset, setOffset] = useState(0);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [cate, setCate] = useState([])
  const [adminProducts, setAdminProducts] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);

  //const [hasNextPage, setHasNextPage] = useState(false);
  const handleNextClick = () => {
    const nextPageNumber = pageNumber + 1;
    setPageNumber(nextPageNumber);
    getproducts1(nextPageNumber);
    console.log("paghgj",pageNumber)
  };
  const limit = 10;
  const handlePrevClick = () => {
    if (pageNumber > 1) {
      const prevPageNumber = pageNumber - 1;
      setPageNumber(prevPageNumber);
      getproducts1(prevPageNumber);
      console.log("previous",pageNumber)
    }
  };
  const [disable2, setDisable2] = useState(true);
  const [isActive2, setIsActive2] = useState(false);
 
  const getproducts1 = (page) => {
    
    const startRow = page;
    const endRow = page * limit;
    console.log("latest",limit,startRow,endRow)
    axios
      .get(api.baseUrl + "/admin/getAdminProducts", {
        params: {
          page: startRow,
          limit: 10,
          type:"all" ,
        }
      })
      .then((response) => {
        setAdminProducts(response.data.data);
      
        console.log("setAdminProducts ----->", response.data);
      })
      .catch((err) => {
        console.log("error", err);
        // Handle the error condition and set the state accordingly
        setAdminProducts([]);
       
      });

  }
  const [searchQuery, setSearchQuery] = useState('');
  const filteredAdminProducts = adminProducts.filter((contact) => {
    return (
      searchQuery.trim() === '' || // If the search query is empty, show all items
      Object.values(contact).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  });
  const tabled = (val) => {
    setActiveButton(val);
if (val == 'd') {
  console.log("Approved")
  setSearch('approved')

}
if (val == 'p') {
  console.log("Pending")
  setSearch('pending')

}
if (val == 'c') {
  console.log("declined")
  setSearch('declined')
}
if (val == 'a') {
  console.log("all")
  setSearch('')
}

//getproducts1()
  };

  useEffect(() => {

    getproducts1(pageNumber)

  }, [search,pageNumber]);
  
  const [status, setStatus] = useState('Status');
const [comments, setComments] = useState('Comments');

const handleStatusChange = (newStatus) => {
  if (newStatus === 'active') {
    setStatus('ACTIVE');
    setComments('');
  } else if (newStatus === 'declined') {
    setStatus('Comments');
    setComments('');
  }
};
const hasNextPage = adminProducts.length === limit;
const setUser = (data) => {
  let { _id, product_name,category_name,created_at,price,product_status,brand_name,category,country,status,product_size,product_width,product_length,
    delivery_local,delivery_national,delivery_zonal,sub_category,listing_price,
    discount,importer_details,inventory,inventory_fullfilment,inventory_procurement_type,
  listing_status,luxury_cess,manufacturer_details,package_breadth,package_height,package_length,package_weight,packer_details,price_mrp,usual_price,
  product_brand,purchase_type,procurement_sla,
  product_brand_color,product_description ,final_price,price_history ,seller_id,product_design,product_image,product_model_name,product_model_number,product_pack,selling_price,stock,
  tax_code,tax_hsn,add_ons,agribreeders_approved } = data;
  localStorage.setItem('ID', _id);
  console.log("pricehistory:",price_history)
  localStorage.setItem("pricehistory",price_history)
  localStorage.setItem("sla",procurement_sla)
  localStorage.setItem("inventory",inventory)
  localStorage.setItem("inventoryfulfill",inventory_fullfilment)
  localStorage.setItem("inventoryprocure",inventory_procurement_type)
  console.log("inventry procurement",inventory_procurement_type)
  localStorage.setItem("productlength",product_length)
  console.log("productlength:",product_length)
  localStorage.setItem("productwidth",product_width)
  localStorage.setItem('status', status);
  console.log("status:",status)
  localStorage.setItem('listingprice', listing_price);
  localStorage.setItem('usualprice',usual_price)
  localStorage.setItem('finalprice', final_price);
  localStorage.setItem("importerdetails",importer_details)
  localStorage.setItem("mfa",manufacturer_details)
  localStorage.setItem("pdetails",packer_details)
  localStorage.setItem("pbreadth",package_breadth)
  localStorage.setItem("pweight",package_weight)
  localStorage.setItem("pheight",package_height)
  localStorage.setItem("packagelength",package_length)
localStorage.setItem("pbrand",product_brand)
localStorage.setItem("pbrandcolor",product_brand_color)
localStorage.setItem("pdesign",product_design)
console.log("sellerid",seller_id)
localStorage.setItem("sellerid",seller_id)
  localStorage.setItem('Productname', product_name);
  localStorage.setItem('categoryname', category_name);
  localStorage.setItem('subcategory', sub_category);
  localStorage.setItem('createdat', created_at);
  localStorage.setItem('Price', price);
  localStorage.setItem('productstatus', product_status);
  localStorage.setItem('brandname', brand_name);
  localStorage.setItem('category', category);
  localStorage.setItem('country', country);
  localStorage.setItem('productbrand2', product_brand);
  localStorage.setItem('discount', discount);
  localStorage.setItem('pdescription', product_description  );
  localStorage.setItem('pimage', JSON.stringify(product_image));
  console.log("productimage:",product_image)
  localStorage.setItem('pdesign',product_design) ;
  localStorage.setItem("hsn",tax_hsn );
  localStorage.setItem("taxcode",tax_code);
  localStorage.setItem("luxurycess",luxury_cess)
  localStorage.setItem("productmodelname",product_model_name)
  localStorage.setItem("pmnumber",product_model_number)
  localStorage.setItem("productpack",product_pack)
  localStorage.setItem("sellingprice",selling_price)
  localStorage.setItem("stock",stock)
  localStorage.setItem("pricemrp",price_mrp)
  localStorage.setItem("psize",product_size)
  localStorage.setItem("agribreedersapproved",agribreeders_approved)
  /*deliverycharges*/
  localStorage.setItem("deliverylocal",delivery_local );
  localStorage.setItem("deliveryzonal",delivery_zonal)
  localStorage.setItem("deliverynational",delivery_national)
localStorage.setItem("purchasetype",purchase_type)
  localStorage.setItem("lstats",listing_status)
  localStorage.setItem("addon",add_ons)

  history.push('/editproducts')
}


  return (
    <Box className={classes.section}>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Typography variant='h6' className={classes.breadcrum}>
            <a className={classes.breadcrum} href="/dashboard" > Go Back </a>
      </Typography>
      <div style={{ display: "flex", justifyContent: "end", alignItems: "center", marginTop:"-20px" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
      <TextField name="search"
        placeholder="  Search"
        className={classes.rese}
        id="standard-bare"
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>
    </div>




      <Typography variant='h5' className={classes.pageTitle} >
        INDIVIDUAL PRODUCT LIST
      </Typography>
      
</Grid>

<Paper sx={{ width: '100%', overflow: 'hidden' }}>


  <Button onClick={(e) => tabled('a')} style={{
    width: "200px", height: "30px", borderRadius: "5px", color: " #FFFFFF",
    background: activeButton === 'a' ? '#0EBA70' : '#84240B', textTransform: "none", marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3), fontweight: 500, fontSize: "14px"
  }}>All Products</Button>
  <Button className='btn' onClick={(e) => tabled('d')} style={{
    width: "200px", height: "30px", color: " #FFFFFF", borderRadius: "5px", background: activeButton === 'd' ? '#0EBA70' : '#84240B',

    textTransform: "none", marginBottom: theme.spacing(3), marginLeft: theme.spacing(3), fontweight: 500, fontSize: "14px"
  }}>Approved</Button>
  <Button className='btn' onClick={(e) => tabled('p')} style={{
    width: "200px", height: "30px", color: " #FFFFFF", borderRadius: "5px",
    background: activeButton === 'p' ? '#0EBA70' : '#84240B', textTransform: "none", marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3), fontweight: 500, fontSize: "14px"
  }}>Pending</Button>
  <Button className='btn' onClick={(e) => tabled('c')} style={{
    width: "200px", height: "30px",
    color: " #FFFFFF", borderRadius: "5px", background: activeButton === 'c' ? '#0EBA70' : '#84240B',
     textTransform: "none",
    marginBottom: theme.spacing(3), marginLeft: theme.spacing(3), fontweight: 500, fontSize: "14px"
  }}>Declined</Button>

  <TableContainer >
    <Table stickyHeader aria-label="sticky table">
      <TableHead >
        <TableRow sx={{
          "& th": {

            fontStyle: "normal",
            fontWeight: "400",
            backgroundColor: "white",
            color: "black",
            fontSize: "18px",
            fontWeight: "400",
            width: "1213px",

          }
        }}>

          <TableCell align="center" >#</TableCell>
          <TableCell align="center" > Product ID</TableCell>
          <TableCell align="center" >Name </TableCell>
          <TableCell align="center" >Category</TableCell>
          <TableCell align="center" >Date of Listing</TableCell>
          <TableCell align="center" > Product Price</TableCell>
          <TableCell align="center" >
  {search === 'pending' 
    ? (
      <>
        <span onClick={() => handleStatusChange('active')}>Action</span>
        
      </>
    ) 
    : (
      <>
        <span>{status}</span>
        <span style={{marginLeft: '10px'}}>{status === 'declined' ? comments : ''}</span>

      </>
    )
  }
  
</TableCell>




        </TableRow>
      </TableHead>
      <TableBody>

      {filteredAdminProducts.filter((contact) => {
          // define background and set its value to ''

      return  (
        search.toLowerCase() === "" ||
        (contact.product_status &&
          contact.product_status.toLowerCase().includes(search))
      );
    }).map((contact,index) =>



          <TableRow key={contact.id} sx={{
            "& td": {
              fontSize: "16px",
              fontWeight: "300",
              fontStyle: "normal",
              alignItems: "center",
              height: "50px !important",
              width: "1213px !important",
              padding: "2px !important"
            }
          }}>
            <TableCell align="center">{index + 1}</TableCell>
            <TableCell align="center">{contact.auto_product_id}</TableCell>
            <TableCell align="center">{contact.product_name}</TableCell>
            <TableCell align="center">{contact.category_name}</TableCell>
            <TableCell align="center">{contact.created_at.substring(0, 10)}</TableCell>
            <TableCell align="center">&#8360;. {contact.price}</TableCell>
            {/*} <TableCell align='center'>{contact.product_status}</TableCell>*/}

            <TableCell align="center">


            <div style={{
  display: "flex",
  alignItems: "center",
  width: "200px",
  height: "38px",
  fontSize: "18px",
  fontWeight: "300",
  fontStyle: "normal",
 
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  lineHeight: "20px",
  background: contact.product_status === 'approved' ? '#0EBA70' :
    contact.product_status === 'pending' ? '#F03E00' :
    contact.product_status === 'declined' ? '#FBBC05' : '',
  borderRadius: "5px"
}}>
  <div style={{
    width: "200px",
    height: "6px",
  
    color: contact.product_status === 'approved' ? 'white' :
      contact.product_status === 'pending' ? '#FBBC05' :
      contact.product_status === 'declined' ? '#EB4335' : '',
    borderRadius: "50px",
    display: "flex",
    alignItems: "center", marginLeft:"40px",
  }}>
    <img
      style={{
        marginRight: "10px",
        maxHeight: "20px",
        maxWidth: "20px", marginRight:"30px",
      }}
      src={contact.product_status === 'approved' ? approved :
        contact.product_status === 'pending' ? pending :
        contact.product_status === 'declined' ? declined : ''}
    />
    {search === 'pending' ?
      <Button
        type="submit"
        onClick={() => setUser(contact)}
        style={{
          textDecoration: "none",
          color: "white",
          zIndex: "9999",textTransform:"capitalize"
        }}
      >
        Review
      </Button> :
      contact.product_status
    }
  </div>
</div>


            </TableCell>
          </TableRow>
        )}
      </TableBody>

    </Table>
  </TableContainer>
</Paper>
  </Grid>
  <Box style={{ textAlign: "end" }}>
      <Button onClick={handlePrevClick} style={{ color: "#590F72" }}>
        Prev
      </Button>
      <strong style={{ color: "blue", justifyContent: "space-between" }}>
        {pageNumber}
      </strong>
      <Button onClick={handleNextClick} style={{ color: "#590F72",opacity: !hasNextPage ? '60%' : '',
          color: !hasNextPage ? '' : '',}} disabled={!hasNextPage}>
        Next
      </Button>
    </Box>
</Box>

  )
}

export default Individualproductslist