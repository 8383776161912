import React, { useState } from 'react'
import { Switch, Route } from "react-router-dom";
import Login from '../Components/Login';
import { Box } from "@material-ui/core";
import Dashboard1 from '../Components/BodyComponent/Dashboard/Dashboard1';
import OrderReports from '../Components/BodyComponent/Reports/Orderreports';

import Individualproductslist from '../Components/BodyComponent/Products/Individualproductslist';
import Notifications from '../Components/BodyComponent/Notifications/Notifications';
import Createnotifications from '../Components/BodyComponent/Notifications/Createnotifications';
import Addproducts from '../Components/BodyComponent/Products/Addproducts';
import Addcustomers from '../Components/BodyComponent/Customers/Addcustomers';
import Salesreports from '../Components/BodyComponent/Reports/Salesreports';
import Paymentreports from '../Components/BodyComponent/Reports/Paymentreports';
import Shipmentreports from '../Components/BodyComponent/Reports/Shipmentreports';
import Approved from '../Components/BodyComponent/Products/Approved';
import Declined from '../Components/BodyComponent/Products/Declined';
import Categorylist from '../Components/BodyComponent/Category/Categorylist';
import Createcategory from '../Components/BodyComponent/Category/Createcategory';

import { useHistory } from 'react-router-dom'
import { useStyles } from "./HeaderStyles";
import { DashboardRounded } from '@material-ui/icons';
import Navbar from "./Navbar";
import Offers from "../Components/BodyComponent/Offers/Offers";
import Ads from '../Components/BodyComponent/Ads/Ads';
import Sidenav from "./Sidenav";
import Admincustomers from '../Components/BodyComponent/Customers/Admincustomers';
import Adminsettings from '../Components/BodyComponent/Settings/Adminsettings';
import Editproducts from '../Components/BodyComponent/Products/Editproduct';
import Notificationreview from '../Components/BodyComponent/Notifications/Notificationreview';
import Adslist from '../Components/BodyComponent/Ads/Adslist';

export default function HeaderComponent() {

const classes = useStyles();

  const [mobileOpen, setMobileOpen] = useState(false);
  const history = useHistory()
  const handleDrawerOpen = () => {
    setMobileOpen(!mobileOpen);
  };
 
  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  return (

<div>
 {window.location.pathname !== '/' ? (
      <>

        <Sidenav
          mobileOpen={mobileOpen}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />
        <Navbar handleDrawerOpen={handleDrawerOpen} />
        
      </>
    ) : null}

  <Box>
    <Switch>
    <Route exact path='/' render={() => <Login />} /> 

    <Box  className={classes.wrapper}>
    <Route exact path='/dashboard' render={() => <Dashboard1/>} />
    <Route exact path='/Salesreports' render={() => <Salesreports/>} />
    <Route exact path='/Paymentreports' render={() => <Paymentreports/>} />
    <Route exact path='/Shipmentreports' render={() => <Shipmentreports/>} />
    <Route exact path='/Orderreports' render={() => <OrderReports/>} />
    <Route exact path='/individualproductslist' render={() => <Individualproductslist/>} />
    <Route exact path='/Notifications' render={() => <Notifications/>} />
    <Route exact path='/viewnotifications' render={() => <Notificationreview />} />
    <Route exact path='/Createnotifications' render={() => <Createnotifications/>} />
    <Route exact path='/Addproducts' render={() => <Addproducts/>} />
    <Route exact path="/editproducts" render={() => <Editproducts/>}/>
    <Route exact path='/Addcustomers' render={() => <Addcustomers/>} />
    <Route exact path='/Approved' render={() => <Approved/>} />
    <Route exact path='/Declined' render={() => <Declined/>} />
    <Route exact path='/Admincustomers' render={() => <Admincustomers/>} />
    <Route exact path='/Categorylist' render={() => <Categorylist/>} />
    <Route exact path='/Adminsettings' render={() => <Adminsettings />} />
    <Route exact path='/Offers' render={() => <Offers />} />
    <Route exact path='/Ads' render={() => <Ads />} />
    <Route exact path="/Adslist" render={()=> <Adslist/>} />
    <Route exact path='/createcategory' render={()=> <Createcategory/>} />

    </Box>
    </Switch>
  </Box>
</div>
  )
}

