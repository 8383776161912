import React, { useState, useEffect } from 'react';
import { Box, Button, Paper, Grid, Typography, Card } from "@material-ui/core";
import InputAdornment from '@mui/material/InputAdornment';
import theme from "../../../theme";
import img from "../../assets/img1.png"
import uploadimg from "../../assets/uploadimg.png";
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from "../BodyStyles";
import {  NativeSelect } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Divider from '@material-ui/core/Divider';
import saveupload from "../../../Components/assets/saveupload.png";
import decline from "../../../Components/assets/cancel.png";
import approve from "../../../Components/assets/approved.png";
import Vector from '../../../Components/assets/Vector.png'
import axios from 'axios';
import api from "../../api.json"
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useHistory } from 'react-router-dom';
import {
  IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions, Alert,
  AlertTitle,
  FormControl,InputLabel,inputLabelClasses
} from "@mui/material";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(0),

  },
  '& .MuiInputBase-input': {
    borderRadius: 20,
    position: 'relative',
    minHeight: 18,
    background:'rgba(14, 186, 112, 0.2)',
    border: 'none',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 20,
      borderColor: 'none',

    },
  },
}));


const Editproducts = () => {

  const classes = useStyles();

const history = useHistory()
const successIcon = <CheckCircleIcon className="success-icon" />;
const iconMapping = {
  success: successIcon,
};
const errorIcon = <ErrorIcon className="error-icon" />;
const iconMapping3 = {
  error: errorIcon,
};
const [updateStatus, setUpdateStatus] = useState(null);
var selectedUser;
  const [activeButton, setActiveButton] = useState("a");
  const [inventory_sla, setInventory_sla] = useState('');
  const [usual_price, setUsual_price] = useState('');
  const [search, setSearch] = useState('');
  const [pname, setPname] = useState('');
  const [cname, setCname] = useState("");
  const [category1, setCategory1] = useState("");
  const [brandname, setBrandname] = useState("")
  const [subcategoryID, setsubcategoryID] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [price, setPrice] = useState("");
  const [productstatus, setProductstatus] = useState("");
  const [createdat, setCreatedat] = useState("");
  const [country, setCountry] = useState("");
  const [discounttv, setDiscounttv] = useState("");
  const [id, setID] = useState("")
  const [pimage, setPimage] = useState([]);
  const [description, setDescription] = useState("");
  const [productbrand1, setProductbrand1] = useState("")
  const [productdesign, setProductDesign] = useState("")
  const [taxhsn1, setTaxhsn] = useState("");
  const [taxcode, setTaxcode] = useState("")
  const [deliverylocal1, setDeliverylocal1] = useState("")
  const [deliverynational1, setDeliverynational1] = useState("")
  const [deliveryzonal1, setDeliveryzonal1] = useState("")
  const [plength,setplength] = useState("")
  const[pwidth,setpwidth] = useState("")
  const [disable2, setDisable2] = useState(0);
  const [isActive2, setIsActive2] = useState(false);
  const [message, setMessage] = useState("");
  const [listingstatus, setListingstatus] = useState("")
  const [agribreeders_approved, setAgribreeders_approved] = useState("")
  const [shipping_type, setShipping_type] = useState("")
  const [purchase_type, setPurchase_type] = useState("")
  const [importerdetails, setImporterdetails] = useState("");
  const [manufacturerdetails, setManufacturerdetails] = useState("")
  const [packagedetails, setPackagedetails] = useState("")
  const [packagebreadth, setPackagebreadth] = useState("")
  const [packageweight, setPackageweight] = useState("")
  const [packageheight, setPackageheight] = useState("")
  const [packagelength, setPackagelength] = useState("")
  const [productbrand, setProductbrand] = useState("")
  const [productbrandcolor, setProductbrandcolor] = useState("");
  const [productmodelname, setProductmodelname] = useState("")
  const [productmodelno, setProductmodelno] = useState("")
  const [productpack, setProductpack] = useState("")
  const [subcategory,setsubcategory] = useState("")
  const [stock,setstock] = useState("")
  const [status,setStatus] = useState("")
  const [listingprice,setlistingprice] = useState("")
  const [sellingprice,setsellingprice] = useState("")
  const [mrp,setmrp] = useState("")
  const [finalprice,setfinalprice] = useState("")
  const [psize,setpsize] = useState("")
const [luxurycess,setluxurycess] = useState("")
const [inventoryfulfill,setinventoryfulfill] = useState("")
const [inventoryprocurement,setinventoryprocurement] = useState("")
const [cate, setCate] = useState([])
const [cates, setCates] = useState([])
const [selectedImages, setSelectedImages] = useState([]);
const [imagesList, setImagesList] = useState([]);
const [prostock, setprostock] = useState('');
const[add_ons,setAdd_ons]=useState('')
const [pricehistory,setpricehistory] = useState([])
const [isEdit,setisEdit] = useState(false)
var selectedSellerId;
var selectedEmployeeName;
const [seller_id, setSeller_id] = useState('');
const [fileUrl, setFileUrl] = useState('');
const[user,setUser]=useState([])
const [selecteduserdata,setselecteduserdata]= useState("")

  useEffect(() => {
    setID(localStorage.getItem('ID'))
    setAdd_ons(localStorage.getItem("add_ons"))
    localStorage.getItem("prostock") && localStorage.getItem("prostock")!== "undefined" ? setprostock(localStorage.getItem("prostock")):setprostock("")
    console.log("seller id",localStorage.getItem("sellerid"))
    console.log("pimage:",localStorage.getItem("pimage"))
    localStorage.getItem("sellerid") && localStorage.getItem("sellerid")!=="undefined" ? setselecteduserdata(localStorage.getItem("sellerid")):setselecteduserdata("")
    console.log("selecteduserdata",selecteduserdata)
    if(user && selecteduserdata){
      console.log("user",user)
      selectedUser = user.find((user1) => user1._id === selecteduserdata);
      console.log("selectedUser",selectedUser)
      if(selectedUser){
      const newuser= `${selectedUser.user_id} - ${selectedUser.name}`
      console.log("newuser",newuser)
      setSeller_id(newuser);
      }
      else{
        setSeller_id(selecteduserdata);
      }
     console.log("seller-id:",seller_id)
    }
    localStorage.getItem("psize") && localStorage.getItem("psize")!== "undefined" ?setpsize(localStorage.getItem("psize")):setpsize("")
    localStorage.getItem("importerdetails") && localStorage.getItem("importerdetails") !== "undefined" ? setImporterdetails(localStorage.getItem("importerdetails")): setImporterdetails("")
    localStorage.getItem("mfa")&& localStorage.getItem("mfa")!== "undefined"? setManufacturerdetails(localStorage.getItem("mfa")):setManufacturerdetails("")
    localStorage.getItem("sellingprice") && localStorage.getItem("sellingprice")!== "undefined"?setsellingprice(localStorage.getItem("sellingprice")):setsellingprice("")
    localStorage.getItem("usualprice") && localStorage.getItem("usualprice")!=="undefined" ? setUsual_price(localStorage.getItem('usualprice')): setUsual_price("")
    localStorage.getItem("pdetails") && localStorage.getItem("pdetails")!== "undefined" ? setPackagedetails(localStorage.getItem("pdetails")): setPackagedetails("")
    localStorage.getItem("pbreadth") && localStorage.getItem("pbreadth")!== "undefined"?setPackagebreadth(localStorage.getItem("pbreadth")):setPackagebreadth("")
    localStorage.getItem("pheight") && localStorage.getItem("pheight")!== "undefined"?setPackageheight(localStorage.getItem("pheight")):setPackageheight("")
    localStorage.getItem("packagelength") && localStorage.getItem("packagelength")!== "undefined" ?setPackagelength(localStorage.getItem("packagelength")):setPackagelength("")
    localStorage.getItem("pweight") && localStorage.getItem("pweight")!== "undefined" ? setPackageweight(localStorage.getItem("pweight")): setPackageweight("")
    localStorage.getItem("stock") && localStorage.getItem("stock")!=="undefined"? setstock(localStorage.getItem("stock")):setstock("")
    localStorage.getItem("inventoryfulfill") && localStorage.getItem("inventoryfulfill")!=="undefined"?setinventoryfulfill(localStorage.getItem("inventoryfulfill")):setinventoryfulfill("")
    console.log("inventry procurement",localStorage.getItem("inventoryprocure"))
    localStorage.getItem("inventoryprocure") && localStorage.getItem("inventoryprocure")!=="undefined"?setinventoryprocurement(localStorage.getItem("inventoryprocure")):setinventoryprocurement("")
    localStorage.getItem("luxurycess") && localStorage.getItem("luxurycess")!=="undefined"?setluxurycess(localStorage.getItem("luxurycess")):setluxurycess("")
    localStorage.getItem("finalprice") && localStorage.getItem("finalprice")!=="undefined"?setfinalprice(localStorage.getItem("finalprice")):setfinalprice("")
    localStorage.getItem("listingprice") && localStorage.getItem("listingprice")!=="undefined" ? setlistingprice(localStorage.getItem("listingprice")):setlistingprice("")
    localStorage.getItem("productpack") && localStorage.getItem("productpack")!== "undefined" ?setProductpack(localStorage.getItem("productpack")):setProductpack("")
    localStorage.getItem("pdesign") && localStorage.getItem("pdesign")!== "undefined"?setProductDesign(localStorage.getItem("pdesign")):setProductDesign("")
    localStorage.getItem("productmodelname") && localStorage.getItem("productmodelname")!== "undefined" ?setProductmodelname(localStorage.getItem("productmodelname")):setProductmodelname("")
    localStorage.getItem("pmnumber") && localStorage.getItem("pmnumber")!== "undefined"?setProductmodelno(localStorage.getItem("pmnumber")):setProductmodelno("")
    localStorage.getItem("pbrandcolor") && localStorage.getItem("pbrandcolor")!== "undefined"?setProductbrandcolor(localStorage.getItem("pbrandcolor")):setProductbrandcolor("")
    localStorage.getItem("pbrand") && localStorage.getItem("pbrand")!=="undefined"?setProductbrand(localStorage.getItem("pbrand")):setProductbrand("")
    const storedStatus = localStorage.getItem("status");
    console.log("storedStatus :",storedStatus)
    if (storedStatus && storedStatus==="active" || storedStatus==="Active"){
      setStatus("active"); }
    else if(storedStatus && storedStatus === "inactive" || storedStatus==="Inactive") {
      setStatus("inactive"); 
    }
    else{
      setStatus("")
    }
    console.log("productlength,productwidth:",localStorage.getItem("productlength"),localStorage.getItem("productwidth"))
    localStorage.getItem("productlength") && localStorage.getItem("productlength")!== "undefined"?setplength(localStorage.getItem("productlength")):setplength("")
    localStorage.getItem("productwidth") && localStorage.getItem("productwidth")!== "undefined"?setpwidth(localStorage.getItem("productwidth")):setpwidth("")
    localStorage.getItem("Productname") && localStorage.getItem("Productname")!== "undefined"?setPname(localStorage.getItem('Productname')):setPname("")
    console.log("cname:",localStorage.getItem('categoryname'))
    const storedCategoryName = localStorage.getItem('categoryname');
    const storedCategory = localStorage.getItem('category');
    var stored_product_image = JSON.parse(localStorage.getItem('pimage'));
    if (storedCategoryName && storedCategoryName!== "null" && storedCategory && storedCategory!== "null" && storedCategory!== "undefined" && storedCategoryName!== "undefined") {
      setCname(storedCategoryName);
      setCategory1(storedCategory);
    }
    localStorage.getItem("brandname") && localStorage.getItem("brandname")!== "undefined"?setBrandname(localStorage.getItem('brandname')):setBrandname("")
    console.log("subcategory:",localStorage.getItem("subcategory"))
    const storedsubCategory = localStorage.getItem('subcategory');
    if (storedsubCategory && storedsubCategory !== "null" && storedsubCategory !== "undefined"){
    setsubcategory(localStorage.getItem("subcategory"));}
    else{
      setsubcategory("")
    }
    localStorage.getItem("sla") && localStorage.getItem("sla")!== "undefined"?setInventory_sla(localStorage.getItem("sla")):setInventory_sla("")
    localStorage.getItem("Price") && localStorage.getItem("Price")!=="undefined"?setPrice(localStorage.getItem('Price')):setPrice("")
    localStorage.getItem("country") && localStorage.getItem("country")!== "undefined"?setCountry(localStorage.getItem('country')):setCountry("")
    localStorage.getItem("productstatus") && localStorage.getItem("productstatus")!== "undefined"?setProductstatus(localStorage.getItem("productstatus")):setProductstatus("")
    localStorage.getItem("discount") && localStorage.getItem("discount")!=="undefined"?setDiscounttv(localStorage.getItem("discount")):setDiscounttv("")
    localStorage.getItem("pdescription") && localStorage.getItem("pdescription")!== "undefined"?setDescription(localStorage.getItem("pdescription")):setDescription("")
    console.log("storedproduvtimage:",stored_product_image)
    if (Array.isArray(stored_product_image) && stored_product_image.length > 0) {
    setPimage(stored_product_image)}
    else{
      setPimage("")
    }
    localStorage.getItem("productbrand2") && localStorage.getItem("productbrand2")!== "undefined"? setProductbrand1(localStorage.getItem("productbrand2")):setProductbrand1("")
    localStorage.getItem("pdesign") && localStorage.getItem("pdesign")!== "undefined"? setProductDesign(localStorage.getItem("pdesign")):setProductDesign("")
    localStorage.getItem("taxcode") && localStorage.getItem("taxcode")!== "undefined"?setTaxcode(localStorage.getItem("taxcode")):setTaxcode("")
    localStorage.getItem("hsn") && localStorage.getItem("hsn")!== "undefined" ?setTaxhsn(localStorage.getItem("hsn")):setTaxhsn("")
    localStorage.getItem("deliverylocal") && localStorage.getItem("deliverylocal") !== "undefined"?setDeliverylocal1(localStorage.getItem("deliverylocal")):setDeliverylocal1("")
    localStorage.getItem("deliverynational")&& localStorage.getItem("deliverynational")!== "undefined"?setDeliverynational1(localStorage.getItem("deliverynational")):setDeliverynational1("")
    localStorage.getItem("deliveryzonal") && localStorage.getItem("deliveryzonal")!=="undefined"?setDeliveryzonal1(localStorage.getItem("deliveryzonal")):setDeliveryzonal1("")
    localStorage.getItem("pricemrp") && localStorage.getItem("pricemrp")!=="undefined"?setmrp(localStorage.getItem("pricemrp")):setmrp("")
    localStorage.getItem("lstatus") && localStorage.getItem("lstatus")!== "undefined"?setListingstatus(localStorage.getItem("lstatus")):setListingstatus("")
  //  console.log("pricehistory:",localStorage.getItem("pricehistory"))
    //setpricehistory(localStorage.getItem("pricehistory"))
    console.log("category name & Id:",cname,category1)
console.log("status:",status)
console.log("pimage:",pimage)

  }, [selectedUser,selecteduserdata,seller_id,user]);
  const [remarks, setRemarks] = useState("");
  const [imagedialog,setimagedialog] = useState(false)

  const handleDeclineClick = () => {
    setOpenDialog(true);
  };
  const handleedit = () => {
    setisEdit(true);
  }
  const [imageid,setimageid] = useState("")
  const handleimagedelete = (index) => {
    console.log("index to delete:",index)
    setimageid(index)
    setimagedialog(true)
  }

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value);
  };
  let approveproducts = async (e) => {


    e.preventDefault();
    {/*let formIsValid = true;
    let newErrors = {};
    if (!name) {
        newErrors.dname = "*Please enter Name";
        formIsValid = false;
    }

    if (!formIsValid) {
        setError(newErrors);
        return;
    }*/}
    console.log("updateproducts--->", {

    })
    setDisable2(true);
    setIsActive2(true);
    try {
      let res = await fetch(api.baseUrl + "/admin/product_status", {
        method: "POST",

        headers: {
          "Content-Type": "application/json",


        },

        body: JSON.stringify({
          _id: id,
          product_status: "approved",
          // "remark": "Testing",
          updated_by: "admin"

        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setDisable2(false);
        setIsActive2(false);
        setUpdateStatus("success");
        setTimeout(() => {
          setUpdateStatus(false);
        }, 3000);

        setMessage("User created successfully");
        history.push('/Individualproductslist')

      } else {
        setMessage("Some error occured");
        setUpdateStatus("failed")
        setTimeout(() => {
          setUpdateStatus(false);
        }, 3000);
      }
    } catch (err) {
      console.log(err);
      setDisable2(false);
      setIsActive2(false);
    }
  };
  const handleImageClick = () => {
    console.log("inside image")
    document.getElementById('fileInput').click();
    setimagedialog(false)
  };
  const handleRemoveImage = (index) => {
    console.log("index value:",index)
    if (index >= 0 && index < pimage.length) {
      console.log("remove image at index:",index);
      deleteImage(index);
      setPimage((prevImages) => prevImages.filter((_, i) => i !== index));
    }
  };
  const [showAllImages, setShowAllImages] = useState(false);

 const deleteImage = async (index) => {

  try{
    await axios.post(api.baseUrl + '/seller/product/deleteproductimage',{

      product_id: id,
      product_image: pimage[index],
    },
    );
   setimagedialog(false)
  setImagesList((prevImages) =>
  prevImages.filter((_, i) => i !== index)
  
  );

 }
    catch(error){
      console.error(error);
    };
};


  const [errorMessage, setErrorMessage] = useState('');
  let approveproducts2 = async (e) => {


    e.preventDefault();
    {/*let formIsValid = true;
    let newErrors = {};
    if (!name) {
        newErrors.dname = "*Please enter Name";
        formIsValid = false;
    }

    if (!formIsValid) {
        setError(newErrors);
        return;
    }*/}
    console.log("updateproducts--->", {

    })
    setDisable2(true);
    setIsActive2(true);
    try {
      let res = await fetch(api.baseUrl + "/admin/product_status", {
        method: "POST",

        headers: {
          "Content-Type": "application/json",


        },

        body: JSON.stringify({
          _id: id,
          product_status: "declined",
          remark: remarks,
          updated_by: localStorage.getItem("updated_by")

        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setDisable2(false);
        setIsActive2(false);
       history.push("/individualproductslist")

        setMessage("User created successfully");
        //history.push('/allemployees')

      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
      setDisable2(false);
      setIsActive2(false);
    }
    setOpenDialog(false);
  };

  let categoryselection = async (pos) => {
    console.log("pos", pos)
    setCname(pos.name);
    //setCategory(cate[(pos - 1)].name);
    setCategory1(pos._id)
    console.log("pos._id1", pos._id)
    subcategoryList();
  }
  useEffect(() => {
    const categoryList = async () => {
      try {
      
          const response = await fetch(api.baseUrl + "/admin/getcategory");
          const data = await response.json();
          
          setCate(data.data);
          console.log("Updated cate", data.data);
        } 
        catch (error) {
          console.error("Error fetching data:", error);
        }
      }

    categoryList();
      
  },[]);
 
  useEffect(() => {
    const getproducts1 = async (e) => {
      try{
        const response = await fetch("/admin/getcustomers?user_type=2")
        const data = await response.json();
        console.log("response:",response)
        setUser(data.data.users);
    console.log("Updated User", data.data.users);
  } 
  catch (error) {
    console.error("Error fetching data:", error);
  }
  
    }
    getproducts1();
  }, []);
  useEffect(() => {
    // This useEffect will run whenever cate changes
   console.log("Updated cate", cate);
   
  }, [cate]);
 

   const subcategoryList = async () => {
try{
  const response = await fetch(api.baseUrl + "/admin/getsubcategory")
  const data = await response.json();
          
  setCates(data.data);
  console.log("Updated subcategory", data.data);
} 
catch (error) {
  console.error("Error fetching data:", error);
}
  };



  const subcategoryselection = async (pos) => {
    console.log("subcategry:",pos)
    setsubcategory(pos.sub_category_name)
    setsubcategoryID(pos._id)

  }
  const [updatemessage,setUpdatemessage] = useState(false)
  let handlesave = async () => {
 
    //e.preventDefault();
    // console.log(JSON.stringify({
    //   product_name: pname,
    //   seller_id: seller_id,
    //   price: price,
    //   discount: discounttv,
    //   product_brand: productbrand,
    //   category: category1,
    //   sub_category: subcategory,
    //   stock: stock,
    //   product_description: description,
    //   product_image:pimage,
    //   status:status,
    //   listing_price: listingprice,
    //   price_mrp: mrp,
    //  // discount_price: discount_price,
    //   selling_price: sellingprice,
    //   final_price:finalprice,
    //   delivery_local: deliverylocal1,
    //   delivery_zonal: deliveryzonal1,
    //   delivery_national: deliverynational1,
    //   tax_hsn: taxhsn1,
    //   tax_code: taxcode,
    //   luxury_cess: luxurycess,
    //   inventory:"Seller only",

    //   inventory_fullfilment: inventoryfulfill,
    //   inventory_procurement_type: inventoryprocurement,
    //   listing_status: listingstatus,

    //   country: country,
    //   packer_details: packagedetails,
    //   manufacturer_details: manufacturerdetails,
    //   importer_details: importerdetails,

    //   package_weight: packageweight,
    //   package_length: packagelength,
    //   package_breadth: packagebreadth,
    //   package_height: packageheight,

    //   brand_name: brandname,
    //   product_size: psize,
    //   product_design: productdesign,
    //   product_model_name: productmodelname,
    //   product_length: plength,
    //   product_brand_color: productbrandcolor,
    //   product_width: pwidth,
    //   product_model_number: productmodelno,
    //   product_pack: productpack,

    //   agribreeders_approved: agribreeders_approved,
    //   //shipping_type: shipping_type,
    //   purchase_type: purchase_type,
    //   //shipping_charges_buyer: shipping_charges_buyer,
    //   //total_deductions: total_deductions,
    //   //settlement_value: settlement_value,
    //   platform_fee:"2%",
    //   //shipping_fee: shipping_fee,
    //   //payment_gateway_fee: payment_gateway_fee,
    //   //collection_fee: collection_fee
    // }),)

 try {
    let res = await fetch(api.baseUrl + "/admin/updateproducts/"+id, {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        product_name: pname,
        seller_id: seller_id,
        price: price,
        discount: discounttv,
        product_brand: productbrand,
        category: category1,
        sub_category: subcategory,
        stock: stock,
        product_description: description,
        product_image:pimage,
        status:status,
        listing_price: listingprice,
        price_mrp: mrp,
       // discount_price: discount_price,
        selling_price: sellingprice,
        final_price:finalprice,
        delivery_local: deliverylocal1,
        delivery_zonal: deliveryzonal1,
        delivery_national: deliverynational1,
        tax_hsn: taxhsn1,
        tax_code: taxcode,
        luxury_cess: luxurycess,
        inventory:"Seller only",

        inventory_fullfilment: inventoryfulfill,
        inventory_procurement_type: inventoryprocurement,
        listing_status: listingstatus,

        country: country,
        packer_details: packagedetails,
        manufacturer_details: manufacturerdetails,
        importer_details: importerdetails,

        package_weight: packageweight,
        package_length: packagelength,
        package_breadth: packagebreadth,
        package_height: packageheight,

        brand_name: brandname,
        product_size: psize,
        product_design: productdesign,
        product_model_name: productmodelname,
        product_length: plength,
        product_brand_color: productbrandcolor,
        product_width: pwidth,
        product_model_number: productmodelno,
        product_pack: productpack,

        agribreeders_approved: agribreeders_approved,
        //shipping_type: shipping_type,
        purchase_type: purchase_type,
        //shipping_charges_buyer: shipping_charges_buyer,
        //total_deductions: total_deductions,
        //settlement_value: settlement_value,
        platform_fee:"2%",
        //shipping_fee: shipping_fee,
        //payment_gateway_fee: payment_gateway_fee,
        //collection_fee: collection_fee
      }),
    });
    let resJson = await res.json();
    if (res.status === 200) {
      console.log("Product successfully updated")
      //alert("Product successfully added")
     // setUpdateStatus("success");
    setShowAllImages(false)
     setUpdatemessage(true)
     setTimeout(() => {
        setUpdateStatus(false);
        setUpdatemessage("")
        setisEdit(false)
       // history.push("/individualproductslist")
      }, 4000);
     
    } else {
      console.log("error occured")
      setUpdateStatus("failed")
      setTimeout(() => {
        setUpdateStatus(false);
      }, 3000);
    }
  } catch (err) {
    console.log(err);
  }
};
const handleFileChange = (event) => {
  const file = event.target.files[0];
  console.log("file:",file)
  const imageUrl = URL.createObjectURL(file);
  console.log("imagefile:",imageUrl)
  if (pimage.length < 4) {
   // setPimage(prevImages => [...prevImages, imageUrl]);
    setImagesList(prevList => [...prevList, file]);
    uploadImage(file);
    console.log("pimage:",pimage)
    setErrorMessage(''); // Clear any previous error message
  } else {
    setErrorMessage('Cannot upload more than 4 images');
    setTimeout(() => {
      setErrorMessage("")
    }, 3000);
  }
};
const [dropdownName, setDropdownName] = useState("");
const [dropdownId, setDropdownId] = useState("");

let getpriceGroupValue = async (pos) => {
  setDropdownId(user[(pos - 1)]._id);
  setDropdownName(user[(pos - 1)].user_id);
  selectedEmployeeName = user[(pos - 1)].user_id;
  selectedSellerId = user[(pos - 1)]._id;
  
  console.log("mounikaedd",selectedSellerId,selectedEmployeeName)

  setSeller_id(selectedEmployeeName);
 
}
const uploadImage = (file) => {
  const formData = new FormData();
  formData.append('file', file);

  axios.post(api.baseUrl + '/seller/uploadFile', formData)
    .then(response => {
      console.log(response.data);
      const fileUrl = response.data.file_url;
      console.log("fileUrl:", fileUrl);
      setFileUrl(fileUrl);

      // Update the imagesList with the file URL
      setPimage(prevImages => [...prevImages, fileUrl]);
      setImagesList(prevList => {
        const updatedList = [...prevList];
        updatedList[updatedList.length - 1] = fileUrl;
        return updatedList;
      });
    })
    .catch(error => {
      console.error(error);
    });
};


  return (
    <Box className={classes.section}>
        <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',



      }}>
        {updatemessage && (
          <Alert iconMapping={iconMapping} severity="success"
            onClose={() => setUpdateStatus(null)}
            style={{
              background: "green", color: "white", marginBottom: theme.spacing(2), marginLeft:theme.spacing(20),position: "fixed", zIndex: 9999,
               [theme.breakpoints.down("xs")]: {
                textAlign: 'center',

                width: "95%", marginBottom: theme.spacing(2)
              },
            }}>
            <AlertTitle> Product Updated successfully</AlertTitle>
          </Alert>
        )}
        {updateStatus === "success" && (
          <Alert iconMapping={iconMapping} severity="success"
            onClose={() => setUpdateStatus(null)}
            style={{
              background: "green", color: "white", marginBottom: theme.spacing(2), marginLeft:theme.spacing(20),position: "fixed", zIndex: 9999,
               [theme.breakpoints.down("xs")]: {
                textAlign: 'center',

                width: "95%", marginBottom: theme.spacing(2)
              },
            }}>
            <AlertTitle> Product Approved successfully</AlertTitle>
          </Alert>
        )}
        {updateStatus === "failed" && (
          <Alert iconMapping={iconMapping3} severity="error" onClose={() => setUpdateStatus(null)}
            style={{
              background: "red", color: "white", marginBottom: theme.spacing(2), position: "fixed", zIndex: 9999, [theme.breakpoints.down("xs")]: {
                textAlign: 'center',
                width: "90%",
              },
            }}>
            <AlertTitle> Failed to update</AlertTitle>
          </Alert>
        )}
      </div>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Typography variant='h6' className={classes.breadcrum}>
            <a className={classes.breadcrum} href="/individualproductslist" > Go Back </a>

          </Typography>
          {!isEdit &&  ( <Typography variant='h5' className={classes.pageTitle} >
           REVIEW AND APPROVE/DECLINE PRODUCT DETAILS
          
        <Button onClick={handleedit} style={{float:"right",
            width: "100px", height: "35px", fontFamily: 'Poppins', color: " #FFFFFF", borderRadius: "5px",
            background: "#84240B", textTransform: "none", marginLeft: theme.spacing(3), borderRadius: "3", fontweight: 500, fontSize: "14px"
          }}>
            <td>
              <Box
                component="img"
                style={{
                  marginRight: 20,
                  marginTop: 10,
                  marginLeft: theme.spacing(1),
                  maxHeight: { xs: 2, md: 2 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                src={Vector}
              />
            </td>
            EDIT</Button>
            </Typography>)}
            {isEdit && ( <Typography variant='h5' className={classes.pageTitle} >
            EDIT PRODUCT</Typography>)
            }
        </Grid>

        <Paper sx={{ width: '100%', overflow: 'hidden' }}> </Paper>
      </Grid>
      {!isEdit ? (
<div>
      <Box style={{ background: "#FFFFFF" }}>

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Name</Typography>
        <TextField className={classes.textField} value={pname} disabled={!isEdit}
          style={{
            background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px",
            boxSizing: "border-box", marginBottom: "16px"
          }} fullWidth variant="outlined" />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Price</Typography>
        <TextField value={price} disabled={!isEdit} className={classes.textField} style={{ background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Discount</Typography>
        <TextField value={discounttv}  disabled={!isEdit}className={classes.textField} style={{ background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Brand</Typography>
        <TextField value={productbrand1} disabled={!isEdit} className={classes.textField} style={{ background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Category</Typography>
        <TextField value={cname} disabled={!isEdit} className={classes.textField} style={{ background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Subcategory</Typography>
        <TextField value={subcategory} disabled={!isEdit} className={classes.textField} style={{ background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />
        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Stock</Typography>
        <TextField value={stock} className={classes.textField} disabled={!isEdit} style={{ background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />
        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Description</Typography>
        <TextField value={description} disabled={!isEdit} className={classes.textField} style={{ background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />
        <Grid container direction="row" spacing={2}>
                <Grid item xs={2}>
        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Upload Product Image</Typography>
        </Grid>
        <Grid item xs={8} style={{ position: "relative" }}>
        <div style={{ display: "flex", alignItems: "center",marginLeft:"20px", marginRight:"20px" }}>
        <TextField className={classes.textField} style={{ width: "33%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                    value={`${pimage.length} images uploaded`}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
      src={uploadimg}
      alt="Image"
      style={{
        position: "absolute",
        top: "50%",
        right: "10px", // Adjust the right position as needed
        transform: "translateY(-50%)",
        width: "20px", // Set the width and height of the image
        height: "20px"
      }}
      />
      </InputAdornment>
    ),
  }}
    />
  
       {!showAllImages && pimage.length > 2 && (
    <p style={{ color: "blue", cursor: "pointer" }} onClick={() => setShowAllImages(true)}>
      View All
    </p>
  )}
  </div>
        </Grid>
        </Grid>
        <div>    
        <div style={{ display: "flex",marginLeft:"10px" }}>
        <img src={img} alt="Default" style={{cursor:"pointer"}} />
  {pimage &&  pimage.slice(0, showAllImages ? pimage.length : 2).map((image, index) => (
    <div key={index} style={{ position: 'relative', marginLeft: "50px",marginBottom:"10px"}}>
      <img
        src={api.baseUrl+'/'+image}
        alt={`Selected ${index}`}
        style={{ width: "80px", height: "80px",borderRadius:"12px" }}
      />
    </div>
  ))}
   
</div>

</div>
<input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} />


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Listing Price
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField  value={listingprice} className={classes.textField}  disabled={!isEdit} style={{
                    width: "50%", height: "35px", background: "#e4f8ef",
                    border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px"
                  }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Select
                    value={status} 
                    disabled={!isEdit}
                    className={classes.select} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", paddingTop: "8px", paddingBottom: "8px" }}
                  >

<MenuItem value="">Select Status</MenuItem> {/* Add an empty option */}
        <MenuItem value="active">Active</MenuItem>
        <MenuItem value="inactive">Deactive</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        




        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />




        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>Price Details</Typography>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    MRP
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={mrp} className={classes.textField} disabled={!isEdit} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Your Selling Price
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={sellingprice} className={classes.textField} disabled={!isEdit} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Usual Price
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={usual_price} className={classes.textField} disabled={!isEdit} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Final Price
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={deliverylocal1} disabled={!isEdit} className={classes.textField} style={{
                    width: "50%", height: "35px", background: "#e4f8ef",
                    border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box"
                  }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        

        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />
        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>
          DELIVERY CHARGES TO CUSTOMER</Typography>
        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2} wrap="nowrap">
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Delivery Charges-Local
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={deliverylocal1} disabled={!isEdit} className={classes.textField} style={{
                    width: "50%", height: "35px", background: "#e4f8ef",
                    border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px"
                  }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Delivery Charges-Zonal
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={deliveryzonal1} disabled={!isEdit} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Delivery Charges-National
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={deliverynational1} disabled={!isEdit} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Box>


        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />


        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>TAX DETAILS</Typography>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2} wrap="nowrap">
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    HSN CODE
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={taxhsn1} disabled={!isEdit} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    TAX CODE
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={taxcode}  disabled={!isEdit}className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    LUXURY PRODUCT CESS
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={luxurycess} className={classes.textField}  disabled={!isEdit} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Box>

        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />
        <Grid container direction="row" spacing={2}>
          <Grid item xs={6}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={4}>
                <Typography style={{
                  color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px",
                  textDecorationLine: "underline", marginBottom: "16px"
                }}>SELLER</Typography></Grid>
                  <Grid item xs={8}>
                  <FormControl className={classes.error} style={{
                  
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1), minWidth: "250px"
                  }}>
                    <InputLabel id="demo-simple-select-label"  InputLabelProps={{
                      sx: {
                        color: "#590F72",
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#590F72",
                          fontWeight: 500,
                          fontSize: 16
                        }
                      }
                    }}></InputLabel>
                    <NativeSelect id="select" label="Role"
                      InputLabelProps={{
                        sx: {
                          color: "#590F72",
                          margin: theme.spacing(2),
                        

                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#590F72",
                            fontWeight: 600,
                            fontSize: 16, 
                          }
                        }
                      }}
                      value={seller_id} disabled={!isEdit} 

                      className={classes.select}
                      input={<BootstrapInput />}
                    >
                      <option value="">{seller_id}</option>
                      {user && user.map((cat) => (
                        <option key={cat._id} value={cat.user_id}>{cat.name} - {cat.user_id}</option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </Grid>
            </Grid>

          </Grid>
        </Grid>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
                    <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Inventory
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                 
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Seller Only
                  </Typography>
                </Grid>
                
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />


        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>INVENTORY DETAILS</Typography>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Fulfilment by
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={inventoryfulfill} className={classes.textField} disabled={!isEdit} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Procurement type
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={inventoryprocurement} className={classes.textField}disabled={!isEdit} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100px" }}>
                    Procurement SLA
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={inventory_sla} className={classes.textField}disabled={!isEdit} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100px" }}>
                    Stock
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={stock}className={classes.textField} disabled={!isEdit} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>MANUFACTURING DETAILS</Typography>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Country Of Origin
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={country} disabled={!isEdit} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Packers Details
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={packagedetails} disabled={!isEdit} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Manufacturing Details
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={manufacturerdetails} disabled={!isEdit} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Importer Details
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={importerdetails} disabled={!isEdit} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>
          PACKAGING DETAILS</Typography>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Package Weight
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={packageweight} disabled={!isEdit} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Package Length
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={packagelength} disabled={!isEdit} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Package Breadth
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={packagebreadth}disabled={!isEdit} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Package Height
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={packageheight} disabled={!isEdit} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />




        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>PRODUCT DETAILS</Typography>
        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Brand
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={productbrand} className={classes.textField} disabled={!isEdit} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Size
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={psize}className={classes.textField} disabled={!isEdit} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography  style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Design
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={productdesign} disabled={!isEdit}  className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Model Name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={productmodelname} disabled={!isEdit} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Length
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={plength} disabled={!isEdit} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Brand Color
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={productbrandcolor} disabled={!isEdit} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Width
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={pwidth} disabled={!isEdit} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Model Number
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={productmodelno}disabled={!isEdit} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>



        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Pack Of
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={productpack} disabled={!isEdit} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Box>

        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />



        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>SETTLEMENT DETAILS</Typography>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
        

        <Grid container direction="row" spacing={0}>
  <Grid item xs={2}>
    <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
      Shipment charges
    </Typography>
  </Grid>
<div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}} spacing={3}>
  <Grid item xs={4} style={{marginRight:"10px"}}>
    <Typography style={{ color: "#FFFFFF",background: "#0EBA70", borderRadius: "10px", 
    fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", 
    marginBottom: "16px", width: "100%",marginRight:"10px",textAlign:"center" }}>
      Local - Rs.50+18% taxes
    </Typography>
  </Grid>

  <Grid item xs={4}  style={{marginRight:"10px"}}>
    <Typography style={{ color: "#FFFFFF",textAlign:"center", background: "#0EBA70", borderRadius: "10px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px",
     fontSize: "14px", marginBottom: "16px", width: "100%",marginRight:"10px" }}>
      Zonal - Rs.60+18% taxes
    </Typography>
  </Grid>

  <Grid item xs={4}  style={{marginRight:"10px"}}>
    <Typography style={{ color: "#FFFFFF", background: "#0EBA70",textAlign:"center",borderRadius: "10px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px",
     fontSize: "14px", marginBottom: "16px", width: "120%" }}>
      National - Rs.70+18% taxes
    </Typography>
  </Grid>
  </div>
</Grid>
  <Grid container direction="row" spacing={2}>

            <Grid item xs={6}>
              <Grid container direction="row" spacing={3}>
              
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Agribreeders Approved
                  </Typography>
                </Grid>
                <Grid item xs={8}>

                  <Select
                    value={agribreeders_approved || "Yes"}disabled={!isEdit}
                    onChange={(e) => setAgribreeders_approved(e.target.value)}
                    className={classes.select} style={{ width: "50%", height: "35px", background: "#FFFFFF",background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                  >  <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>

                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Selling Price
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField value={sellingprice} disabled={!isEdit}
                  onChange={(e) => setsellingprice(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef",
                   border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Purchase Type
                  </Typography>
                </Grid>
                <Grid item xs={8}>

                  <Select
                    value={purchase_type} disabled={!isEdit}
                    onChange={(e) => setPurchase_type(e.target.value)}
                    className={classes.select} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Purchase Type
                </MenuItem> <MenuItem value="Prepaid">Prepaid</MenuItem>
                    <MenuItem value="COD">COD</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Add Ons (Commission, Shipping, Fixed & Collection)
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={add_ons} disabled={!isEdit}
                  onChange={(e) => setAdd_ons(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#e4f8ef",
                   border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Final Price
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField className={classes.textField} disabled={!isEdit} style={{ width: "50%", height: "35px", background: "#e4f8ef", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    value={finalprice} onChange={(e) => setfinalprice((v) => (e.target.validity.valid ? e.target.value : v))}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Platform Fee
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>2%(Fixed Charges)</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>

        </Box>
  
        < div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        <Button  type="submit" onClick={approveproducts} style={{ width: "200px", height: "35px", fontFamily: 'Poppins', color: " #FFFFFF", 
          borderRadius: "5px", background: "#0EBA70 ", textTransform: "none", marginLeft: theme.spacing(3), borderRadius: "3", fontweight: 500, fontSize: "14px" }}>
            <td>
              <Box
                component="img"
                style={{
                  marginRight: 20,
                  marginTop: 10,
                  marginLeft: theme.spacing(1),
                  maxHeight: { xs: 2, md: 2 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                src={approve}
              />
            </td>
            Approve</Button>

          <Button  onClick={handleDeclineClick} style={{
            width: "200px", height: "35px", fontFamily: 'Poppins', color: " #FFFFFF", borderRadius: "5px",
            background: "#EB4335", textTransform: "none", marginLeft: theme.spacing(3), borderRadius: "3", fontweight: 500, fontSize: "14px"
          }}>
            <td>
              <Box
                component="img"
                style={{
                  marginRight: 20,
                  marginTop: 10,
                  marginLeft: theme.spacing(1),
                  maxHeight: { xs: 2, md: 2 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                src={decline}
              />
            </td>
            DECLINE</Button>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)} style={{borderRadius:"60px",
                      border:" 1px solid #0EBA70 !important"}} InputProps={{
                  style: {
                      borderRadius: "60px",               
                      borderColor:" 1px solid #0EBA70 !important"
                  },
              }}>
            <DialogTitle style={{width:"271px",marginLeft:"50px",marginRight:"50px",marginTop:"30px",}}>Reason for Product Decline</DialogTitle>
       
              
                <TextField placeholder="Message Here..." value={remarks} onChange={handleRemarksChange}
                 style={{marginLeft:"70px",marginRight:"50px",width:"429px",height:"169px"}}    InputProps={{
                  style: {
                      borderRadius: "20px",
                      height: '129px',
                      borderColor:"#0EBA70",
                      border:" 1px solid #0EBA70", '::placeholder': {
                        marginTop: '-30px' // set the marginTop of placeholder text here
                      }
                  },
              }}/>
              
          
            <DialogActions>
            {/*}  <Button onClick={() => setOpenDialog(false)}>Cancel</Button>*/}
              <Button onClick={approveproducts2} style={{background:"#0EBA70",color:"white",width:"185px",marginBottom:"20px"}}>Submit</Button>
            </DialogActions>
          </Dialog>
</div>
        

      </Box>
      </div>):(<div>
        <Box style={{ background: "#e4f8ef",marginLeft:"-20px",marginRight:"-20px",padding:"20px" }}>

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Name</Typography>
        <TextField value={pname} onChange={(e) => setPname(e.target.value)} className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Price</Typography>
        <TextField value={price} onChange={(e) => setPrice((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined"
          inputProps={{
            pattern: "[0-9]*",
            inputMode: "numeric",
            style: { textAlign: "left" }
          }}
        />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Discount</Typography>
        <TextField value={discounttv} onChange={(e) => setDiscounttv((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined"
          inputProps={{
            pattern: "[0-9]*",
            inputMode: "numeric",
            style: { textAlign: "left" }
          }}
        />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Brand</Typography>
        <TextField value={productbrand1} onChange={(e) => setProductbrand1(e.target.value)} className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Category</Typography>


        <Select

          value={cname ? cate.find(cat => cat.name === cname) : ''}
          onChange={(e) => categoryselection(e.target.value)}
          className={classes.selectd} style={{ height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined"
          displayEmpty
          >
            <MenuItem value="" disabled>
              Select Category
            </MenuItem>

          {cate && cate.map((cat) => (
            <MenuItem key={cat._id} value={cat}>
              {cat.name}
            </MenuItem>
          ))}
        </Select>
        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Subcategory</Typography>

        <Select

value={subcategory? cates.find(cats => cats.sub_category_name === subcategory) : ''}
onChange={(e) => subcategoryselection(e.target.value)}
          className={classes.selectd} style={{ height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined"
          displayEmpty
          >
            <MenuItem value="" disabled>
              Select  Subcategory
            </MenuItem>

          {cates && cates.map((cats) => (
            <MenuItem key={cats._id} value={cats}>
              {cats.sub_category_name}
            </MenuItem>
          ))}
        </Select>

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Stock</Typography>
        <TextField value={stock} onChange={(e) => setstock((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined"
          inputProps={{
            pattern: "[0-9]*",
            inputMode: "numeric",
            style: { textAlign: "left" }
          }}
        />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Product Description</Typography>
        <TextField value={description} onChange={(e) => setDescription(e.target.value)} className={classes.textField} style={{ background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} fullWidth variant="outlined" />
        <Grid container direction="row" spacing={2}>
                <Grid item xs={2}>
        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>Upload Product Image</Typography>
        </Grid>
        <Grid item xs={8} style={{ position: "relative" }}>
        <div style={{ display: "flex", alignItems: "center",marginLeft:"20px", marginRight:"20px" }}>
        <TextField className={classes.textField} style={{ width: "33%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                    value={`${pimage.length} images uploaded`}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
      src={uploadimg}
      alt="Image"
      style={{
        position: "absolute",
        top: "50%",
        right: "10px", // Adjust the right position as needed
        transform: "translateY(-50%)",
        width: "20px", // Set the width and height of the image
        height: "20px"
      }}
      />
      </InputAdornment>
    ),
  }}
    />
  </div>
        </Grid>
        </Grid>
        <div>
        
        <div style={{ display: "flex",marginLeft:"10px" }}>
        <img src={img} alt="Default" onClick={handleImageClick} style={{cursor:"pointer"}} />
        {pimage && pimage.map((image, index) => {
  const handleDeleteClick = () => {
    console.log("index value inside delete:",index)
    handleimagedelete(index); // Call handleimagedelete with the correct index
  };
  const handleRemoveClick = () => {
    console.log("index value inside remove:",imageid)
    handleRemoveImage(imageid); // Call handleimagedelete with the correct index
  };


  return (
    <div key={index} style={{ position: 'relative', marginLeft: "50px", marginBottom: "10px" }}>
      <img
        src={api.baseUrl + '/' + image}
        alt={`Selected ${index}`}
        style={{ width: "100px", height: "100px", borderRadius: "12px" }}
      />
      <button
        onClick={handleDeleteClick}
        style={{
          position: 'absolute',
          top: '0px',
          right: '-1px',
          backgroundColor: '#0EBA70',
          border: 'none',
          cursor: 'pointer',
          color: 'white',
          borderRadius: "40px"
        }}
      >
        <ClearIcon />
      </button>
      <Dialog open={imagedialog} onClose={() => setimagedialog(false)} style={{ borderRadius: "20px", border: "1px solid #0EBA70 !important" }} InputProps={{
        style: {
          borderRadius: "20px",
          borderColor: "1px solid #0EBA70 !important"
        },
      }}>
        <DialogTitle style={{ width: "271px", marginLeft: "50px", marginRight: "50px", marginTop: "30px" }}>Are you sure you want to delete image?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setimagedialog(false)} style={{ background: "#0EBA70", color: "white", width: "185px", marginBottom: "20px" }}>Cancel</Button>
          <Button onClick={handleRemoveClick} style={{ background: "#EB4335", color: "white", width: "185px", marginBottom: "20px" }}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
})}
</div>

  {errorMessage && <p style={{color:"red"}}>{errorMessage}</p>}
</div>
<input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} />


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{
                    color: "black", fontFamily: 'Poppins', fontStyle: "normal",
                    fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "124px"
                  }}>
                    Listing Price
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={listingprice} onChange={(e) => setlistingprice((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={8}>

                  <Select

                    value={status || ""}
                    onChange={(e) => setStatus(e.target.value)}
                    style={{
                      width: "50%",
                      height: "35px",
                      background: "#FFFFFF",
                      border: "0.25px solid #000000",
                      borderRadius: "10px",
                      boxSizing: "border-box",
                      paddingTop: "8px",
                      paddingBottom: "8px" }}
                  >
<MenuItem value="">Select Status</MenuItem>
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Deactive</MenuItem>

                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>







        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />




        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>Price Details</Typography>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    MRP
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={mrp} onChange={(e) => setmrp((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Your Selling Price
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={sellingprice} onChange={(e) => setsellingprice((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Usual Price
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={usual_price} onChange={(e) => setUsual_price((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Final Price
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={finalprice} onChange={(e) => setfinalprice(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>




        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />
        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>DELIVERY CHARGES TO CUSTOMER</Typography>
        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2} wrap="nowrap">
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Delivery Charges-Local
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={deliverylocal1} onChange={(e) => setDeliverylocal1(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Delivery Charges-Zonal
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={deliveryzonal1} onChange={(e) => setDeliveryzonal1(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Delivery Charges-National
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={deliverynational1} onChange={(e) => setDeliverynational1(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Box>


        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />


        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>TAX DETAILS</Typography>

        <Grid item xs={8}>
                  <Typography> </Typography>
                </Grid>
        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2} wrap="nowrap">
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    HSN CODE
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={taxhsn1} onChange={(e) => setTaxhsn((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    TAX CODE
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={taxcode} onChange={(e) => setTaxcode((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    LUXURY PRODUCT CESS
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={luxurycess} onChange={(e) => setluxurycess((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Box>


        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>PRICE HISTORY</Typography>

        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />
        <Grid container direction="row" spacing={2}>
          <Grid item xs={6}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={4}>
                <Typography style={{
                  color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px",
                  textDecorationLine: "underline", marginBottom: "16px"
                }}>SELLER</Typography></Grid>
                  <Grid item xs={8}>
                  <FormControl className={classes.error} style={{
                  
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1), minWidth: "250px"
                  }}>
                    <InputLabel id="demo-simple-select-label"  InputLabelProps={{
                      sx: {
                        color: "#590F72",
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#590F72",
                          fontWeight: 500,
                          fontSize: 16
                        }
                      }
                    }}></InputLabel>
                    <NativeSelect id="select" label="Role"
                      InputLabelProps={{
                        sx: {
                          color: "#590F72",
                          margin: theme.spacing(2),
                        

                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#590F72",
                            fontWeight: 600,
                            fontSize: 16, 
                          }
                        }
                      }}
                      value={seller_id}onChange={(e) => getpriceGroupValue(e.target.options.selectedIndex)}

                      className={classes.select}
                      input={<BootstrapInput />}
                    >
                      <option value="">{seller_id}</option>
                      {user && user.map((cat) => (
                        <option key={cat._id} value={cat.user_id}>{cat.name} - {cat.user_id}</option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </Grid>
            </Grid>

          </Grid>
        </Grid>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Inventory
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography> Seller only </Typography>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Box>
        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />


        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>INVENTORY DETAILS</Typography>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Fulfilment by
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={inventoryfulfill} onChange={(e) => setinventoryfulfill(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Procurement type
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={inventoryprocurement} onChange={(e) => setinventoryprocurement(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100px" }}>
                    Procurement SLA
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={inventory_sla} onChange={(e) => setInventory_sla(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100px" }}>
                    Stock
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={stock} onChange={(e) => setprostock(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />




        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>MANUFACTURING DETAILS</Typography>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Country Of Origin
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={country} onChange={(e) => setCountry(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Packers Details
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={packagedetails} onChange={(e) => setPackagedetails(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Manufacturing Details
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={manufacturerdetails} onChange={(e) => setManufacturerdetails(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Importer Details
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={importerdetails} onChange={(e) => setImporterdetails(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />

        <Typography style={{
          color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline",
          marginBottom: "16px"
        }}>PACKAGING DETAILS</Typography>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Package Weight
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={packageweight} onChange={(e) => setPackageweight(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Package Length
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={packagelength} onChange={(e) => setPackagelength(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Package Breadth
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={packagebreadth} onChange={(e) => setPackagebreadth (e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Package Height
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={packageheight} onChange={(e) => setPackageheight(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />




        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>PRODUCT DETAILS</Typography>
        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Brand
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={brandname} onChange={(e) => setBrandname(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Size
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={psize} onChange={(e) => setpsize (e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Design
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={productdesign} onChange={(e) => setProductDesign(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Model Name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={productmodelname} onChange={(e) => setProductmodelname(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Length
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={plength} onChange={(e) => setplength (e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Brand Color
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={productbrandcolor} onChange={(e) => setProductbrandcolor(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Width
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={pwidth} onChange={(e) => setpwidth (e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px" }}>
                    Model Number
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={productmodelno} onChange={(e) => setProductmodelno((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>



        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "84px" }}>
                    Pack Of
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={productpack} onChange={(e) => setProductpack((v) => (e.target.validity.valid ? e.target.value : v))} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Box>

        <Divider variant='horizontal' style={{ backgroundColor: "#000000" }} />

        <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", textDecorationLine: "underline", marginBottom: "16px" }}>SETTLEMENT DETAILS</Typography>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
        

        <Grid container direction="row" spacing={0}>
  <Grid item xs={2}>
    <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
      Shipment charges
    </Typography>
  </Grid>
<div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}} spacing={3}>
  <Grid item xs={4} style={{marginRight:"10px"}}>
    <Typography style={{ color: "#FFFFFF", background: "#0EBA70", borderRadius: "10px", 
    fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", 
    marginBottom: "16px", width: "100%",marginRight:"10px",textAlign:"center" }}>
      Local - Rs.50+18% taxes
    </Typography>
  </Grid>

  <Grid item xs={4}  style={{marginRight:"10px"}}>
    <Typography style={{ color: "#FFFFFF",textAlign:"center", background: "#0EBA70", borderRadius: "10px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px",
     fontSize: "14px", marginBottom: "16px", width: "100%",marginRight:"10px" }}>
      Zonal - Rs.60+18% taxes
    </Typography>
  </Grid>

  <Grid item xs={4}  style={{marginRight:"10px"}}>
    <Typography style={{ color: "#FFFFFF", background: "#0EBA70",textAlign:"center",borderRadius: "10px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px",
     fontSize: "14px", marginBottom: "16px", width: "120%" }}>
      National - Rs.70+18% taxes
    </Typography>
  </Grid>
  </div>
</Grid>
  <Grid container direction="row" spacing={2}>

            <Grid item xs={6}>
              <Grid container direction="row" spacing={3}>
              
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Agribreeders Approved
                  </Typography>
                </Grid>
                <Grid item xs={8}>

                  <Select
                    value={agribreeders_approved || "Yes"}
                    onChange={(e) => setAgribreeders_approved(e.target.value)}
                    className={classes.select} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                  >  <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>

                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Selling Price
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField value={sellingprice} 
                  onChange={(e) => setsellingprice(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF",
                   border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>


        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Purchase Type
                  </Typography>
                </Grid>
                <Grid item xs={8}>

                  <Select
                    value={purchase_type}
                    onChange={(e) => setPurchase_type(e.target.value)}
                    className={classes.select} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Purchase Type
                </MenuItem> <MenuItem value="Prepaid">Prepaid</MenuItem>
                    <MenuItem value="COD">COD</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Add Ons (Commission, Shipping, Fixed & Collection)
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField value={add_ons} 
                  onChange={(e) => setAdd_ons(e.target.value)} className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF",
                   border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", marginBottom: "16px", width: "100%" }}>
                    Final Price
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField className={classes.textField} style={{ width: "50%", height: "35px", background: "#FFFFFF", border: "0.25px solid #000000", borderRadius: "10px", boxSizing: "border-box", marginBottom: "16px" }}
                    value={finalprice} onChange={(e) => setfinalprice((v) => (e.target.validity.valid ? e.target.value : v))}
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      style: { textAlign: "left" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <Typography style={{ color: "black", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500px", fontSize: "14px", width: "100%" }}>
                    Platform Fee
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>2%(Fixed Charges)</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ borderRadius: "20px", fontFamily: 'Poppins', fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "black" }}>

        </Box>
        < div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
      <Button onClick={handlesave} style={{ width: "150px", height: "35px", textAlign:'center',fontFamily: 'Poppins', color: " #e4f8ef", borderRadius: "5px", background: "#0EBA70", textTransform: "none", borderRadius: "3", fontweight: 500, fontSize: "14px" }}>
           
             
            SAVE </Button>
            <Button disabled={isEdit} type="submit" onClick={approveproducts} style={{ width: "200px", height: "35px", fontFamily: 'Poppins', color: " #FFFFFF", 
          borderRadius: "5px", background: "#D9D9D9 ", textTransform: "none", marginLeft: theme.spacing(3), borderRadius: "3", fontweight: 500, fontSize: "14px" }}>
            <td>
              <Box
                component="img"
                style={{
                  marginRight: 20,
                  marginTop: 10,
                  marginLeft: theme.spacing(1),
                  maxHeight: { xs: 2, md: 2 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                src={approve}
              />
            </td>
            Approve</Button>

          <Button disabled={isEdit} onClick={handleDeclineClick} style={{
            width: "200px", height: "35px", fontFamily: 'Poppins', color: " #FFFFFF", borderRadius: "5px",
            background: "#D9D9D9 ", textTransform: "none", marginLeft: theme.spacing(3), borderRadius: "3", fontweight: 500, fontSize: "14px"
          }}>
            <td>
              <Box
                component="img"
                style={{
                  marginRight: 20,
                  marginTop: 10,
                  marginLeft: theme.spacing(1),
                  maxHeight: { xs: 2, md: 2 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                src={decline}
              />
            </td>
            DECLINE</Button>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)} style={{borderRadius:"40px",
                      border:" 1px solid #0EBA70 !important"}} InputProps={{
                  style: {
                      borderRadius: "40px",
               
                     
                      borderColor:" 1px solid #0EBA70 !important"
                  },
              }}>
            <DialogTitle style={{width:"271px",marginLeft:"50px",marginRight:"50px",marginTop:"30px",}}>Reason for Product Decline</DialogTitle>
       
              
                <TextField placeholder="Message Here..." value={remarks} onChange={handleRemarksChange}
                 style={{marginLeft:"70px",marginRight:"50px",width:"429px",height:"169px"}}    InputProps={{
                  style: {
                      borderRadius: "20px",
                      height: '129px',
                      borderColor:"#0EBA70",
                      border:" 1px solid #0EBA70", '::placeholder': {
                        marginTop: '-30px' // set the marginTop of placeholder text here
                      }
                  },
              }}/>
              
          
            <DialogActions>
            {/*}  <Button onClick={() => setOpenDialog(false)}>Cancel</Button>*/}
              <Button onClick={approveproducts2} style={{background:"#0EBA70",color:"white",width:"185px",marginBottom:"20px"}}>Submit</Button>
            </DialogActions>
          </Dialog>

         


        </div>
        </Box>
      </div>

      )}
    </Box>


    
  )
}

export default Editproducts
