import React from 'react'
import { BrowserRouter } from "react-router-dom";
import HeaderComponent from './Header/HeaderComponent'
import FooterComp from './Header/FooterComp';


function App() {
  return (
    <BrowserRouter>
      <HeaderComponent />
      < FooterComp/>
    </BrowserRouter>
  )
}

export default App
