import {React,useState} from 'react'
import { useStyles } from "../BodyStyles";
import "./AdminSettings.css";
import ReactSwitch from 'react-switch';


import { Box,Grid,Button, Typography,Paper } from "@material-ui/core";
const Adminsettings = () => {
  const [selectedOption, setSelectedOption] = useState('');
  
    const [checked, setChecked] = useState(true);
  
    const handleChange = val => {
      setChecked(val)
    }
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [selectedOptiondel, setSelectedOptiondel] = useState('');

  const handleSelectChangedel = (event) => {
    setSelectedOptiondel(event.target.value);
  };
  const [selectedOptionimg, setSelectedOptionimg] = useState('');

  const handleSelectChangeimg = (event) => {
    setSelectedOptionimg(event.target.value);
  };
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const [isCheckedbio, setIsCheckedbio] = useState(false);

  const handleCheckboxChangebio = () => {
    setIsCheckedbio(!isCheckedbio);
  };
  const [isCheckedshow, setIsCheckedshow] = useState(false);

  const handleCheckboxChangeshow = () => {
    setIsCheckedshow(!isCheckedshow);
  };
  const [isCheckedshowimg, setIsCheckedshowimg] = useState(false);

  const handleCheckboxChangeshowimg = () => {
    setIsCheckedshowimg(!isCheckedshowimg);
  };
 
  const [activeButton, setActiveButton] = useState(1);
  const [isDivVisible, setDivVisible] = useState(false);

  const handleClick = () => {
    setDivVisible(true);
  };

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };
  const classes = useStyles();
  const [active, setActive] = useState(false);
  const handleSaveClick = () => {
    setActive(!active);
    alert("Data saved Successfully");
  };
  return (
    <div>
    <Box className={classes.section}>
    <Grid container justifyContent= "flex-end">
    <Grid item xs={12} sm={12}>
      <Typography variant='h6' className={classes.breadcrum}>
      <a  className={classes.breadcrum} href="/dashboard" > Go Back </a>
     
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography variant='h5' className={classes.noti} >
         SETTINGS/ADMIN PREVILEGES
        </Typography>  
        </Box>
        {activeButton==3 && isDivVisible && 
        <div id="hide" style={{display:"flex",marginRight:"20px",float:"right"}}>
            <ReactSwitch
            offColor="#FF0000"
        checked={checked}
        onChange={handleChange}
        width={78}
      />
      <span style={{marginLeft:"20px"}}>{checked ? 'ENABLE' : 'DISABLE'}</span>
    </div>}
        </Grid>
        </Grid>
        </Box>
        
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Button onClick={() => handleButtonClick(1)} style={{
          width: "150px", height: "30px", color: " #FFFFFF", 
          background: activeButton === 1 ? '#0EBA70' : '#84240B', textTransform: "none", marginLeft:"0px",
         fontweight: 500, fontSize: "16px",paddingLeft:"0",Align:"left"
        }}>Seller</Button>
        <Button className='btn' onClick={() => handleButtonClick(2)} style={{
          width: "150px", height: "30px", color: " #FFFFFF",  background: activeButton === 2 ? '#0EBA70' : '#84240B',
                textTransform: "none",  fontweight: 500, fontSize: "16px",Align:"left"
        }}>Admin</Button>
        <Button className='btn' onClick={() =>{ handleButtonClick(3);handleClick();}} style={{
          width: "150px", height: "30px", color: " #FFFFFF",  background: activeButton === 3 ? '#0EBA70' : '#84240B',
                textTransform: "none",  fontweight: 500, fontSize: "16px",Align:"left"
        }}>Settlement</Button>
        </Paper>
        {activeButton === 1 && <div>
        <div className='form-row'>
              <label className="content">Seller can edit post ?</label>
              <div className='input helper'>
              <select className="select" value={selectedOption} onChange={handleSelectChange}
              style={{ marginTop:'10px',marginLeft:'32%' }}>
        <option value="option1">Yes</option>
        <option value="option2">No</option>
        </select>
        <p classname="helper" style={{ color:'grey', margin:'0 0 0 130px',fontStyle:'italic'}}>Seller will be able to edit their own posts</p>
                
                <br></br>
                </div>
                </div>
                <div className='form-row'>
                <label className="content">Seller can delete post ?</label>
                <div className='input helper'>
                <select className="selectdel" value={selectedOptiondel} onChange={handleSelectChangedel}
                style={{marginTop:'10px', marginLeft:'28%' }}>
        <option value="option1">Yes</option>
        <option value="option2">No</option>
        </select>
        <p classname="helper" style={{ color:'grey',margin:'0 0 0 120px',fontStyle:'italic' }}>Seller will be able to delete their own posts</p>
                     <br></br>
                     </div>
                     </div>
                     <div className='form-row'>
                <label className="content">Pending Post edit</label>
                <div className='input'>
                <label className="check">
                <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          style={{ transform: 'scale(1.5)', marginTop:'15px',marginLeft: '120px',marginRight:'2px' }}
        />
        </label>
        <span classname="helper" style={{marginLeft:'0'}}> Disable post editing while post in 'pending' status</span>
   </div>
</div>
<div className='form-row'>
                <label className="content">Pending Post edit</label>
                <div className='input'>
                <input className=" text helper" type="number" value="10" style={{ textAlign:'center', marginTop:'10px',marginLeft: '280%'}}></input>
               
                </div>
                </div>
                <div className='form-row'>
                <label className="content">Show Seller bio</label>
                <div className='input'>
                <label className="check">
                <input
          type="checkbox"
          checked={isCheckedbio}
          onChange={handleCheckboxChangebio}
          style={{ transform: 'scale(1.5)',marginRight:'5px', marginTop:'10px',marginLeft: '140px' }}
        />
        </label>
        <span classname="helper" style={{ fontStyle:"light" }}>Seller bio info will be shown</span>
        </div>
    
                </div>
                <div className='form-row'>
                <label className="content">Show Post count</label>
                <div className='input'>
                  <label className="check">
                <input
          type="checkbox"
          checked={isCheckedshow}
          onChange={handleCheckboxChangeshow}
          style={{ transform: 'scale(1.5)', marginRight:'5px',marginTop:'10px',marginLeft:'130px' }}
        />
        </label>
        <span classname="helper" style={{ fontStyle:"light" }}>Show how many posts created by user</span>
        </div>
                </div>
                <div className='form-row'>
                <label className="content">Show featured Images</label>
                <div className='input '>
                <label className="check">
                <input
          type="checkbox"
          checked={isCheckedshowimg}
          onChange={handleCheckboxChangeshowimg}
         style={{ transform: 'scale(1.5)',marginRight:'5px',marginTop:'10px',marginLeft:'85px' }}
        />
        </label>
 <span style={{ fontStyle:"light" }}>Show how many posts created by user</span>
    
                </div>
                </div>
                <div className='form-row'>
                <label className="content">Featured images Size</label>
                <div className='input'>
                <select className="selectlast" value={selectedOptionimg} onChange={handleSelectChangeimg}
              
                style={{marginTop:'10px',marginLeft:'130%'}}>
        <option value="option1">Thumbnail</option>
        <option value="option2">Others</option>
        </select>
        </div>
        </div>
        </div>}
       
          {activeButton === 2 && <div>
          <div className='form-row'>
              <label className="content">Admin can edit post ?</label>
              <div className='input helper'>
              <select className="select" value={selectedOption} onChange={handleSelectChange}
              style={{ marginTop:'10px',marginLeft:'32%' }}>
        <option value="option1">Yes</option>
        <option value="option2">No</option>
        </select>
        <p classname="helper" style={{ color:'grey', margin:'0 0 0 130px',fontStyle:'italic' }}>Admin will be able to edit their own posts</p>
                
                <br></br>
                </div>
                </div>
                <div className='form-row'>
                <label className="content">Admin can delete post ?</label>
                <div className='input helper'>
                <select className="selectdel" value={selectedOptiondel} onChange={handleSelectChangedel}
                style={{marginTop:'10px', marginLeft:'28%' }}>
        <option value="option1">Yes</option>
        <option value="option2">No</option>
        </select>
        <p classname="helper" style={{ color:'grey',margin:'0 0 0 120px',fontStyle:'italic' }}>Admin will be able to delete their own posts</p>
                     <br></br>
                     </div>
                     </div>
          </div>}
          {activeButton === 3 && <div>
            
            <div style={{display:"flex",float:"right"}}>
                <Button  style={{
          width: "100px", height: "30px", color: " #FFFFFF", 
          background:  '#84240B', textTransform: "none",
         fontweight: 500, fontSize: "16px",paddingLeft:"0",marginRight:"10px"
        }}>Edit</Button>
        </div>
          <div className='form-row'>
              <label style={{fontSize:"20px", marginLeft:"0"}} className="content">Set up the charges applicable on the platform.</label></div>
            
              <div className='form-row'>
                <label style={{fontSize:"20px"}}className="content">Platform Fee:</label>
                <div className='input'>
                <input className=" text helper" type="text" value="10%" style={{ textAlign:'center', height:"30px", width:"110px",marginTop:'10px',marginLeft: '230%'}}></input>
                </div>
                </div>
                <div className='form-row'>
                <label style={{fontSize:"20px"}}className="content">Shipping Fee:</label>
                <div className='input'>
                <input className=" text helper" type="text" value="Rs.60" style={{ textAlign:'center', height:"30px", width:"110px",marginTop:'10px',marginLeft: '230%'}}></input>
                </div>
                </div>
                <div className='form-row'>
                <label style={{fontSize:"20px"}}className="content">Collection Fee</label>
                <div className='input'>
                <input className=" text helper" type="text" value="4%" style={{ textAlign:'center',height:"30px", width:"110px", marginTop:'10px',marginLeft: '225%'}}></input>
                </div>
                </div>
                <div className='form-row'>
                <label style={{fontWeight:200,fontSize:"20px"}}className="content">Payment Gateway Fee</label>
                <div className='input'>
                <input className=" text helper" type="text" value="2%" style={{ textAlign:'center', height:"30px", width:"110px",marginTop:'10px',marginLeft: '155%'}}></input>
                </div>
                </div>
                
                <div style={{display:"flex",float:"right"}}>
                  
                <Button  style={{
          width: "150px", height: "30px", color: " #FFFFFF", 
          background:  '#84240B', textTransform: "none",marginTop:"40%",
         fontweight: 500, fontSize: "16px",paddingLeft:"0",marginRight:"10px"
        }}>Cancel</Button>
        <Button className='btn' onClick={handleSaveClick} style={{
          width: "150px", height: "30px", color: " #FFFFFF", marginTop:"40%", background: active? '#0EBA70' : '#84240B',
                textTransform: "none",  fontweight: 500, fontSize: "16px"
                        }}>Save
                </Button>
        </div>
          </div>
          }
                           </div>
      
  )
}

export default Adminsettings;