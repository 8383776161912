import React, { useState, useEffect } from 'react'
import { useStyles } from "../BodyStyles";
import { Box, Card, FormControl, Grid, InputLabel, NativeSelect, TextField, Typography, inputLabelClasses, MenuItem, Select, AlertTitle, Alert } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../theme';
import { PageHeader } from '../../common/CommonComponent';
import img from '../../assets/img1.png';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { Button } from '@material-ui/core';
import api from "../../api.json";
var selectedGroupName = '';
var selectedGroupId = '';
var selectedCategorysubName = '';
var selectedCategorysubId = '';
var selectedCategoryName = '';
var selectedCategoryId = '';
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(0),

    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        minHeight: 5,
        border: '1px solid gray',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: 'gray',

        },
    },
}));
const useStyles1 = makeStyles((theme) => ({
    root: {
        background: "#e4f8ef",
        height: "100vh",
        display: "flex",
        marginLeft: "-20px",
        marginRight: "-20px",
        justifyContent: "center", textAlign: "center",


        [theme.breakpoints.down("sm")]: {
            background: "#e4f8ef"
        },
    },

    section: {
        margin: theme.spacing(3, 0),
        background: "#e4f8ef",
        marginLeft: "-20px",
        marginRight: "-20px",
        marginTop: "20px",
        marginBottom: "-20px",
        height: "140vh",
        [theme.breakpoints.down("sm")]: {
            height: "190vh"
        },

    },
    goback: {
        marginTop: theme.spacing(8),
        marginLeft: theme.spacing(2),
        width: "102px",
        height: "21px",
        textDecoration: "underline solid black 1px",
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(11),
            marginLeft: theme.spacing(2)
        },
    },
    header2: {
        marginTop: theme.spacing(-1),
        marginLeft: theme.spacing(2),
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 500,
        fontStyle: "normal",
        letterSpacing: "0.001em",
        lineHeight: "30px",
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(-2),
        },
    },
    label: {
        textAlign: "center",
        justifyContent: "center",
        marginTop: theme.spacing(2),
        display: "block",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 500,
        fontStyle: "normal",
        letterSpacing: "0.001em",
        lineHeight: "30px",
    },
    label2: {
        textAlign: "center",
        justifyContent: "center",
        marginTop: theme.spacing(2),
        display: "block",
        fontFamily: "Poppins",
        background: "#FFFFFF",
        fontSize: "20px",
        width: "86%",
        marginLeft: "80px",
        padding: "10px",
        fontWeight: 500,
        fontStyle: "normal",
        letterSpacing: "0.001em",
        lineHeight: "30px",
        border: "0.2px solid #000000",
        [theme.breakpoints.down("sm")]: {
            width: "70%",
            marginLeft: theme.spacing(6)

        },
    },
    text1: {
        textAlign: "start", display: "flex",
        margin: "6px", marginBottom: "3px",

        '& .MuiInputBase-input': {
            height: '8px',
        },
    },
    box1: {
        textAlign: "center",
        justifyContent: "center",

        display: "block",
        fontFamily: "Poppins",
        background: "#FFFFFF",
        fontSize: "20px",
        width: "470px",

        height: "346px",
        padding: "10px",
        fontWeight: 500,
        fontStyle: "normal",
        letterSpacing: "0.001em",
        lineHeight: "30px",
        border: "0.1px solid #000000",
        marginLeft: "63px",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "23px",

            width: "260px"

        },
    },
    box2: {
        textAlign: "center",
        justifyContent: "center",

        display: "block",
        fontFamily: "Poppins",
        background: "#FFFFFF",
        fontSize: "20px",
        width: "470px",

        height: "342px",
        padding: "10px",
        fontWeight: 500,
        fontStyle: "normal",
        letterSpacing: "0.001em",
        lineHeight: "30px",
        border: "0.1px solid #000000",
        marginRight: "63px",
      
        [theme.breakpoints.down("sm")]: {
            marginLeft: "69px",

            width: "260px"

        },
    },
    button1: {
        background: " #0EBA70",
        border: "0.2px solid #00000",margin:"5px",
         height: "50px", color: "#FFFFFF", fontWeight: 700, fontSize: "20px", fontStyle: "normal",
        fontFamily: "Poppins",
    },
    grid2: {
        marginTop: "40px", display: "flex",
        justifyContent: "space-around"

    },
    typo: {
        fontStyle: "normal",
        letterSpacing: "0.001em",
        lineHeight: "30px",
        fontFamily: "Poppins",
        marginTop: "30px"
    },
    breadcrum2: {
        color: "black",
        marginTop: theme.spacing(3),
        //  margin: theme.spacing(4, 0),

        marginLeft: theme.spacing(2),
        fontFamily: 'Poppins',
        textDecoration: "underline solid 1px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "21px",
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(5.5),

        },

    },
}));

const Createcategory = () => {
    const classes = useStyles();
    const classes1 = useStyles1();
    const [age, setAge] = React.useState('');
    const [disable, setDisable] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [disable1, setDisable1] = useState(0);
    const [isActive1, setIsActive1] = useState(false);
    const [disable2, setDisable2] = useState(0);
    const [isActive2, setIsActive2] = useState(false);
    const [message, setMessage] = useState("");
    const [name, setName] = useState("");
    const [name1, setName1] = useState("");
    const [name2, setName2] = useState("");
    const [dname, setDname] = useState("");
    const [subclsification, setSubclasification] = useState("");
    const [categoryname, setCategoryname] = useState('');
    const [contact, setContact] = useState([])
    const history = useHistory();

    const [subcategory, setSubcategory] = useState("")
    const [error, setError] = useState({

        status: false,

        msg: "",
        type: ""
    })

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const [cate, setCate] = useState([]);
    const [subcate, setSubcate] = useState([]);
    const [subcls, setSubcls] = useState([]);
    const [dropdownId, setDropdownId] = useState('');
    const [dropdownName, setDropdownName] = useState('');
    const successIcon = <CheckCircleIcon className="success-icon" />;
    const iconMapping = {
      success: successIcon,
    };
    const errorIcon = <ErrorIcon className="error-icon" />;
    const iconMapping3 = {
      error: errorIcon,
    };
    const [updateStatus, setUpdateStatus] = useState(null);
    const [updateStatus1, setUpdateStatus1] = useState(null);
    const [updateStatus2, setUpdateStatus2] = useState(null);
    const [updateStatus3, setUpdateStatus3] = useState(null);
    const imageclick = ()=>{
        setUpdateStatus3("failed");
        setTimeout(() => {
          setUpdateStatus3(false);
         
        }, 3000);
    }
    const [imageUrl, setImageUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
    
        reader.onload = () => {
          setImageUrl(reader.result);
        };
    
        if (file) {
          reader.readAsDataURL(file);
          setFileName(file.name);
        }
      };
      //upload api
      const [selectedImage, setSelectedImage] = useState(null);
      const [data,setData]=useState("")
      const [fileUrl, setFileUrl] = useState('');
      const [selectedImages, setSelectedImages] = useState([]);
      const [imagesList, setImagesList] = useState([]);
      const [fileup, setFileup] = useState(null);
      const handleImageClick = () => {
        // if (selectedImages.length > 1) {

        //   setErrorMessage('Cannot upload more than 4 images');
        //   setTimeout(() => {
        //     setErrorMessage('');
        //   }, 3000);
        //       setFileup("failed");
        //       setTimeout(() => {
        //         setErrorMessage('');
        //       }, 5000);
        //       setTimeout(() => {
        //         setFileup('');
        //       }, 5000);
        //   }
        //   else{
        document.getElementById('fileInput').click();
        //  }
      };
     // const [img, setImg] = useState(null); // Initialize with null


     
      const [errorMessage, setErrorMessage] = useState('');
      const[cated,setCated]=useState([])
      const selectedfilepath="";


      const handleFileChange = (event) => {
        const file = event.target.files[0];
        const imageUrlpath = URL.createObjectURL(file);
       // uploadImagenew(file)
        if (selectedImages.length < 1) {
          setSelectedImages(prevImages => [...prevImages, imageUrlpath]);
          setImagesList(prevList => [...prevList, file]);
          setErrorMessage(''); // Clear any previous error message
        } else {
          setErrorMessage('Cannot upload more than 1 image');
          setTimeout(() => {
            setErrorMessage('');
          }, 3000);
        }
        
      };

      const handleRemoveImage = (index) => {
        setSelectedImages(prevImages => prevImages.filter((_, i) => i !== index));
      };
      const uploadImagenew = async (e) => {
        e.preventDefault();
        try {
          const formData = new FormData();
          for (const file of imagesList) {
            console.log("filename",file)
            
            formData.append('file', file);
           
         }
         formData.append('name', dname);
        
        const res = await axios.post(api.baseUrl + '/admin/createcategory', formData);
       // console.log(res);
        // setIsLoading(false);
        if(res.status === 200){
          setDname("")
          setUpdateStatus("success");
          
          getcategory()
          setTimeout(() => {
            setUpdateStatus(false);
            //history.push("/Categorylist")
          }, 5000);
        } 
        
          // Display the alert message after all API calls are successful
         // alert("API calls completed successfully");
        } catch (error) 
        {
          console.error(error);
         // setIsLoading(false);
        }
      };
    let Createcategory = async (e) => {


        e.preventDefault();
        let formIsValid = true;
        let newErrors = {};
        if (!dname) {
            newErrors.dname = "*Please enter Name";
            formIsValid = false;
        }

        if (!formIsValid) {
            setError(newErrors);
            return;
        }
        console.log("createcategory--->", {

            name: dname,

        })
        setDisable(true);
        setIsActive(true);
        try {
            let formData = new FormData();
            formData.append('name', dname);
      
            let res = await fetch(api.baseUrl + '/admin/createcategory', {
              method: 'POST',
              body: formData,
            });
            let resJson = await res.json();
            if (res.status === 200) {
                setDisable(false);
                setIsActive(false);
                setDname("")
                getcategory()
                setMessage("User created successfully");
                setUpdateStatus("success");
                setTimeout(() => {
                  setUpdateStatus(false);
                  history.push("/Categorylist")
                }, 5000);

            } else {
                setMessage("Some error occured");
                setUpdateStatus("failed");
                setTimeout(() => {
                  setUpdateStatus(false);
                 
                }, 5000);
            }
        } catch (err) {
            console.log(err);
            setDisable(false);
            setIsActive(false);
        }
    };
    let Createsubcategory = async (e) => {


        e.preventDefault();
        let formIsValid = true;
        let newErrors = {};
        if (!name) {
            newErrors.dname = "*Please enter Name";
            formIsValid = false;
        }

        if (!formIsValid) {
            setError(newErrors);
            return;
        }
        console.log("createcategory--->", {

            name: selectedGroupName,
            category_id: selectedGroupId,
            category_name: categoryname

        })
        setDisable1(true);
        setIsActive1(true);
        try {
            let formData2 = new FormData();
            formData2.append('sub_category_name', categoryname);
            formData2.append('category_id', selectedGroupId);
            formData2.append('category_name', selectedGroupName);
            let res = await fetch(api.baseUrl + "/admin/createsubcategory", {
          
            method: 'POST',
            body: formData2,
          });
            let resJson = await res.json();
            if (res.status === 200) {
                setDisable1(false);
                setIsActive1(false);
                setName("")
                setCategoryname("");
                getsubcategory()
                setMessage("User created successfully");
                setUpdateStatus1("success");
                setTimeout(() => {
                  setUpdateStatus1(false);
                 
                }, 5000);

            } else {
                setMessage("Some error occured");
                setUpdateStatus1("failed");
                setTimeout(() => {
                  setUpdateStatus1(false);
                 
                }, 5000);
            }
        } catch (err) {
            console.log(err);
            setDisable1(false);
            setIsActive1(false);
        }
    };
    let Createsubclassification = async (e) => {


        e.preventDefault();
        {/*let formIsValid = true;
        let newErrors = {};
        if (!name) {
            newErrors.dname = "*Please enter Name";
            formIsValid = false;
        }

        if (!formIsValid) {
            setError(newErrors);
            return;
        }*/}
        console.log("createsubclasification--->", {



            sub_classification_name: subclsification,
            category_id: selectedCategoryId,
            category_name: selectedCategoryName,
            sub_category_id: selectedCategorysubId,
            sub_category_name: selectedCategorysubName
        })
        setDisable2(true);
        setIsActive2(true);
        try {
            let formData3 = new FormData();
            formData3.append('sub_classification_name',subclsification);
            formData3.append('category_id', selectedCategoryId);
            formData3.append('category_name', selectedCategoryName);
            formData3.append('sub_category_id', selectedCategorysubId);
            formData3.append('sub_category_name', selectedCategorysubName);
            let res = await fetch(api.baseUrl + "/admin/createsubclassification", {
                method: "POST",
body:formData3

            })
            let resJson = await res.json();
            if (res.status === 200) {
                setDisable2(false);
                setIsActive2(false);
                setName("")
                setName1("")
                setName2("")
                setCategoryname("");
                getsubcategory()
                getsubcategory1()
                setSubclasification("")
                setMessage("User created successfully");
                setUpdateStatus2("success");
                setTimeout(() => {
                  setUpdateStatus2(false);
                 
                }, 5000);
                //history.push('/allemployees')

            } else {
                setMessage("Some error occured");
                setUpdateStatus2("failed");
                setTimeout(() => {
                  setUpdateStatus2(false);
                 
                }, 5000);
            }
        } catch (err) {
            console.log(err);
            setDisable2(false);
            setIsActive2(false);
        }
    };
    const getcategory = () => {
        axios
            .get(api.baseUrl + "/admin/getcategory")
            .then((response) => {

                setCate(response.data.data);
                console.log("ghghhf67----->", response.data)



            })
            .catch((err) => {
                console.log("error", err);
            });

    }
    const [sv, setSv] = useState([])
    const getsubcategory = () => {
        axios
            .get(api.baseUrl + "/admin/getsubcategory")
            .then((response) => {

                setSubcate(response.data.data);
                // setSv(response.data.data)
                console.log("ghghhf67----->", response.data)



            })
            .catch((err) => {
                console.log("error", err);
            });

    }
    const getsubcategory1 = () => {
        axios
            .get(api.baseUrl + "/admin/getsubcategory")
            .then((response) => {

                //setSubcate(response.data.data);
                 setSv(response.data.data)
                console.log("ghghhf67----->", response.data)



            })
            .catch((err) => {
                console.log("error", err);
            });

    }



    let getpriceGroupValue = async (pos) => {
        setDropdownId(cate[(pos - 1)]._id);
        setDropdownName(cate[(pos - 1)].name);
        selectedGroupName = cate[(pos - 1)].name;
        selectedGroupId = cate[(pos - 1)]._id;
        // selectedproductId = data[(pos - 1)]._id;
        //selectedproductsalesId = data[(pos - 1)].sales_id;


        setName(selectedGroupName);
        console.log("hello", selectedGroupName, selectedGroupId)
    }
    let getpriceGroupValue1 = async (pos) => {
        setDropdownId(cate[(pos - 1)]._id);
        setDropdownName(cate[(pos - 1)].name);
        selectedCategoryName = cate[(pos - 1)].name;
        selectedCategoryId = cate[(pos - 1)]._id;
        // selectedproductId = data[(pos - 1)]._id;
        //selectedproductsalesId = data[(pos - 1)].sales_id;


        setName1(selectedCategoryName);
        console.log("hello", selectedCategoryName, selectedCategoryId)
    }
    let getsubcategoryclasification = async (pos) => {
        setDropdownId(sv[(pos - 1)].category_id);
        setDropdownName(sv[(pos - 1)].category_name);
        selectedCategoryName= sv[(pos - 1)].category_name;
        selectedCategoryId = sv[(pos - 1)].category_id;
        setName1(selectedCategoryName);
        console.log("hellovalue123", selectedCategoryName, selectedCategoryId)
       
        console.log("hellosubcategoryvalkhgjkhkjh", selectedCategoryName, selectedCategoryId)

    }
    let getsubcategoryValue = async (pos) => {
        setDropdownId(subcate[(pos - 1)]._id);
        setDropdownName(subcate[(pos - 1)].sub_category_name);
        selectedCategorysubName = subcate[(pos - 1)].sub_category_name;
        selectedCategorysubId = subcate[(pos - 1)]._id
setName2(selectedCategorysubName);
        console.log("hellosubcategory", selectedCategorysubName, selectedCategorysubId)

    }
   
    {/*let getpriceGroupValue1 = async (pos) => {
        setDropdownId(sv[(pos - 1)].category_id);
        setDropdownName(sv[(pos - 1)].category_name);
        selectedCategoryName= sv[(pos - 1)].category_name;
        selectedCategoryId = sv[(pos - 1)].category_id;
        setName1(selectedCategoryName);
        console.log("hellovalue123", selectedCategoryName, selectedCategoryId)

    }
    let getsubcategoryValue = async (pos) => {
        setDropdownId(subcate[(pos - 1)]._id);
        setDropdownName(subcate[(pos - 1)].sub_category_name);
        selectedCategorysubName = subcate[(pos - 1)].sub_category_name;
        selectedCategorysubId = subcate[(pos - 1)]._id



        setName2(selectedCategorysubName);
        console.log("hellosubcategory", selectedCategoryName, selectedCategoryId)

    }*/}
    useEffect(() => {
        getcategory()
        getsubcategory()
        getsubcategory1()

    }, []);


    return (
        <div >
              <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'}}>
             {updateStatus === "success" && (
          <Alert iconMapping={iconMapping} severity="success"
            onClose={() => setUpdateStatus(null)}
            style={{
              background: "green", color: "white", marginBottom: theme.spacing(2), marginTop:"40px",position: "fixed", zIndex: 9999, [theme.breakpoints.down("xs")]: {
                textAlign: 'center',
                width: "95%", marginBottom: theme.spacing(2)
              },
            }}>
            <AlertTitle> Category Added successfully </AlertTitle>
          </Alert>
        )}
        {updateStatus === "failed" && ( 
          <Alert iconMapping={iconMapping} severity="error" onClose={() => setUpdateStatus(null)}
            style={{
              background: "red", color: "white", marginBottom: theme.spacing(2), marginTop:"40px",position: "fixed", zIndex: 9999, [theme.breakpoints.down("xs")]: {
                textAlign: 'center',
                width: "90%",
              },
            }}>
            <AlertTitle> Failed to Add Category</AlertTitle>
          </Alert>
        )}
        {/*2nd one */}
        {updateStatus1 === "success" && (
          <Alert iconMapping={iconMapping} severity="success"
            onClose={() => setUpdateStatus1(null)}
            style={{
              background: "green", color: "white", marginBottom: theme.spacing(2), marginTop:"40px",position: "fixed", zIndex: 9999, [theme.breakpoints.down("xs")]: {
                textAlign: 'center',
                width: "95%", marginBottom: theme.spacing(2)
              },
            }}>
            <AlertTitle> SubCategory Added successfully </AlertTitle>
          </Alert>
        )}
        {updateStatus1 === "failed" && ( 
          <Alert iconMapping={iconMapping} severity="error" onClose={() => setUpdateStatus1(null)}
            style={{
              background: "red", color: "white", marginBottom: theme.spacing(2), marginTop:"40px",position: "fixed", zIndex: 9999, [theme.breakpoints.down("xs")]: {
                textAlign: 'center',
                width: "90%",
              },
            }}>
            <AlertTitle> Failed to Add SubCategory</AlertTitle>
          </Alert>
        )}
         {/*3rd one */}
         {updateStatus2 === "success" && (
          <Alert iconMapping={iconMapping} severity="success"
            onClose={() => setUpdateStatus2(null)}
            style={{
              background: "green", color: "white", marginBottom: theme.spacing(2), marginTop:"40px",position: "fixed", zIndex: 9999, [theme.breakpoints.down("xs")]: {
                textAlign: 'center',
                width: "95%", marginBottom: theme.spacing(2)
              },
            }}>
            <AlertTitle> SubClassification Added successfully </AlertTitle>
          </Alert>
        )}
        {updateStatus2 === "failed" && ( 
          <Alert iconMapping={iconMapping} severity="error" onClose={() => setUpdateStatus2(null)}
            style={{
              background: "red", color: "white", marginBottom: theme.spacing(2), marginTop:"40px",position: "fixed", zIndex: 9999, [theme.breakpoints.down("xs")]: {
                textAlign: 'center',
                width: "90%",
              },
            }}>
            <AlertTitle> Failed to Add  SubClassification</AlertTitle>
          </Alert>
        )}
        {/* error*/}
        {updateStatus3 === "failed" && (
          <Alert iconMapping={iconMapping} severity="success"
            onClose={() => setUpdateStatus3(null)}
            style={{
              background: "green", color: "white", marginBottom: theme.spacing(2), marginTop:"40px",position: "fixed", zIndex: 9999, [theme.breakpoints.down("xs")]: {
                textAlign: 'center',
                width: "95%", marginBottom: theme.spacing(2)
              },
            }}>
            <AlertTitle>Coming soon </AlertTitle>
          </Alert>
        )}
      </div>
            <Box className={classes1.section}>
                <br />
                {/*   <div className={classes1.breadcrum2}>
                    <Typography>Go Back</Typography>
    </div>*/}
                <div className={classes1.header2}>
                    <p>CATEGORIES</p>
                </div>
                <div className={classes1.label}>ADD CATEGORIES</div>

                <div className={classes1.label2}>
                    <form onSubmit={uploadImagenew}>
                    <Grid container spacing={2}
                    style={{ display: "flex"}} >
                    <Grid item>
                    <Typography style={{
                                    fontStyle: "normal",
                                    letterSpacing: "0.001em",
                                    lineHeight: "30px",
                                    fontFamily: "Poppins",
                                    marginLeft:"10px",
                                             marginTop:"15px",                                                     
                                     fontSize: "20px", textAlign: "start",
                    }}>Enter Category</Typography>
                        
                        </Grid>
                        <Grid item>
                        <Typography style={{
                                    fontStyle: "normal",
                                    letterSpacing: "0.001em",
                                    lineHeight: "30px",
                                    fontFamily: "Poppins",
                                    float:"right",
                                    marginLeft:"595px",
                                             marginTop:"15px",                                                     
                                     fontSize: "20px", textAlign: "start",
                                }}>Upload Image</Typography>
                        </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                        <Grid item>
                        <TextField placeholder='Enter Category' value={dname}
                            onChange={(e) => setDname(e.target.value)} className={classes1.text1}
                                style={{float: "left", marginLeft: "8px",
                                    borderRadius: 0,
                                    width:"300%",
                            
                                
                            }} /></Grid>
                            <Grid item>
                            < Box
             
             display="flex"
             justifyContent="center"
             marginLeft={72}
             alignItems="center"
             textAlign="center"
               
           
           >     
       
       <div>
        
        <div style={{ display: "flex" }}>
      
        <img
          src={img}
          alt="Selected"
          onClick={handleImageClick}
          style={{ cursor: 'pointer', width: '100px', height: '100px', borderRadius: '12px' }}
        />
   
  {selectedImages.map((image, index) => (
    <div key={index} style={{ position: 'relative', marginRight: "10px" }}>
       
       
      <img
        src={image}
        alt={`Selected ${index}`}
        style={{ width: "100px", height: "100px",borderRadius:"12px" }}
      />
      
      <button
        onClick={() => handleRemoveImage(index)}
        style={{
          position: 'absolute',
          top: '0px',
          right: '-1px',
          backgroundColor: '#0EBA70',
          border: 'none',
          cursor: 'pointer',
          color: 'white',
          borderRadius:"40px"
        }}
      >
        <ClearIcon />
      </button>
    </div>
  ))}
</div>

  {errorMessage && <p style={{color:"red"}}>{errorMessage}</p>}
</div>
<input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} />
   
       
                                 </Box>
                            </Grid>
</Grid>
<Grid container>
                        <Grid item>
                        <Button type='submit' className={classes1.button1} style={{
                            borderRadius: 0, marginTop: "0px", opacity: isActive ? '60%' : '',
                            color: isActive ? '' : '',
                            backgroundColor: "#0EBA70", color: "#FFFFFF", width:"368%",marginLeft: "8px"
                        }} disabled={disable}>ADD CATEGORY</Button>
                        </Grid>
                        </Grid>
                    </form>
                </div>
                <Grid container spacing={2}
                    style={{ display: "flex", justifyContent: "space-around", marginTop: "50px", }} >
                    <Grid item>
                        <Typography style={{
                            marginLeft: theme.spacing(10), fontFamily: "Poppins",

                            fontSize: "20px",
                        }}>ADD SUB - CATEGORIES</Typography>
                        <form onSubmit={Createsubcategory}>
                            <div className={classes1.box1} style={{
                                display: "flex",
                                justifyContent: "start", flexDirection: "column"
                            }} >

                                <Typography style={{
                                    fontFamily: "Poppins",marginTop:"20px",

                                    fontSize: "20px", width: "190px", marginLeft: "-5px"
                                }}>Choose Category</Typography>
                                <FormControl style={{ marginTop: "10px" }}>
                                <NativeSelect
  id="select"
  label="Role"
  fullWidth
  InputLabelProps={{
    sx: {
      color: "#590F72",
      margin: theme.spacing(2),
      border: "black",
      [`&.${inputLabelClasses.shrink}`]: {
        color: "#590F72",
        fontWeight: 600,
        fontSize: 16,
        border: "black",
      },
    },
  }}
  value={name}
  onChange={(e) => getpriceGroupValue(e.target.options.selectedIndex)}
  className={classes.select}
  input={<BootstrapInput />}
  // Specify the background color here
>
  <option value=""  >----- Choose Category -----</option>
  {cate.map((cat) => (
    <option key={cat._id} value={cat.name} >
      {cat.name}
    </option>
  ))}
</NativeSelect>

                                </FormControl>


                                <Typography style={{
                                    fontStyle: "normal",
                                    letterSpacing: "0.001em",
                                    lineHeight: "30px",
                                    fontFamily: "Poppins",marginTop:"30px",
                                   fontSize: "20px", textAlign: "start",
                                }}>Add Sub - Category</Typography>
                                <TextField placeholder='Enter Sub - Category'
                                 value={categoryname} onChange={(e) => setCategoryname(e.target.value)} 
                                InputProps={{
                                    style: {
                                        borderRadius: 0,
                                        height: '41px',
                                    },
                                }} />
                            {/*}    <Typography style={{
                                    fontStyle: "normal",
                                    letterSpacing: "0.001em",
                                    lineHeight: "30px",
                                    fontFamily: "Poppins",
                                     fontSize: "20px", textAlign: "start",
                                }}>Upload Image</Typography>
                               < Box
             
             display="flex"
             justifyContent="center"
             marginLeft={5}
             alignItems="center"
             height={80}
             width={80}
             bgcolor="#0EBA70"
             borderRadius={100}        
           
           >
                                 <img src={img} alt="Default" style={{height:"30px",width:"30px"}}/>
                            </Box>*/}
                                <Button type='submit' className={classes1.button1} fullWidth style={{
                                    borderRadius: 0, marginTop: "40px", opacity: isActive1 ? '60%' : '',
                                    color: isActive1 ? '' : '',
                                    backgroundColor: "#0EBA70", color: "#FFFFFF"
                                }} disabled={disable1}>ADD SUB - CATEGORY</Button>
                              
                            </div>
                        </form>
                    </Grid>
                    
                    <Grid item>
                        
                        <Typography style={{
                            marginLeft: theme.spacing(10), fontFamily: "Poppins",

                            fontSize: "20px",
                        }}>ADD SUB - CLASSIFICATION</Typography>
                        <form onSubmit={Createsubclassification}>
                            <div className={classes1.box2} style={{
                                display: "flex",
                                justifyContent: "start", flexDirection: "column"
                            }} >

                                <Typography style={{
                                    fontFamily: "Poppins",

                                    fontSize: "20px", width: "190px", marginLeft: "-5px"
                                }}>Choose Category</Typography>
                                <FormControl style={{ marginTop: "3px" }}>
                                    <InputLabel id="demo-simple-select-label" style={{ marginTop: "-5px" }}></InputLabel>
                                    <NativeSelect id="select" label="Role" fullWidth
                                        InputLabelProps={{
                                            sx: {
                                                color: "#590F72",
                                                margin: theme.spacing(2),
                                                border: "black",
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    color: "#590F72",
                                                    fontWeight: 600,
                                                    fontSize: 16,
                                                    border: "black",
                                                }
                                            }
                                        }}
                                        value={name1} onChange={(e) => getpriceGroupValue1(e.target.options.selectedIndex)}
                                        className={classes.select}
                                        input={<BootstrapInput />}
                                    >
                                        <option value="">----- Choose Category -----</option>
                                        {cate.map((cat) => (
                                            <option key={cat._id} value={cat.name}>{cat.name}</option>
                                        ))}

                                    </NativeSelect>
                                </FormControl>
                                <Typography style={{
                                    fontFamily: "Poppins",

                                    fontSize: "20px", width: "260px", marginLeft: "-5px"
                                }}>Choose Sub - Category</Typography>
                                <FormControl style={{ marginTop: "3px" }}>
                                    <InputLabel id="demo-simple-select-label" style={{ marginTop: "-5px" }}></InputLabel>
                                    <NativeSelect id="select" label="Role" fullWidth
                                        InputLabelProps={{
                                            sx: {
                                                color: "#590F72",
                                                margin: theme.spacing(2),
                                                border: "black",
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    color: "#590F72",
                                                    fontWeight: 600,
                                                    fontSize: 16,
                                                    border: "black",
                                                }
                                            }
                                        }}
                                        value={name2} onChange={(e) => getsubcategoryValue(e.target.options.selectedIndex)}
                                        className={classes.select}
                                        input={<BootstrapInput />}
                                    >
                                        <option value="">----- Choose Category -----</option>
                                        {subcate.map((cat) => (
                                            <option key={cat._id} value={cat.sub_category_name}>{cat.sub_category_name}</option>
                                        ))}

                                    </NativeSelect>

                                </FormControl>

                                <Grid container spacing={2}
                    style={{ display: "flex"}} >
                    <Grid item>
                        
                                <Typography style={{
                                    fontStyle: "normal",
                                    letterSpacing: "0.001em",
                                    lineHeight: "30px",
                                    fontFamily: "Poppins",
                                    fontSize: "20px", textAlign: "start",
                                }}>Add Sub - Classification</Typography>
                                </Grid>
                               {/* <Grid>
                                <Typography style={{
                                    fontStyle: "normal",
                                    letterSpacing: "0.001em",
                                    lineHeight: "30px",
                                    fontFamily: "Poppins",
                                    marginLeft:"35px",
                                             marginTop:"15px",                                                     
                                     fontSize: "20px", textAlign: "start",
                                }}>Upload Image</Typography>
                            </Grid>*/}
                                </Grid>
                                <Grid container spacing={2}
                    style={{ display: "flex"}} >
                    <Grid item>
                                 
                                 <TextField  placeholder='Enter Sub - Classification' value={subclsification}
                                    onChange={(e) => setSubclasification(e.target.value)} InputProps={{
                                        style: {
                                            borderRadius: 0,
                                            height: '41px',
                                            marginTop: "3px",width:"212%"
                                           
                                        },
                                    }} /> 
                                    
                                    </Grid>
                                     {/*   <Grid item>
                             < Box
             
             display="flex"
             justifyContent="center"
             marginLeft={10}
             alignItems="center"
             height={80}
             width={80}
             bgcolor="#0EBA70"
             borderRadius={100}        
           
           >
                                 <img src={img} alt="Default" style={{height:"30px",width:"30px"}}/>
                                 </Box> 
                                </Grid> */}
</Grid>
                                <Button type="submit" className={classes1.button1} fullWidth
                                    style={{
                                        borderRadius: 0, marginTop: "20px", opacity: isActive2 ? '60%' : '',
                                        color: isActive2 ? '' : '', marginBottom: "10px",
                                        backgroundColor: "#0EBA70", color: "#FFFFFF"
                                    }} disabled={disable2}>ADD SUB - CLASSIFICATION</Button>
                            </div>
                        </form>
                    </Grid>

                </Grid>



            </Box>
        </div>
    );
};


export default Createcategory