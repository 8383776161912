import React, { useState, useEffect } from 'react';
import { Box, Button, Paper,Grid, Typography } from "@material-ui/core";
import theme from "../../../theme";
import { useStyles } from "../BodyStyles";
import data from "./mockdata_notifications.json";
import { useHistory } from 'react-router-dom';
import { Alert, AlertTitle, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import reviewimg from "../../../Components/assets/reviewimg.png";
import createnoti from "../../../Components/assets/createnoti.png";
import axios from 'axios';
import api from "../../api.json";
import SearchIcon from "@material-ui/icons/Search";
import { saveAs } from 'file-saver';
import excelimg from "../../../Components/assets/excelimg.png";
import * as XLSX from 'xlsx';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
const Notifications = () => {
    const classes = useStyles();
    const [orders, setOrders] = useState(data);
    //const [count, setCount] = useState(data.length);  
    const [activeButton, setActiveButton] = useState("a");
    const [search, setSearch] = useState('');
    const [showOverlay, setShowOverlay] = useState(false);
    const[cate,setCate]=useState([])
    const history = useHistory()
    const [updateStatus, setUpdateStatus] = useState(null);
    const successIcon = <CheckCircleIcon className="success-icon" />;
    const iconMapping = {
      success: successIcon,
    };
    const errorIcon = <ErrorIcon className="error-icon" />;
    const iconMapping3 = {
      error: errorIcon,
    };
    const alert =() =>{
   history.push("/notificationsreview")
    }
    const[count,setCount]=useState('')
    const [pageNumber, setPageNumber] = useState(1);

    //const [hasNextPage, setHasNextPage] = useState(false);
    const handleNextClick = () => {
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      getNotification(nextPageNumber);
      console.log("paghgj",pageNumber)
    };
    const limit = 10;
    const handlePrevClick = () => {
      if (pageNumber > 1) {
        const prevPageNumber = pageNumber - 1;
        setPageNumber(prevPageNumber);
        getNotification(prevPageNumber);
        console.log("previous",pageNumber)
      }
    };
    const hasNextPage = cate.length === limit;
    const getNotification =(page)=>{
         
    const startRow = page;
    const endRow = page * limit;
    console.log("latest",limit,startRow,endRow)
      axios
      .get(api.baseUrl + "/admin/getnotification", {
        params: {
          page: startRow,
          limit: 10,
        
        }
      })
      .then((response) => {

        setCate(response.data.notifications);
        setCount(response.data.totalCount)
        localStorage.setItem("totalcount",response.data.totalCount)
      
       // console.log("category", cate,response.data)


      })
      .catch((err) => {
        console.log("error", err);
      });
    }

    useEffect(() => {
  
      getNotification(pageNumber);
        }, [pageNumber]);
   
        const requestSearch = (searchedVal) => {
          console.log("searchedVal", searchedVal)
          axios
            .get(api.baseUrl + "/admin/searchnotification/" + searchedVal)
            .then((response) => {
              setCate(response.data.data);
             
              //setContacts(response.data)
             // console.log("topup", response.data.notifications)
            })
            .catch((err) => {
              console.log("error", err);
            });
        };
               const createnotifications = () => {
            
               history.push("/Createnotifications")
                // Get the table data       
               // const filteredOrders = orders.filter((order) => order.status.toLowerCase().includes(search.toLowerCase()));
        
              };

              const exportToExcel = () => {
                console.log("on export")
               
                // Get the table data       
                const filteredOrders = orders.filter((order) => order.membership.toLowerCase().includes(search.toLowerCase()));
          
            
                // Define the header row
                const header = ["Message"];
            
            
                // Create the data array for the spreadsheet
                const data1 = [header, ...filteredOrders.map(({ message}) =>
                  [message])];
            
                // Create a new worksheet
                const ws = XLSX.utils.aoa_to_sheet(data1);
            
                // Create a new workbook
                const wb = XLSX.utils.book_new();
            
                // Add the worksheet to the workbook
                XLSX.utils.book_append_sheet(wb, ws, 'Table Data');
            
                // Create a binary string representation of the workbook
                const bin = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            
                // Create a Blob from the binary string
                const blob = new Blob([bin], { type: 'application/octet-stream' });
            
                // Save the file
                saveAs(blob, 'customers.xlsx');
          
              };
  return (
    <Box className={classes.section}>
    <Grid container justifyContent= "flex-end">
  
    <Grid item xs={12} sm={12}>
      <Typography variant='h6' className={classes.breadcrum}>
      <a  className={classes.breadcrum} href="/viewnotifications" > Go Back </a>
     
      </Typography>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',



      }}>
    {updateStatus === "success" && (
          <Alert iconMapping={iconMapping} severity="success"
            onClose={() => setUpdateStatus(null)}
            style={{
              background: "green", color: "white", marginBottom: theme.spacing(2), position: "fixed", zIndex: 9999, [theme.breakpoints.down("xs")]: {
                textAlign: 'center',
                width: "95%", marginBottom: theme.spacing(2)
              },
            }}>
            <AlertTitle> Coming Soon ...</AlertTitle>
          </Alert>
        )}
        </div>
      <div  style={{ display: "flex", justifyContent: "end", alignItems: "center", marginTop:"30px" }}>
      <div style={{ display: "flex", justifyContent: "center",marginRight:"10px" }}>
      <TextField name="search"
        placeholder="  Search"
        className={classes.rese}
        onChange={(e) => requestSearch(e.target.value)}
        id="standard-bare"
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
      />
    </div>
    <Button onClick={createnotifications} style={{ width: "200px",height: "45px", color: "#FFFFFF",float:"right", borderRadius: "5px", background: "#0EBA70", textTransform: "none",  marginRight: theme.spacing(1),fontStyle:"normal", borderRadius: "3", fontweight: 500, fontSize: "16px" }}>
            <td>
          <Box
            component="img"
            style={{
              marginLeft: 1,
              marginTop: 10,
            
              maxHeight: { xs: 2, md: 2 },
              maxWidth: { xs: 350, md: 250 },
            }}
            src={createnoti}
          />
        </td>
        Create Notifications</Button>
      </div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography variant='h5' className={classes.noti} >
         NOTIFICATIONS
        </Typography>     
                
        
     
     
              
              
      
      </Box>
      </Grid>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>

    <TableContainer >
    <Table stickyHeader aria-label="sticky table">
      <TableHead style={{}} >
        <TableRow sx={{
          "& th": {
            fontFamily: 'Poppins',
            fontStyle: "normal",
            fontWeight: "400",
            backgroundColor: "white",
            color: "black",
        
            fontSize: "18px",
            fontWeight: "500",         
            width:"1213px",
            borderRadius: "10px 10px 0px 0px",
            border: "0.25px solid #000000",
            
          
          }
        }}>

         
<TableCell>
  <Box sx={{ display: 'flex', width: "1100px",justifyContent: 'space-between',height:"10px",borderRadius:"20px" ,fontFamily: 'Poppins',fontStyle: "normal",fontWeight: "500",fontSize: "16px",color:"black"}}>
    <Typography variant="body1">Message</Typography>
  {/*  <Typography style={{marginRight:"50px"}} variant="body1">Action</Typography>*/}
  </Box>
</TableCell>

                 
              
        </TableRow>
      </TableHead>
      <TableBody>
     
  {cate.map((contact) =>

 

    <TableRow  key={contact.id} sx={{
      "& td": {
        fontSize: "16px",
        fontWeight: "400",
        fontStyle: "normal",
        alignItems: "center",
        height: "50px !important",
        width:"1213px !important",
        padding: "2px !important"

      }
    }}>
     
      
      <TableCell>
  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
  <Typography variant="body1">
  {contact.message}</Typography>
 {/* <Button onClick={alert} style={{ width: "200px",height: "35px", color: "#FFFFFF", borderRadius: "5px", background: "#0EBA70", textTransform: "none",   borderRadius: "3",fontStyle:"normal", fontweight: 500, fontSize: "16px" }}>
      <td>
          <Box
            component="img"
            style={{
              marginTop: "10px",
              textAlign:"center",
            
             
              marginLeft: theme.spacing(-5),
              maxHeight: { xs: 2, md: 2 },
              maxWidth: { xs: 350, md: 250 },
            }}
            src={reviewimg}
          />
          </td>Review </Button>*/}




  </Box>


      
      </TableCell>
     
     
      
    </TableRow>
  )}
</TableBody>

    </Table>
  </TableContainer>
  </Paper>


      </Grid>
      <Box style={{ textAlign: "end" }}>
      <Button onClick={handlePrevClick} style={{ color: "#590F72" }}>
        Prev
      </Button>
      <strong style={{ color: "blue", justifyContent: "space-between" }}>
        {pageNumber}
      </strong>
      <Button onClick={handleNextClick} style={{ color: "#590F72",opacity: !hasNextPage ? '60%' : '',
          color: !hasNextPage ? '' : '',}} disabled={!hasNextPage}>
        Next
      </Button>
    </Box>
      </Box>
  )
}
export default Notifications


    


