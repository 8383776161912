
import { Box, Button, Card, Grid, IconButton, InputAdornment,CircularProgress , inputLabelClasses, TextField, Typography, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import api from "./api.json"
import "./Login.css";
import { makeStyles } from '@material-ui/core/styles';
import logoagri from "./logoagri.png";
import theme from '../theme';
import FormGroup from '@mui/material/FormGroup';
import { Person, Eye, EyeSlashFill } from 'react-bootstrap-icons';
import FormControlLabel from '@mui/material/FormControlLabel';
import ContactIcon from '@material-ui/icons/Email';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Visibility, VisibilityOff } from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(242deg, #0EBA70 0%, #0EBA70 100%)",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      background: "#0EBA70",
      height: "auto",
    },
  },
 
  formContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.up("md")]: {
      width: 450,
      height: 540,
    },
  },
  formCard: {
    width: "100%",
    height: "100%",
    textAlign: "center",
    justifyContent: "center",

    [theme.breakpoints.up("md")]: {
      width: 450,
      height: 563,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '300px',
    padding: '15px',

    [theme.breakpoints.up("md")]: {
      marginTop: "10%",
    },
  },
  textField: {
    marginBottom: "1em",
    background: "#0EBA70",
    color: "white",
    border: "none",
    borderRadius: "10px",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      fontSize: "20px",
    },
  },
  submitButton: {
    backgroundColor: "#CAADD5",
    border: "#CAADD5",
    color: "#0EBA70",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  loadingIndicator: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}))


const Login = () => {
  const theme = useTheme();
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  


  console.log(theme)
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    subscribe: false
  })
  const [message, setMessage] = useState("");

  const [error, setError] = useState({
    status: false,

    msg: "",
    type: ""
  })
  const classes = useStyles();
  const history = useHistory();
  const handleInputChange = e => {
    const { name, value, type, checked } = e.target;
    setInputs({
      ...inputs,
      [name]: type === 'checkbox' ? checked : value
    });
  };
  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }
 

  




  let handleLogin = async (e) => {

    e.preventDefault();
    console.log("sol==>", {
      password: inputs.password,
      email: inputs.email,

    })

    setLoading(true);

    
    try {
      let res = await fetch(api.baseUrl + "/admin/login", {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: inputs.password,
          email: inputs.email,

        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        
        setMessage("User logged in successfully");
        setLoading(false);
        history.push('/dashboard')
        window.location.reload()
        localStorage.setItem('updated_by', resJson.data.email);
        console.log("kjhjgkjh",resJson.data.email)

      } else {
        setMessage("Some error occured");
        console.log("errrrrr", resJson.message)
        setLoading(false);
        setError({ status: true, message: resJson.message, type: 'error' });

      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.formContainer}>

      <Grid item>
          <Card className={classes.formCard}>

           

            <Grid
              container
              className={classes.form}
              spacing={2}
            >

              <Grid item xs="12">

                <form onSubmit={handleLogin}>

                  <div >   {error.status ? <div id='error'
                    style={{ color: "red", fontWeight: "bold", textAlign: "center" }} ><i class="bi bi-exclamation-circle" style={{ fontWeight: "bold" }}></i>&nbsp;&nbsp;&nbsp;&nbsp;{error.message}</div> : ''}
                  </div>

                  <TextField
                    placeholder="Enter Email ID or Phone Number or Username"
                    name="email"
                    fullWidth
                    value={inputs.email}
                    onChange={handleInputChange}
                    variant="outlined"
                    type="email"
                    style={{
                      marginBottom: "1em",
                      color: "white",
                      fontSize: "20px",
                      border: "none",
                      backgroundColor: "#e4f8ef",
                      marginTop:"7em",
                      borderRadius: "10px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" >
                          <Person style={{ color: 'black', width: '24px', height: '20px', marginLeft: theme.spacing(3) }} />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField

                    style={{
                      marginBottom: "0em",

                      color: "white",
                      border: "none",
                      backgroundColor: "#e4f8ef",
                      marginTop:"1em",
                      borderRadius: "10px",
                    }}
                    placeholder="Password"
                    fullWidth
                    name="password"
                    value={inputs.password}
                    onChange={handleInputChange}
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end"  >
                          <IconButton onClick={handleTogglePassword} edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
                        </InputAdornment>
                      ),
                    }}handleLogin

                  />

               
                  <Grid item xs="12" justifyContent="center" alignContent="center"
                    justify="center" container spacing={0}>
                    <Button
                      type="submit"
                      size="large" fullWidth
                      variant="contained"
                      style={{ backgroundColor: "#0EBA70",   marginTop:"3em",border: "#0EBA70", color: "white" }}
                    >
                      Login
                    </Button>
                    {loading ? <CircularProgress size={30} className={classes.loadingIndicator} /> : ""}
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Card>

        </Grid>

        <Grid item className={classes.imageContainer}>
        <Card style={{
              height: 563,
              width: 450,
              backgroundColor: "#e4f8ef",
              }}>
                <Box
            component="img"
            style={{
             
                marginLeft:150,
              marginTop: 100,
              maxHeight: { xs: 233, md: 267 },
              maxWidth: { xs: 350, md: 250 },
            }}
            src={logoagri}
          />
             <Typography style={{ textAlign: "start", color: "black", marginTop: "1em",marginLeft:"6em", fontWeight: "600" }}>WELCOME TO AGRIBREEDERS  </Typography>
          
             <Typography style={{ textAlign: "start", color: "black", marginTop: "1em",marginLeft:"8em", fontWeight: "600"}}>Login to Admin Panel </Typography>
         
        </Card>
        </Grid>
        
      </Grid>
    </div>



  )
}

export default Login