import React from "react";
import { Drawer, Hidden,Box,Typography} from "@material-ui/core";
import logoagri from "./logo.png";
import { useStyles } from "./HeaderStyles";
import SidenavData from "./SidenavData";
import { CenterFocusStrong } from "@material-ui/icons";

export default function Sidenav({
  mobileOpen,
  handleDrawerOpen,
  handleDrawerClose,
}) {
  const classes = useStyles();

  return (
    <nav className={classes.drawer} aria-label='mailbox folders'>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      
      
      
      <Hidden mdUp implementation='css'>
        <Drawer
          variant='temporary'
          anchor={"left"}
          open={mobileOpen}
          onClose={handleDrawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}>
            
         
          <SidenavData handleDrawerClose={handleDrawerClose} />
   
         
        </Drawer>
      </Hidden>
      <Hidden smDown implementation='css'>
     
        <Drawer
        
          classes={{
            paper: classes.drawerPaper,
          }}
          variant='permanent'
          open>
             <Box
            component="img"
            style={{
             width:150,
             
              marginLeft:15,
              marginTop: 5,
                maxHeight: { xs: 
                10, md: 12 },
              maxWidth: { xs: 2, md: 1 },
            }}
            src={logoagri}
          />
            <Typography variant='h6'  style={{
             width:150,marginLeft:15,}} className={classes.logo}>
                   
            {"Agri Breeders"}
                 </Typography>
          <SidenavData handleDrawerClose={handleDrawerClose} />
        </Drawer>
      </Hidden>
    </nav>
  );
}
