import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,Button
} from "@material-ui/core";
import LogoutIcon from '@mui/icons-material/Logout';
import products from '../Components/assets/products.png'
import offers from '../Components/assets/offers.png'
import logout from '../Components/assets/logout.png'
import settings from '../Components/assets/settings.png'
import dashboard from '../Components/assets/dashboard.png'
import notifications from '../Components/assets/notifications.png'
import customers from '../Components/assets/customers.png'
import categories from '../Components/assets/categories.png'
import reports from '../Components/assets/reports.png'

import ExpandLess from '@material-ui/icons/ExpandLess';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { NavLink, useHistory } from "react-router-dom";
import { useStyles } from "./HeaderStyles";
import { DialogActions } from "@mui/material";
export default function SidenavData({ handleDrawerClose }) {
  const classes = useStyles();
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isCustomersOpen, setIsCustomersOpen] = useState(false);
  const [isOffersOpen, setIsOffersOpen] = useState(false);
  const [isAdsOpen, setIsAdsOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
  const [isReportsOpen, setIsReportsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isDashboardOpen, setIsDashboardOpen] = useState(false);
 
  const superadmin = [
    {
      label: "Dashboard",

      icon: <img src={dashboard} alt="Dashboard" style={{ width: "30px" }} />,
      subMenus: [

    {
      label: "Dashboard",
      link: "/dashboard",

    },
  ],
  
  onClick: () => handleDropdownClick(setIsDashboardOpen)

},
{
  label: "Reports",

  icon: <img src={reports} alt="reports" style={{ width: "30px" }} />,
  subMenus: [

    {
      label: "Sales Reports",
      link: "/salesreports",

    },
    {
      label: "Payment Reports",
      link: "/paymentreports",

    },
    {
      label: "Order Reports",
      link: "/orderreports",

    },
    {
      label: "Shipment Reports",
      link: "/shipmentreports",

    },

  ],
  onClick: () => handleDropdownClick(setIsReportsOpen)

},
{
  label: "Products",
  icon:<img src={products} alt="Products" style={{ width: "30px" }} />,
  subMenus: [
    {
      label: "Add Products",
      link: "/addproducts",
    },
    {
      label: "Approved",
      link: "/approved",
    },
    {
      label: "Individual Products List",
      link: "/individualproductslist",
    },
    {
      label: "Declined",
      link: "/declined",
    },
  ],
  onClick: () => handleDropdownClick(setIsProductsOpen)
},
{
  label: "Customers",
  icon: <img src={customers} alt="Customers" style={{ width: "30px" }} />,
  subMenus: [
    {
      label: "Add Customers",
      link: "/addcustomers",
    },
    {
      label: "Admin Customers",
      link: "/admincustomers",
    },
    
  ],
  onClick: () => handleDropdownClick(setIsCustomersOpen)

},
{
  label: "Notifications",
  icon: <img src={notifications} alt="Notifications" style={{ width: "30px" }} />,
  subMenus: [
    {
      label: "Notifications",
      link: "/Notifications",
    },
    {
      label: "Create Notifications",
      link: "/Createnotifications",
    },
    {
      label: "View Notifications",
      link: "/viewnotifications",
    },
  ],
  onClick: () => handleDropdownClick(setIsNotificationsOpen)

},
{
  label: "Categories",

  icon: <img src={categories} alt="categories" style={{ width: "30px" }} />,
  
  subMenus: [
    {
      label: "Create Category",
      link: "/createcategory",
    },
    {
      label: "Category List",
      link: "/Categorylist",
    },
    
  ],
  onClick: () => handleDropdownClick(setIsCategoriesOpen)

},

{
  label: "Settings",

  icon: <img src={settings} alt="Settings" style={{ width: "30px" }} />,

  subMenus: [
    {
      label: "Admin Settings",
      link: "/Adminsettings",
    },
    
  ],
        onClick: () => handleDropdownClick(setIsSettingsOpen)

},
{
  label: "Offers",
  icon: <img src={offers} alt="Offers" style={{ width: "30px" }} /> ,
  subMenus: [
    {
      label: "Offers",
      link: "/Offers",
    },
    
  ],
 
  onClick: () => handleDropdownClick(setIsOffersOpen)

},
{
  label: "Ads",
  icon: <img src={offers} alt="Ads" style={{ width: "30px" }} /> ,
  subMenus: [
    {
      label: "Ads",
      link: "/Ads",
    },
    {
      label: "Ads List",
      link: "/Adslist",
    },
    
  ],
 
  onClick: () => handleDropdownClick(setIsAdsOpen)

},
  ]
  const history = useHistory()
  const handleLogout = () => {
    // Perform any necessary logout logic here
setOpenDialog(false)
history.push("/");
window.location.reload()
  };
  const handleDropdownClick = (setOpenFn) => {
    setIsDashboardOpen(false);
    setIsProductsOpen(false);
    setIsCustomersOpen(false);
    setIsOffersOpen(false);
    setIsAdsOpen(false);
    setIsNotificationsOpen(false);
    setIsCategoriesOpen(false);
    setIsReportsOpen(false);
    setIsSettingsOpen(false);
    setOpenFn(true);
  }
  const [openDialog, setOpenDialog] = useState(false);
  const handleSaveClick = (e) => {
    //setSelectedContact(contact);
    e.preventDefault(); // Add this line to prevent default form submission behavior
    setOpenDialog(true);
  };

  return (
    <List>
      {localStorage.getItem("role") === "admin" ? (
        superadmin.map((listItem, index) => (
          <React.Fragment key={index}>
            <ListItem button onClick={listItem.onClick}>
              <ListItemIcon>
                {listItem.icon}
              </ListItemIcon>
              <ListItemText primary={listItem.label} />
              {(listItem.label === "Notifications" ? (isNotificationsOpen ? <ExpandLess /> : <ExpandMore />) :
                (isCategoriesOpen ? <ExpandLess /> : <ExpandMore />))}
            </ListItem>
            {listItem.subMenus && (
              <Collapse in={

            (listItem.label === "Notifications" ? isNotificationsOpen : isCategoriesOpen)} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {listItem.subMenus.map((subMenu, subMenuIndex) => (
                <ListItem button key={subMenuIndex} className={classes.nested} component={NavLink} to={subMenu.link} onClick={handleDrawerClose}>
                  <ListItemText primary={subMenu.label} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    ))
  ) : (
    superadmin.map((listItem, index) => (
      <React.Fragment key={index}>
        <ListItem button onClick={listItem.onClick}>
          <ListItemIcon>
          <Typography variant='h6' className={classes.logo}>
            {listItem.icon}
            </Typography>
          </ListItemIcon>
          <Typography variant='h6' className={classes.logo}>
           <ListItemText primary={listItem.label} />
          </Typography>
          <Typography variant='h6'  className={classes.logo}>
          {listItem.label === "Dashboard" ? (isDashboardOpen ? <ExpandLess /> : <ExpandMore />) : (listItem.label === "Products" ? (isProductsOpen ? <ExpandLess /> : <ExpandMore />): (listItem.label === "Offers" ? (isOffersOpen ? <ExpandLess /> : <ExpandMore />) : (listItem.label === "Customers" ?
            (isCustomersOpen ? <ExpandLess /> : <ExpandMore />) : (listItem.label === "Notifications" ? (isNotificationsOpen ? <ExpandLess /> : <ExpandMore />) : (listItem.label === "Ads" ? (isAdsOpen ? <ExpandLess /> : <ExpandMore />) : (listItem.label === "Categories" ? (isCategoriesOpen ? <ExpandLess /> : <ExpandMore />) : (isReportsOpen ? <ExpandLess /> : <ExpandMore />)))))))}
         </Typography>
        </ListItem>
        {listItem.subMenus && (
          <Collapse in={listItem.label === "Dashboard" ? isDashboardOpen : (listItem.label === "Products" ? isProductsOpen: (listItem.label === "Ads" ? isAdsOpen : (listItem.label === "Customers" ? isCustomersOpen :(listItem.label === "Offers" ? isOffersOpen :
            (listItem.label === "Notifications" ? isNotificationsOpen : (listItem.label === "Categories" ? isCategoriesOpen : (listItem.label === "Reports" ? isReportsOpen : isSettingsOpen)))))))} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {listItem.subMenus.map((subMenu, subMenuIndex) => (
                <ListItem button key={subMenuIndex} className={classes.nested} component={NavLink} to={subMenu.link} onClick={handleDrawerClose}>
                  <Typography variant='h6' className={classes.subm}>
                   <ArrowRight/>
                  </Typography>
                  <ListItemText primary={subMenu.label} />
                </ListItem>
              ))}

            </List>



          </Collapse>

        )}
      </React.Fragment>
    ))
  )

  }
    {/* Logout button */}
    {/* <ListItem button onClick={handleLogout}>
    <ListItemIcon>
    <Typography variant='h6' className={classes.logo}>
    <img src={logout} alt="Logout" style={{ width: "30px" }} />

      </Typography>
    </ListItemIcon>
    <Typography variant='h6' className={classes.logo}>
         
         
    <ListItemText primary="Logout" />
    </Typography>
  </ListItem> */}
      <ListItem button onClick={handleSaveClick}>
        <ListItemIcon>
          <LogoutIcon  style={{color:"#FFFFFF"}}/>
        </ListItemIcon>
        <ListItemText primary="Logout" style={{color:"#FFFFFF"}}/>
      </ListItem>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
  <DialogTitle style={{color:"#000000"}}>Are you sure you want to logout?</DialogTitle>
  <DialogContent>
  
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenDialog(false)} style={{color:"#590F72",fontSize:"16px",fontWeight:400, background: "#0EBA70"}}>Cancel</Button>
  
    <Button style={{color:"#590F72",fontWeight:400,fontSize:"16px", background: "#EB4335" }} onClick={(e)=>{
      handleLogout(e)
      
    }}>Log Out</Button>

  </DialogActions>
</Dialog>
</List>
  )
}